import React, { useRef, useEffect, useState } from 'react'
import './dawshowcase.css'

import Logic from './images/LogicDAW.jpg'
import Protools from './images/ProToolsDAW.jpg'
import Ableton from './images/AbletonDAW.jpg'
import PluginShowcase from './PluginShowcase'

import { motion } from 'framer-motion'

const DawShowcase = ({ allComments,currentTime }) => {
    const containerRef = useRef(null)
    const [scrollYWithinContainer, setScrollYWithinContainer] = useState(0)

    // Example images. Adjust as needed.
    const images = [Logic, Protools, Ableton, Logic]

    useEffect(() => {
        const handleScroll = () => {
            /**
             * The distance from top of the page to the container’s top
             * plus how far the user has scrolled in total (window.scrollY).
             */
            if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect()
                // The top of the container in the viewport is `rect.top`
                // If rect.top is negative => we are scrolled past the top
                const offset = -rect.top
                // offset is how many pixels we’ve scrolled *within* this container
                // We can clamp it between 0 and containerRef.current.offsetHeight to avoid negative or overshoot.
                const maxScroll = containerRef.current.offsetHeight
                const clamped = Math.min(Math.max(offset, 0), maxScroll)
                setScrollYWithinContainer(clamped)
            }
        }

        // Listen to scroll events
        window.addEventListener('scroll', handleScroll, { passive: true })
        // Fire immediately to set initial position
        handleScroll()

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    // We’ll define how much “vertical scroll” is available:
    // For a 4-image sequence, let's define each image has a fade-out portion
    const totalImages = images.length
    const containerScrollHeight = 1 // [0..1] range inside the container
    /**
     * segmentCount = totalImages
     * That means we have 4 segments if we have 4 images
     */
    const segmentCount = totalImages
    // We'll define each segment in fraction form:
    const segmentSize = containerScrollHeight / segmentCount

    // Convert our pixel-based scroll within the container to a 0..1 progress ratio
    // The container itself can be 400vh tall, so:
    const containerPixels = window.innerHeight * 4   // or use a matching value in CSS
    // Now the fraction = how far have we scrolled / how many pixels define "full container"
    const scrollFraction = scrollYWithinContainer / containerPixels

    const getOpacityForImage = (index) => {
        // segmentStart is the fraction at which image is fully visible
        const segmentStart = index * segmentSize
        // Let’s define a portion of the segment for “rest” (fully visible),
        // then fade out near the end. Example: 60% rest, 40% fade.
        const restFraction = 0.6
        const segmentEnd = segmentStart + segmentSize * restFraction

        if (scrollFraction < segmentStart) {
            // we haven't reached this image's "full" segment => keep it at 1 if it's the first image, or 0 if we want it hidden
            return index === 0 ? 1 : 0
        }
        if (scrollFraction > (segmentStart + segmentSize)) {
            // we've scrolled past the entire segment allocated to that image => fully faded out
            return 0
        }
        // We are within that image’s segment.
        // If we’re before the fade portion => opacity is 1
        if (scrollFraction < segmentEnd) {
            return 1
        }
        // If in the fade portion, calculate fade from 1 to 0
        const fadeProgress =
            (scrollFraction - segmentEnd) / ((segmentStart + segmentSize) - segmentEnd)
        return 1 - fadeProgress
    }

    return (
        <div className="daw-showcase__container" ref={containerRef}>
            <div className="daw-showcase__wrapper">

                <div className="daw-showcase__sticky-viewport">
                    {/* The "plugin" always pinned */}
                    <motion.div className="daw-showcase__plugin"
                          initial={{ opacity: 0}}
                          whileInView={{ opacity: 1 }}
                    >
                        <PluginShowcase allComments={allComments}
                         currentTime={currentTime}  />
                    </motion.div>

                    {/* DAW images stacked absolutely */}
                    {images.map((src, index) => (
                        <div
                            key={index}
                            className="daw-showcase__image-container"
                            style={{ opacity: getOpacityForImage(index) }}
                        >
                            <img
                                src={src}
                                alt={`DAW Screenshot ${index + 1}`}
                                className="daw-showcase__image"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DawShowcase
