import React, { useState, useRef, useEffect } from 'react';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { motion, AnimatePresence } from 'framer-motion';

const PlaylistItem = ({
    playlist,
    isDragOver,
    isSelected,
    onDragOver,
    onDragLeave,
    onDrop,
    onClick,
}) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [renaming, setRenaming] = useState(false);
    const [newName, setNewName] = useState(playlist.name);
    const menuRef = useRef(null);

    const handleDragOver = (e) => {
        onDragOver(e, playlist.id);
    };

    const handleDragLeave = () => {
        onDragLeave();
    };

    const handleDrop = (e) => {
        onDrop(e, playlist);
    };

    const toggleMenu = (e) => {
        e.stopPropagation();
        setMenuVisible(!menuVisible);
    };

    const handleRename = async () => {
        if (newName.trim() === '') return;
        const playlistDocRef = doc(db, 'playlists', playlist.id);
        await updateDoc(playlistDocRef, { name: newName });
        setRenaming(false);
        setMenuVisible(false);
    };

    const handleDelete = async () => {
        const playlistDocRef = doc(db, 'playlists', playlist.id);
        await deleteDoc(playlistDocRef);
        setMenuVisible(false);
    };

    // Close menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setMenuVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            className={`playlist-item ${isDragOver ? 'drag-over' : ''} ${isSelected ? 'selected' : ''
                }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={onClick}
        >
            <div className="playlist-item-content">
                {renaming ? (
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleRename();
                        }}
                    >
                        <input
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            autoFocus
                        />
                        <button type="submit">Save</button>
                        <button
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setRenaming(false);
                                setMenuVisible(false);
                                setNewName(playlist.name);
                            }}
                        >
                            Cancel
                        </button>
                    </form>
                ) : (
                    <>
                        <div className="playlist-item-name">{playlist.name}</div>
                        <div className="playlist-item-menu-icon" onClick={toggleMenu}>
                            &#x22EE; {/* Vertical Ellipsis */}
                        </div>
                        <AnimatePresence>
                            {menuVisible && (
                                <motion.div
                                    className="playlist-item-menu"
                                    ref={menuRef}
                                    style={{ top: '30px', right: '10px' }}
                                    onClick={(e) => e.stopPropagation()}
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.9 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    <div
                                        className="playlist-item-menu-option"
                                        onClick={() => {
                                            setRenaming(true);
                                        }}
                                    >
                                        Rename
                                    </div>
                                    <div className="playlist-item-menu-option" onClick={handleDelete}>
                                        Delete
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </>
                )}
            </div>
        </div>
    );
};

export default PlaylistItem;
