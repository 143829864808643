// SignUp.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, updateProfile } from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserAuth } from '../../context/AuthContext';
import useWindowDimensions from '../getWindowDimensions';

import Logo from '../usermainpage/SoundbirdlogoSVG.svg'

/* Basic validators */
const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
const isValidPassword = (password) => password.length >= 6;

const SignUp = () => {
  const [step, setStep] = useState(1);

  // Form fields
  const [email, setEmail] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);

  const [fullName, setFullName] = useState('');
  const [nameTouched, setNameTouched] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);

  const [error, setError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const params = new URLSearchParams(location.search);
  const redirect = params.get('redirect');

  const auth = getAuth();
  const { createUser } = UserAuth(); // createUser is your wrapper around createUserWithEmailAndPassword

  // Firestore doc creation
  const createUserDocument = async (user) => {
    const userDocRef = doc(db, 'users', user.uid);
    const userDocSnap = await getDoc(userDocRef);
    if (!userDocSnap.exists()) {
      await setDoc(userDocRef, {
        email: user.email,
        name: fullName,
        friends: [],
        friendRequests: [],
        createdAt: serverTimestamp(),
      });
      console.log('User document created with UID:', user.uid);
    }
  };

  /* --------------------------------
     Step 1: Email
  ----------------------------------- */
  const emailError =
    emailTouched && email.length > 0 && !isValidEmail(email)
      ? 'Please enter a valid email.'
      : '';

  const canGoToStep2 = isValidEmail(email);

  const handleNextStep = () => {
    if (canGoToStep2) {
      setStep(2);
    }
  };

  /* --------------------------------
     Step 2: Full Name + Password
  ----------------------------------- */
  const nameError =
    nameTouched && fullName.trim().length === 0
      ? 'Please enter your full name.'
      : '';

  const passwordError =
    passwordTouched && password.length > 0 && !isValidPassword(password)
      ? 'Password must be at least 6 characters.'
      : '';

  const canSignUp =
    fullName.trim().length > 0 &&
    isValidPassword(password);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // 1) Create the user
      const userCredential = await createUser(email, password);

      // 2) Update the displayName immediately
      await updateProfile(userCredential.user, { displayName: fullName });

      // 3) Create Firestore doc if needed
      await createUserDocument(userCredential.user);

      // 4) Navigate to the correct page
      if (width === 500) {
        navigate('/userplugin');
      } else {
        navigate(redirect || '/user');
      }
    } catch (err) {
      console.error('Error during sign up:', err);
      setError(err.message);
    }
  };

  /* --------------------------------
     Render
  ----------------------------------- */
  return (
    <div className="sign__container">
      <div className="sign__leftcontainer">
        <img
          style={{ width: '300px' }}
          src={Logo}></img>
      </div>


      <div className="sign__rightcontainer">

        <div className="sign__text">
          Welcome to
          <div style={{ letterSpacing: '-2px', marginTop: '-30px', fontSize: '80px' }}><strong>Soundbird</strong></div>
        </div>



        <div className="sign__formcontainer">

          {step === 1 && (
            <>
              <div className="sign__form">

                <input
                  className="sign__input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => setEmailTouched(true)}
                  placeholder='Email'
                  required
                />
                {emailError && <div className="sign__error">{emailError}</div>}
              </div>

              <button
                className="sign__button"
                onClick={handleNextStep}
                disabled={!canGoToStep2}
              >
                Next
              </button>
            </>
          )}

          {/* STEP 2: Full Name + Password */}
          {step === 2 && (
            <form onSubmit={handleSignUp}>
              <div className="sign__form">

                <input
                  placeholder='Full name'
                  className="sign__input"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  onBlur={() => setNameTouched(true)}
                  required
                />
                {nameError && <div className="sign__error">{nameError}</div>}
              </div>

              <div className="sign__form">

                <input
                  placeholder='Password'
                  className="sign__input"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={() => setPasswordTouched(true)}
                  required
                />
                {passwordError && <div className="sign__error">{passwordError}</div>}
              </div>

              {/* TOS text */}
              <p style={{ color: '#6c757d', fontSize: '0.9rem', marginTop: '8px' }}>
                By signing up, you agree to our <span style={{ fontSize: '18px', color: 'black' }} className="sign__underline">Terms of Service</span> and <span style={{ fontSize: '18px', color: 'black' }} className="sign__underline">Privacy Policy</span>.
              </p>

              <button
                className="sign__button"
                type="submit"
                disabled={!canSignUp}
              >
                Sign Up
              </button>
            </form>
          )}

          {/* Display any error */}
          {error && <div className="sign__error">{error}</div>}

          <div className="sign__up"

          >
            Already have an account? &nbsp;
            <a style={{ fontSize: '18px', color: 'black' }} href="/signin" className="sign__underline">Log in</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
