// GlobalWaveSurferContext.jsx

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

const GlobalWaveSurferContext = createContext(null);

export function GlobalWaveSurferProvider({ children }) {
  const waveSurferRef = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(null);

  useEffect(() => {
    // 1) Create WaveSurfer hidden in #globalWaveform by default
    waveSurferRef.current = WaveSurfer.create({
      container: '#globalWaveform',
      waveColor: '#E3E3EC',
      progressColor: '#bfbfbf',
      responsive: true,
      height: 70,
      barGap: 1,
      barWidth: 5,
      barRadius: 5,
    });

    waveSurferRef.current.on('ready', () => {
      setIsReady(true);
    });

    // If you want to destroy on unmount, do it here:
    // return () => {
    //   waveSurferRef.current && waveSurferRef.current.destroy();
    // };
  }, []);

  // 2) Reassign container helper
  const setWaveSurferContainer = (containerSelector) => {
    if (
      waveSurferRef.current && 
      document.querySelector(containerSelector)
    ) {
      // remove old wave from the old container
      waveSurferRef.current.empty();

      // point waveSurfer’s “params.container” to the new DOM element
      waveSurferRef.current.params.container = containerSelector;

      // re-assign the internal drawer’s container
      waveSurferRef.current.drawer.container =
        document.querySelector(containerSelector);

      // force a re-draw with new container
      waveSurferRef.current.drawBuffer();
    }
  };

  // Expose your standard player controls, plus the new function:
  const loadFile = (url) => {
    if (!waveSurferRef.current) return;
    console.log(currentUrl)
    setCurrentUrl(url);
    waveSurferRef.current.load(url);
  };

  const playPause = () => {
    if (!waveSurferRef.current) return;
    waveSurferRef.current.playPause();
  };

  const play = () => {
    waveSurferRef.current && waveSurferRef.current.play();
  };

  const pause = () => {
    waveSurferRef.current && waveSurferRef.current.pause();
  };

  const seekTo = (ratio) => {
    waveSurferRef.current && waveSurferRef.current.seekTo(ratio);
  };

  const getCurrentTime = () => {
    return waveSurferRef.current ? waveSurferRef.current.getCurrentTime() : 0;
  };

  const getDuration = () => {
    return waveSurferRef.current ? waveSurferRef.current.getDuration() : 0;
  };

  const isPlaying = () => {
    return waveSurferRef.current ? waveSurferRef.current.isPlaying() : false;
  };

  // Provide all in context
  return (
    <GlobalWaveSurferContext.Provider
      value={{
        waveSurferRef,
        isReady,
        loadFile,
        playPause,
        play,
        pause,
        seekTo,
        getCurrentTime,
        getDuration,
        isPlaying,
        currentUrl,
        // The new function:
        setWaveSurferContainer,
      }}
    >
      {children}
      {/* The hidden container remains in the DOM for fallback */}
      <div
        id="globalWaveform"
        style={{
          position: 'absolute',
          zIndex: 999,
          width: '0px',
          height: '0px',
          overflow: 'hidden',
        }}
      />
    </GlobalWaveSurferContext.Provider>
  );
}

export function useGlobalWaveSurfer() {
  return useContext(GlobalWaveSurferContext);
}
