import React from 'react'
import UserMainPage from '../usermainpage/UserMainPage'
import UserPage from '../userpage/UserPage'

const Editor = () => {
  return (
    <div>
        <UserMainPage page={<UserPage />}/>
    </div>
  )
}

export default Editor