import React  from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import './assets/Avenir-Book-01.ttf'

import { BrowserRouter } from 'react-router-dom'

//ReactDOM.render(<App/>, document.querySelector("#root"))
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)