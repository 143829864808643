
import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove, query, collection, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserAuth } from '../../context/AuthContext';

const FriendItem = ({ uid, onClick}) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            const userDoc = await getDoc(doc(db, 'users', uid));
            if (userDoc.exists()) {
                setUser(userDoc.data());
            }
        };
        fetchUser();
    }, [uid]);


    if (!user) return null;

    return (
        <div className="chat__friendItem" onClick={onClick}>
            <span>{user.email}</span>
        </div>
    );
};
export default FriendItem
