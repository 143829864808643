import React, { useEffect, useState } from 'react'

import { AnimatePresence, motion } from 'framer-motion';

import LinkSVG from './LinkSVG.svg'

import CopySVG from './copySVG.svg'

const SideBarShareLinkButton = ({ name, onClick, isSelected, link, copyFunction, inviteLink }) => {
    const [mouseEnter, setMouseEnter] = useState(false);
    const [mouseEnterCopyButton, setMouseEnterCopyButton] = useState(false);
    const [clicked, setClicked] = useState(false);


    return (
        <div>
            <motion.div
                onMouseEnter={e => {
                    setMouseEnter(true);
                }}
                onMouseLeave={e => {
                    setMouseEnter(false);
                }}
                animate={{ scale: mouseEnter ? 1.01 : 1.0 }}
                style={{
                    boxShadow: isSelected ? '4px 4px 10px #ebeaea' : '0px 0px 0px #FFFFFF',
                    color: isSelected ? '#000000' : '#8A8A8A'
                }}

            >
                <button key="3"
                    onClick={onClick}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '4px',
                        height: '50px'
                    }}
                >
                    {!isSelected && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '4px'
                            }}>

                            <img src={LinkSVG}>
                            </img>

                            {name}
                        </div>
                    )}

                </button>

                <AnimatePresence>
                    {isSelected && (
                        <motion.div
                            className='colab__inviteLinkContainer'
                            style={{
                                position: 'absolute',
                                marginTop: '-67px'
                            }}
                            initial={{ opacity: 0, y: -5 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -5 }}
                            transition={{ duration: 0.2 }}
                        >
                            {inviteLink ? (
                                <>
                                    <input
                                        className='colab__inviteLinkInput'
                                        type='text'
                                        value={inviteLink}
                                        readOnly
                                        onClick={(e) => e.target.select()}
                                    />
                                    <motion.div
                                        animate={{ scale: mouseEnterCopyButton ? 1.1 : 1.0 }}>

                                        <button
                                            className='colab__inviteLinkCopyBtn'
                                            onMouseEnter={e => {
                                                setMouseEnterCopyButton(true);
                                            }}
                                            onMouseLeave={e => {
                                                setMouseEnterCopyButton(false);
                                            }}
                                            onClick={() => copyFunction(inviteLink)}

                                            style={{
                                                background: 'none',
                                                transform: 'translate(-2px, 1px)'
                                            }}
                                        >
                                            <img src={CopySVG} />
                                        </button>
                                    </motion.div>
                                </>
                            ) : (
                                <div
                                style={{color: 'white', fontSize: 'small', width: '100%', height: '100%',
                                    transform: 'translate(47px, 16px)'
                                }}
                                >Generating link...</div>
                            )}
                        </motion.div>

                    )}
                </AnimatePresence>
            </motion.div>
        </div>
    )
}

export default SideBarShareLinkButton