import React, { useState } from 'react'
import './signin.css'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { UserAuth } from '../../context/AuthContext'

import GoButton from './GoButton.svg'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

import useWindowDimensions from '../getWindowDimensions'

import Logo from '../usermainpage/SoundbirdlogoSVG.svg'



const SignIn = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const location = useLocation();
    const navigate = useNavigate()

    const { signIn } = UserAuth()

    const { height, width } = useWindowDimensions();

    const params = new URLSearchParams(location.search);
    const redirect = params.get('redirect');

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError('')
        try {
            await signIn(email, password)
            if (width === 500) {
                navigate('/userplugin')
            }
            else {
                navigate(redirect || '/user');
            }
        } catch (e) {
            setError(e.message)
        }
    }

    return (
        <div className='sign__container'>

            <div className="sign__leftcontainer">
                <img
                    style={{ width: '300px' }}
                    src={Logo}></img>
            </div>


            <div className="sign__rightcontainer">

                <div className="sign__text">
                    Welcome to
                    <div style={{ letterSpacing: '-2px', marginTop: '-30px', fontSize: '80px' }}><strong>Soundbird</strong></div>
                </div>

                <div className="sign__formcontainer">
                    <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <div className='sign__form'>

                            <input
                                placeholder='Email'
                                onChange={(e) => setEmail(e.target.value)} className='sign__input' type="email" />
                        </div>

                        <div className='sign__form'>

                            <input
                                placeholder='Password'
                                onChange={(e) => setPassword(e.target.value)} className='sign__input' type="password" />
                        </div>
                        <button className='sign__button'>
                            sign in
                        </button>
                    </form>
                </div>

                <div>
                    {error}
                </div>


                <div className='sign__up'>
                    But I don't have an account?
                    <div >
                        <Link
                            style={{ fontSize: '18px', color: 'black' }} href="/signin"
                            to={redirect || '/signup'} className='sign__underline'>
                            Sign Up
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn