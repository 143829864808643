import React, { useEffect, useState, useRef } from 'react';
import './lyrics.css';

const MIN_TEXTAREA_HEIGHT = 10;

export default function LyricsTextElement(props) {
  const { value: initialValue, onSaveLyrics } = props;
  const textareaRef = useRef(null);
  const [value, setValue] = useState(initialValue || '');
  const valueRef = useRef(value);
  const isModifiedRef = useRef(false);

  useEffect(() => {
    // Update value if initialValue changes
    setValue(initialValue || '');
    isModifiedRef.current = false; // Reset modification flag
  }, [initialValue]);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const onChange = (event) => {
    setValue(event.target.value);
    isModifiedRef.current = true; // Mark as modified
  };

  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'b') {
      event.preventDefault();
      makeSelectionBold();
    }
  };

  const makeSelectionBold = () => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    if (start === end) return; // No text selected

    const selectedText = value.substring(start, end);
    const before = value.substring(0, start);
    const after = value.substring(end);

    const newValue = `${before}<b>${selectedText}</b>${after}`;
    setValue(newValue);

    // Preserve selection
    setTimeout(() => {
      textarea.selectionStart = start + 3;
      textarea.selectionEnd = end + 3 + selectedText.length;
    }, 0);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT
      )}px`;
    }
  }, [value]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (textarea) {
        textarea.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  // Save lyrics on unmount if modified
  useEffect(() => {
    return () => {
      if (onSaveLyrics && isModifiedRef.current) {
        onSaveLyrics(valueRef.current);
      }
    };
  }, []);

  return (
    <div>
      <textarea
        className="lyrics__textbox"
        rows={1}
        spellCheck={false}
        autoCorrect="off"
        onChange={onChange}
        ref={textareaRef}
        style={{ minHeight: MIN_TEXTAREA_HEIGHT, resize: 'none' }}
        value={value}
        placeholder="Write your lyrics"
      />
    </div>
  );
}
