import React from 'react'

const StickyTest = () => {
  return (
    <div className='sticky__container'>
        <div className="sticky__wrapper">
            <div className="sticky__content">
                Abcdefg
            </div>
        </div>
    </div>
  )
}

export default StickyTest