// SongItem.jsx
import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { ReactComponent as PlayButton } from './PlayButtonPlaylists.svg';

const SongItem = ({ song, onDragStart, onClick }) => {
  const [style, setStyle] = useState({ display: 'none' });
  const [mouseEnter, setMouseEnter] = useState(false);

  // Extract name
  const name = song.name.slice(14);
  const songName = name.slice(0, -4);

  // Drag & Drop
  const handleDragStart = (e) => {
    onDragStart(e, song);
  };

  // Hover logic
  useEffect(() => {
    setStyle({ display: mouseEnter ? 'block' : 'none' });
  }, [mouseEnter]);

  return (
    <div
      className="song-item"
      draggable
      onDragStart={handleDragStart}
      // 1) Add onClick here (or on a child element):
      onClick={() => onClick && onClick(song)}
      style={{ cursor: 'pointer' }}
    >
      <motion.div
        className='oneaudio__container'
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        animate={{ scale: mouseEnter ? 1.02 : 1 }}
      >
        <motion.div
          className='oneaudio__playbutton'
          animate={{ opacity: mouseEnter ? 1 : 0 }}
          transition={{ duration: 0.07 }}
        >
          {/* You could also move onClick to just the PlayButton */}
        </motion.div>

        <img
          src={`https://picsum.photos/200/200?${song.id}`}
          className='oneaudio__img'
          alt="cover"
        />

        <div className='oneaudio__gradient' />
        <div className="oneaudio__name">
          {songName}
        </div>

        <motion.div
          animate={{
            scale: mouseEnter ? 1.02 : 1,
            opacity: mouseEnter ? 1 : 0
          }}
        >
          <PlayButton className='onaudio__play' />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SongItem;
