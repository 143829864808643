import React, { useEffect, useState, useRef } from 'react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { motion } from 'framer-motion';
import ColabMessage from '../colabeditor/ColabMessage';
import { UserAuth } from '../../context/AuthContext';
import OneFile from '../files/OneFile';

import SkeletonSound from '../invites/SkeletonSound.jpg';
import SearchIcon from '../files/searchicon.svg';

const PluginProjectSelector = () => {
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const { user } = UserAuth();

    useEffect(() => {
        if (user.uid) {
            const invitesRef = collection(db, "invites");
            const userInvitesQuery = query(
                invitesRef,
                where("team", "array-contains", user.email),
                orderBy("lastUpdated", "desc")
            );

            const unsubscribe = onSnapshot(userInvitesQuery, (snapshot) => {
                const projectList = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    viewing: false,
                    ...doc.data(),
                }));
                setProjects(projectList);
                setFilteredProjects(projectList);
            });

            return () => unsubscribe();
        }
    }, [user.uid]);

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        const filtered = projects.filter((project) =>
            project.name?.toLowerCase().includes(query)
        );
        setFilteredProjects(filtered);
    };

    return (
        <div className="project-selector">

            <div className='juceplugin__selectortitle'>Select a Project to Connect</div>

            <div className="files__search" style={{ marginLeft: -20, marginRight: -10 }}>
                <div className="files__searchcontainer">
                    <input
                        className="files__searchbar"
                        placeholder="Search for a project..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    <img
                        src={SearchIcon}
                        className="files__searchicon"
                        alt="Search Icon"
                    />
                </div>
            </div>
            <div className='files__files' style={{ marginBottom: 20, marginTop: 20, marginLeft: 10 }}>
                {filteredProjects.map((project) => (
                    <div key={project.id} >
                        <OneFile
                            isProject={false}
                            key={project.id}
                            name={project.name}
                            id={project.id}
                            img={SkeletonSound}
                            type={project.type}
                            members={project.team.length}
                            lastUpdated={project.lastUpdated?.toDate()}
                            plugin={true}
                            duration={project.durationMs}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PluginProjectSelector
