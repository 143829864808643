// ColabMessages.jsx

import React, { useState, useEffect } from 'react';
import ColabMessage from './ColabMessage';
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  serverTimestamp,
  setDoc,
  doc,
  deleteDoc,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { db } from '../../firebase';
import { UserAuth } from '../../context/AuthContext';
import ColabSendSVG from './Colabsend.svg';
import Pause from './PauseSound.svg';
import Play from './playsound.svg';
import { useParams } from 'react-router-dom';
import { additionalHexData, additionalHexDataArray } from './additionalHexData.js';

const ColabMessages = ({
  waveWidth,
  duration,
  viewWidth,
  projectData,
  time,
  playState,
  togglePlayState,
  goToComment,
  getCurrentTime,
  memberPanelOpen,
  users
}) => {
  const [allComments, setAllComments] = useState([]);
  const [currentCommentValue, setCurrentCommentValue] = useState('');

  const { user } = UserAuth();
  const params = useParams();

  const uid = user.uid;

  let pluginid = projectData.pluginId;
  const allMembers = projectData.team;

  if (!pluginid) {
    pluginid = '';
  }

  useEffect(() => {
    if (user.uid) {
      const q = query(
        collection(db, 'comments'),
        where('project', '==', params.id),
        orderBy('time', 'asc')
      );

      onSnapshot(q, (snapshot) => {
        setAllComments(
          snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              viewing: false,
              ...doc.data(),
            };
          })
        );
      });

      markAsRead()
    }
  }, [user.uid]);

  async function markAsRead() {

  }

  const deleteComment = async (id) => {
    try {
      await deleteDoc(doc(db, 'comments', id));
    } catch (error) {
      console.error('Error deleting comment: ', error);
    }
  };

  const formatTimecode = (time) => {
    const totalSeconds = parseInt(time, 10);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);
    const milliseconds = Math.floor((time % 1) * 1000);

    const pad = (num, size = 2) => num.toString().padStart(size, '0');

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}:${pad(milliseconds, 3)}`;
  };

  const handleClick = () => {
    togglePlayState();
  };

  const onChange = (event) => {
    setCurrentCommentValue(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addNewComment();
    }
  };

  const addNewComment = () => {
    const allEmailsExceptMine = allMembers.filter(email => email !== user.email);

    console.log(allEmailsExceptMine)

    if (currentCommentValue) {
      const newComment = {
        value: currentCommentValue,
        user: uid,
        project: params.id,
        time: serverTimestamp(),
        position: getCurrentTime(),
        pluginId: pluginid,
        col: allMembers.indexOf(user.email),
        username: user.displayName,
        readby: [user.email],
      };

      console.log(user.displayName);

      setDoc(doc(collection(db, 'comments')), newComment).then(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });

      updateDoc(doc(db, 'invites', params.id), {
        unreadUsers: arrayUnion(...allEmailsExceptMine)
      });

      setCurrentCommentValue('');
    }
  };

  // Function to export markers in a tab-delimited text file for Pro Tools
  const exportMarkersForProTools = () => {
    const markers = allComments.map((comment, index) => {
      const timeInSeconds = comment.position;
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = (timeInSeconds % 60).toFixed(3);
      const timeCode = `${minutes}:${seconds.padStart(6, '0')}`;
      return `${index + 1}\t${comment.value || 'Marker'}\t${timeCode}\t${comment.value || ''}`;
    });

    const header = 'Marker Number\tMarker Name\tTime\tComment\n';
    const content = header + markers.join('\n');

    const blob = new Blob([content], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${params.name || 'Project'}_Markers.txt`;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to create and download a WAV file with embedded markers for Logic Pro X
  const exportMarkersForLogic = () => {
    const sampleRate = 44100; // 44.1 kHz
    const durationInSeconds = Math.max(...allComments.map((c) => c.position)) + 1; // 1 second extra
    const numSamples = Math.ceil(durationInSeconds * sampleRate);
    const numChannels = 2; // Stereo

    // Create an empty audio buffer
    const audioBuffer = new Float32Array(numSamples * numChannels);

    // Generate WAV file with embedded markers
    const wavData = createWavWithMarkers(audioBuffer, sampleRate, numChannels, allComments);

    // Trigger a download for the WAV file
    const blob = new Blob([wavData], { type: 'audio/wav' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${params.name || 'Project'}_Markers.wav`;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to create WAV file with embedded markers


  const createWavWithMarkers = (audioBuffer, sampleRate, numChannels, comments) => {
    const numSamples = audioBuffer.length / numChannels;
    const bytesPerSample = 2; // 16-bit audio
    const blockAlign = numChannels * bytesPerSample;
    const byteRate = sampleRate * blockAlign;

    // WAV header size
    const wavHeaderSize = 44;
    let bufferLength = wavHeaderSize + audioBuffer.length * bytesPerSample;

    // Prepare 'cue ' chunk data
    const cuePoints = comments.map((comment, index) => {
      const position = Math.floor(comment.position * sampleRate);
      return {
        dwName: index + 1,
        dwPosition: position,
        fccChunk: 'data',
        dwChunkStart: 0,
        dwBlockStart: 0,
        dwSampleOffset: position,
      };
    });

    const cueChunkSize = 4 + cuePoints.length * 24;

    // Prepare 'LIST' chunk data
    const labelChunks = comments.map((comment, index) => {
      const label = comment.value || `Marker ${index + 1}`;
      const labelLength = label.length + 1; // Null-terminated
      const paddedLabelLength = labelLength % 2 === 0 ? labelLength : labelLength + 1; // Pad to even
      return {
        dwName: index + 1,
        value: label,
        length: paddedLabelLength + 4, // 4 bytes for dwName
      };
    });

    const listChunkDataSize = 4 + labelChunks.reduce((sum, lbl) => sum + 8 + lbl.length, 0); // 'adtl' + all 'labl' chunks

    const listChunkSize = listChunkDataSize;

    // Total size
    const totalSize = bufferLength + 8 + cueChunkSize + 8 + listChunkSize + additionalHexData.length;

    // Create buffer
    const finalBuffer = new ArrayBuffer(totalSize);
    const finalView = new DataView(finalBuffer);

    let offset = 0;

    // 'RIFF' chunk descriptor
    writeString(finalView, offset, 'RIFF');
    offset += 4;
    finalView.setUint32(offset, totalSize - 8, true); // File size - 8 bytes
    offset += 4;
    writeString(finalView, offset, 'WAVE');
    offset += 4;


    writeString(finalView, offset, 'JUNK');
    offset += 4;
    finalView.setUint32(offset, 40, true); // 
    offset += 4;
    finalView.setUint16(offset, 1, true); // AudioFormat (1 for PCM)
    offset += 2;
    finalView.setUint16(offset, numChannels, true); // NumChannels
    offset += 2;
    finalView.setUint32(offset, sampleRate, true); // SampleRate
    offset += 4;
    finalView.setUint32(offset, byteRate, true); // ByteRate
    offset += 4;
    finalView.setUint16(offset, blockAlign, true); // BlockAlign
    offset += 2;
    finalView.setUint16(offset, bytesPerSample * 8, true); // BitsPerSample
    offset += 2;

    // 'data' sub-chunk
    writeString(finalView, offset, 'data');
    offset += 4;
    finalView.setUint32(offset, audioBuffer.length * bytesPerSample, true); // Subchunk2Size
    offset += 4;

    // Write audio data (silence)
    for (let i = 0; i < audioBuffer.length; i++, offset += 2) {
      finalView.setInt16(offset, 0, true); // Silence
    }

    // Write 'cue ' chunk
    writeString(finalView, offset, 'cue ');
    offset += 4;
    finalView.setUint32(offset, cueChunkSize, true);
    offset += 4;
    finalView.setUint32(offset, cuePoints.length, true);
    offset += 4;

    cuePoints.forEach((cue) => {
      finalView.setUint32(offset, cue.dwName, true);
      offset += 4;
      writeString(finalView, offset, cue.fccChunk);
      offset += 4;
      finalView.setUint32(offset, cue.dwChunkStart, true);
      offset += 4;
      finalView.setUint32(offset, cue.dwBlockStart, true);
      offset += 4;
      finalView.setUint32(offset, cue.dwSampleOffset, true);
      offset += 4;
    });

    // Write 'LIST' chunk with label information
    writeString(finalView, offset, 'LIST');
    offset += 4;
    finalView.setUint32(offset, listChunkSize, true);
    offset += 4;
    writeString(finalView, offset, 'adtl');
    offset += 4;

    labelChunks.forEach((labelChunk) => {
      writeString(finalView, offset, 'labl');
      offset += 4;
      finalView.setUint32(offset, labelChunk.length, true); // Size of label chunk data
      offset += 4;
      finalView.setUint32(offset, labelChunk.dwName, true); // dwName (cue point ID)
      offset += 4;
      writeString(finalView, offset, labelChunk.value + '\0'); // Null-terminated string
      offset += labelChunk.value.length + 1;
      if ((labelChunk.value + '\0').length % 2 !== 0) {
        offset += 1; // Padding byte if needed
      }
    });

    // Append additional hex data
    const uint8View = new Uint8Array(finalBuffer);
    uint8View.set(additionalHexData, offset);
    offset += additionalHexData.length;

    return finalBuffer;
  };

  // Helper function to write strings to DataView
  function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }


  useEffect(() => {
    console.log(memberPanelOpen);
  }, [memberPanelOpen])


  return (
    <div style={{
      position: 'relative',
      zIndex: '1'
    }}>
      <div className='colab__comments'>
        {allComments.map((element) => (
          <div key={element.id}>
            <div className='colab__onecomment' >
              <div
                className='colab__whitepos'
                style={{
                  width: `${(element.position * waveWidth * 1.001) / duration +
                    (viewWidth - 650) * 0.01
                    }px`,
                }}
              ></div>
              <div onClick={() => goToComment(element.position)}>
                <ColabMessage
                  message={element.value}
                  position={element.position}
                  color={element.col}
                  deleteComment={deleteComment}
                  commentId={element.id}
                  memberPanelOpen={memberPanelOpen}
                  reactions={element.reactions || {}}
                  currentUserEmail={user.email}
                  userName={element.username}
                  users={users}
                />
              </div>
            </div>
          </div>
        ))}

        <div className='bottomspace'></div>
      </div>

      <div className='colab__bottomcontainer'>

        <div className='colab__plusbutton' onClick={handleClick}>
          <div className='colab__playpos'>
            {playState && <img className='colab__play' src={Pause} alt='Pause' />}
            {!playState && (
              <img className='colab__play' src={Play} alt='Play' style={{ marginLeft: 3, marginTop: 1 }} />
            )}
          </div>

        </div>
        <div className="colab__bottomleftgap"></div>
        <div className='colab__textarea'>
          <div className='colab__time'>{formatTimecode(time)}</div>
          <textarea
            spellCheck={false}
            autoCorrect='false'
            className='colab__text'
            value={currentCommentValue}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            placeholder='Add a comment...'
          ></textarea>
          <button className='colab__sendbutton' onClick={addNewComment}>
            <img src={ColabSendSVG} alt='Send' />
          </button>
        </div>
        {/* Add the Export Buttons */}
        {/* <div className='colab__export-buttons'>
          <button onClick={exportMarkersForProTools} className='export-button'>
            Export Markers for Pro Tools
          </button>
          <button onClick={exportMarkersForLogic} className='export-button'>
            Export Markers for Logic Pro X
          </button>
        </div>*/}
      </div>
    </div>
  );
};

export default ColabMessages;
