// DataContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import {
    collection, doc, onSnapshot, query, where, orderBy
} from 'firebase/firestore';
import { db } from '../firebase';  // your Firestore import
import { UserAuth } from '../context/AuthContext';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const { user } = UserAuth();

    const [userData, setUserData] = useState(null);       // user doc data
    const [invitations, setInvitations] = useState([]);   // invitations array
    const [workspaces, setWorkspaces] = useState([]);     // workspaces array
    const [invites, setInvites] = useState([]);           // e.g. "invites" collection data

    // We’ll track loading or error states as well
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!user?.uid) return;

        setLoading(true);

        // ─────────── 1) Listen to user doc ───────────
        const userDocRef = doc(db, 'users', user.uid);
        const unsubUserDoc = onSnapshot(userDocRef, (docSnap) => {
            setUserData(docSnap.exists() ? docSnap.data() : null);
        });

        // ─────────── 2) Listen to invitations for user ───────────
        const invitationsRef = collection(db, 'invitations');
        const qInvitations = query(
            invitationsRef,
            where("userId", "==", user.email),
            orderBy("time", "desc")
        );
        const unsubInvitations = onSnapshot(qInvitations, (snapshot) => {
            const arr = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setInvitations(arr);
        });

        // ─────────── 3) Listen to user’s workspaces ───────────
        const workspacesRef = collection(db, 'workspaces');
        const qWorkspaces = query(
            workspacesRef,
            where("team", "array-contains", user.email),
            orderBy("lastUpdated", "desc")
        );
        const unsubWorkspaces = onSnapshot(qWorkspaces, (snapshot) => {
            const arr = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setWorkspaces(arr);
        });

        // ─────────── 4) Listen to “invites” collection (if needed) ───────────
        const invitesRef = collection(db, 'invites');
        const qInvites = query(
            invitesRef,
            where("team", "array-contains", user.email),
            orderBy("lastUpdated", "desc")
        );
        const unsubInvites = onSnapshot(qInvites, (snapshot) => {
            const arr = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setInvites(arr);
            setLoading(false);
        });

        // Cleanup function
        return () => {
            unsubUserDoc();
            unsubInvitations();
            unsubWorkspaces();
            unsubInvites();
        };
    }, [user?.uid, user?.email]);

    const value = {
        userData,
        invitations,
        workspaces,
        invites,
        loading
    };

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};

// Optional convenience hook:
export const useDataContext = () => {
    return useContext(DataContext);
};
