import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Sample songs data
const songsData = [
  { id: 'song-1', name: 'Song One' },
  { id: 'song-2', name: 'Song Two' },
  { id: 'song-3', name: 'Song Three' },
];

// Initializes playlists without songs
const playlistsData = [
  { id: 'playlist-1', name: 'Playlist One', songs: [] },
  { id: 'playlist-2', name: 'Playlist Two', songs: [] },
];

const PlaylistsTest = () => {
  const [songs, setSongs] = useState(songsData);
  const [playlists, setPlaylists] = useState(playlistsData);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // handles song dropping on playlists
    if (source.droppableId !== destination.droppableId) {
      const sourceClone = Array.from(songs);
      const destClone = playlists.find(p => p.id === destination.droppableId).songs.slice();

      const [removed] = sourceClone.splice(source.index, 1);
      destClone.push(removed);

      const updatedPlaylists = playlists.map(playlist => {
        if (playlist.id === destination.droppableId) {
          return { ...playlist, songs: destClone };
        }
        return playlist;
      });

      setPlaylists(updatedPlaylists);

      // Logging out the playlist where the song was added
      console.log(`Updated ${destination.droppableId}:`, destClone.map(song => song.name));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="songs" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '10px',
              overflow: 'auto',
            }}
          >
            {songs.map((song, index) => (
              <Draggable key={song.id} draggableId={song.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      userSelect: 'none',
                      padding: '10px',
                      margin: '0 10px',
                      backgroundColor: 'white',
                      ...provided.draggableProps.style,
                    }}
                  >
                    {song.name}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div style={{ marginTop: '20px' }}>
        {playlists.map((playlist) => (
          <Droppable key={playlist.id} droppableId={playlist.id}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  padding: '10px',
                  width: '250px',
                  minHeight: '50px',
                  margin: '10px',
                  backgroundColor: 'lightblue',
                  textAlign: 'center',
                }}
              >
                <h2>{playlist.name}</h2>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
};

export default PlaylistsTest;
