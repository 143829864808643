import { map } from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react';

import './skeleton.css'

const SkeletonWave = (props) => {
  const stickObj = <div className='skeleton__onebar' />
  const width = props.width;
  let rows = [];
  const [counter, setCounter] = useState(0)
  const [flutter, setFlutter] = useState(0);

  for (let i = 0; i < width; i += 6) {
    rows.push(<div className='skeleton__onebar' style={{ height: Math.abs(Math.sin(i) * 25 + flutter) }} />);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const oldFlutter = flutter;
      setFlutter(oldFlutter + 1)
      console.log(flutter);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {

  }, [flutter])

  return (
    <div className="skeleton__shimmerwrapper">
        <div className="skeleton__shimmer">
       
       </div>
       <div className='skeleton__container'>
          {rows}
        </div>
    </div>

  )
}

export default SkeletonWave