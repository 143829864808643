import React, { useState } from 'react'

import { motion } from 'framer-motion';

const SideBarButton = ({ name, onClick, isSelected, svg }) => {
    const [mouseEnter, setMouseEnter] = useState(false);


    return (
        <div>
            <motion.div
                onMouseEnter={e => {
                    setMouseEnter(true);
                }}
                onMouseLeave={e => {
                    setMouseEnter(false);
                }}
                animate={{ scale: mouseEnter ? 1.01 : 1.0 }}
            >
                <button className='dash__button' key="3"
                    onClick={onClick}
                    style={{
                        boxShadow: isSelected ? '4px 4px 10px #ebeaea' : '0px 0px 0px #FFFFFF',
                        color: isSelected ? '#000000' : '#8A8A8A',
                        display: 'flex',
                        gap: '7px',
                        alignItems: 'center'
                    }}>
                    <div className="sidebarbutton__svg" 
                    style={{marginTop: '3px'}}
                    >
                        <img src={svg} />
                    </div>
                    <div className="sidebarbutton__name">
                        {name}
                    </div>

                </button>

            </motion.div>
        </div>
    )
}

export default SideBarButton