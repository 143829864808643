/*import React, { useState } from 'react';

const sectionTypes = ['Chorus', 'Verse', 'Pre-Chorus', 'Intro', 'Outro'];

const ColabLyrics = () => {
  const [sections, setSections] = useState([
    {
      id: Date.now(),
      type: 'Lyrics',
      content: '',
    },
  ]);

  const [isAddingSection, setIsAddingSection] = useState(false);
  const [newSectionName, setNewSectionName] = useState('');

  const handleContentChange = (id, newContent) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id ? { ...section, content: newContent } : section
      )
    );
  };

  const addSectionClick = () => {
    setIsAddingSection(true);
    setNewSectionName('');
  };

  const handleNewSectionNameChange = (e) => {
    setNewSectionName(e.target.value);
  };

  const handleNewSectionKeyDown = (e) => {
    if (e.key === 'Enter') {
      confirmAddSection();
    }
  };

  const getSuggestion = () => {
    const input = newSectionName.toLowerCase();
    const suggestion = sectionTypes.find((type) =>
      type.toLowerCase().startsWith(input)
    );
    if (suggestion && suggestion.toLowerCase() !== input) {
      return suggestion.slice(newSectionName.length);
    } else {
      return '';
    }
  };

  const confirmAddSection = () => {
    if (newSectionName.trim() === '') {
      return; // Do not add empty section names
    }

    // If there is a suggestion, complete the section name
    const suggestion = getSuggestion();
    const finalSectionName =
      suggestion ? newSectionName + suggestion : newSectionName;

    setSections([
      ...sections,
      {
        id: Date.now(),
        type: finalSectionName.trim(),
        content: '',
      },
    ]);
    setIsAddingSection(false);
    setNewSectionName('');
  };

  return (
    <div className="lyrics__maincontainer">
      <div className="lyrics__box">
        <div className="lyrics__title">Lyrics</div>
        {sections.map((section) => (
          <div key={section.id}>
            <div className="lyrics__sectionname">{section.type}</div>
            <div className="lyrics__lyricscontainer">
              <textarea
                className="lyrics__lyrics"
                value={section.content}
                onChange={(e) =>
                  handleContentChange(section.id, e.target.value)
                }
                rows={Math.max(section.content.split('\n').length, 1)}
                style={{ resize: 'none', overflow: 'hidden', width: '100%' }}
              />
            </div>
          </div>
        ))}

        {isAddingSection ? (
          <div className="lyrics__addsection">
            <div className="input-with-suggestion">
              <input
                type="text"
                value={newSectionName}
                onChange={handleNewSectionNameChange}
                onKeyDown={handleNewSectionKeyDown}
                autoFocus
              />
              <div className="suggestion">
                <span className="input-text">{newSectionName}</span>
                <span className="suggested-text">{getSuggestion()}</span>
              </div>
            </div>
            <button onClick={confirmAddSection}>Add Section</button>
          </div>
        ) : (
          <button onClick={addSectionClick}>Add New Section</button>
        )}
      </div>
    </div>
  );
};

export default ColabLyrics;
*/

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import LyricsTextElement from '../productivity/LyricsTextElement';

const ColabLyrics = (props) => {
  const { projectData, showMemberPanel } = props;
  const [lyrics, setLyrics] = useState(null); // Use null to indicate loading state
  const { id } = useParams();

  useEffect(() => {
    if (projectData) {
      if (projectData.lyrics !== undefined) {
        setLyrics(projectData.lyrics);
      } else {
        setLyrics('');
      }
    }
  }, [projectData]);

  useEffect(()=>{

  },[showMemberPanel])



  const saveLyrics = async (newLyrics) => {
    if (lyrics !== newLyrics) {
      try {
        const docRef = doc(db, 'invites', id);
        await updateDoc(docRef, { lyrics: newLyrics });
        console.log('Lyrics saved successfully');
      } catch (error) {
        console.error('Error saving lyrics: ', error);
      }
    }
  };

  if (lyrics === null) {
    // Wait for lyrics to load before rendering
    return <div>Loading...</div>;
  }

  const containerStyle = {
    zIndex: showMemberPanel ? 0 : 1, // Adjust z-index based on showMemberPanel
    position: 'relative', // Ensure z-index is applied properly
  };

  return (
    <div className="lyrics__maincontainer">
      <div className="lyrics__box"  style={containerStyle}>
        <div className="lyrics__title">Lyrics</div>
        <LyricsTextElement value={lyrics} onSaveLyrics={saveLyrics} />
      </div>
    </div>
  );
};

export default ColabLyrics;
