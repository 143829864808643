import React from 'react'

import { motion } from "framer-motion"
import { useState } from 'react';

const OneProject = (props) => {
    const projectName = props.name;
    const numMembers = props.members;
    const precentDone = props.precent;
    const[mouseEnter, setMouseEnter] = useState(false);

  return (
    <motion.div className='projects__project' style={{
      boxShadow: '4px 4px 10px #ebeaea',
    }}
    
    onMouseEnter={e => {
      setMouseEnter(true);
  }}
  onMouseLeave={e => {
      setMouseEnter(false);
  }}
  animate={{scale: mouseEnter ? 1.00 : 1,
    translateY: mouseEnter ? -2.0 : 0
  }}
    
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}>
      <div className='project__xbutton'>
        
      </div>
        <div className='project__title'>
            {projectName}
        </div>
        <div>

        </div>
        
    </motion.div>
  )
}

export default OneProject