import React, { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, serverTimestamp, updateDoc, doc, deleteDoc, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserAuth } from '../../context/AuthContext';

const OneHorizontalFile = (props) => {
    const name = props.name;
    const randurl = props.url;
    const members = props.members;
    const inFiles = props.isInFiles;
    const [mouseEnter, setMouseEnter] = useState(false);
    const [timeFromChange, setTimeFromChange] = useState("0 seconds ago");

    const { isUpdated } = props;

      const { user } = UserAuth();

    // --- REORDERING ---
    const { draggable, onDragStart, onDragEnter, onDragEnd } = props;

    // EXACTLY THE SAME RIGHT-CLICK SHARE MENU LOGIC AS IN OneFile:
    const { isProject = false } = props;
    const { plugin = false } = props;
    const navigate = useNavigate();

    // For rename logic
    const initialName = props.name;
    const [showInvitePopup, setShowInvitePopup] = useState(false);
    const [inviteLink, setInviteLink] = useState('');

    const [showShareButton, setShowShareButton] = useState(false);
    const [showShareMenu, setShowShareMenu] = useState(false);
    const shareButtonRef = useRef(null);
    const shareMenuRef = useRef(null);

    // This is just a local rename state (if you want to rename from the context menu).
    // If you have a separate renaming flow, adapt as needed.
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(name);
    const inputRef = useRef(null);

    // Listen for invite popup to generate link
    useEffect(() => {
        if (showInvitePopup && !inviteLink) {
            generateInviteLink();
        }
    }, [showInvitePopup, inviteLink]);

    // Generate the invite link (same as in OneFile)
    const generateInviteLink = async () => {
        try {
            const token = Math.random().toString(36).substr(2);

            let inviteCollectionName = 'fileInvites';
            let docData = {
                token,
                isUsed: false,
                createdAt: serverTimestamp(),
            };

            if (isProject) {
                inviteCollectionName = 'projectInvites';
                docData.projectId = props.id;  // Adjust if your project logic differs
            } else {
                docData.fileId = props.id;
            }

            const inviteRef = collection(db, inviteCollectionName);
            await addDoc(inviteRef, docData);

            const pathPrefix = isProject ? 'projectinvite' : 'invite';
            const link = `${window.location.origin}/${pathPrefix}/${token}`;
            setInviteLink(link);

        } catch (error) {
            console.error('Error generating invite link:', error);
        }
    };

    // Rename logic (if you want to rename from the context menu)
    const renameDocument = async (newName) => {
        if (!props.id) return;
        try {
            if (isProject) {
                await updateDoc(doc(db, 'workspaces', props.id), {
                    name: newName,
                    lastUpdated: serverTimestamp(),
                });
            } else {
                await updateDoc(doc(db, 'invites', props.id), {
                    name: newName,
                    lastUpdated: serverTimestamp(),
                });
            }
        } catch (error) {
            console.error('Error renaming document:', error);
        }
    };

    // Handle rename click
    const handleRename = () => {
        setIsEditing(true);
        setShowShareMenu(false);
    };

    // Once we start editing, focus the input
    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);

    // When user finishes editing name
    const handleBlur = async () => {
        setIsEditing(false);
        if (newName !== initialName) {
            await renameDocument(newName);
        }
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            setIsEditing(false);
            if (newName !== initialName) {
                await renameDocument(newName);
            }
        }
    };

    // Opening the file / project
    // (This logic can be adapted to match your actual routing as needed)
    const openProject = () => {
        if (plugin) {
            let duration = 0;
            if (props.duration) {
                duration = props.duration;
            }
            navigate(`/plugin/${props.id}/${props.name}/${duration}/1`);
        } else {
            updateDoc(doc(db, "invites", props.id), {
                unreadUsers: arrayRemove(user.email),
            });
            navigate(`/project/${props.id}/${props.name}`);
        }
    };

    // Time logic
    useEffect(() => {
        const updateTimeFromChange = () => {
            if (props.lastUpdated && !isNaN(new Date(props.lastUpdated).getTime())) {
                setTimeFromChange(formatRelativeTime(new Date(props.lastUpdated)));
            } else {
                setTimeFromChange("0 seconds ago");
            }
        };

        updateTimeFromChange();
        const intervalId = setInterval(() => {
            updateTimeFromChange();
        }, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, [props.lastUpdated]);

    const formatRelativeTime = (date) => {
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);

        let value;
        let unit;

        if (diffInSeconds < 60) {
            value = diffInSeconds;
            unit = "second";
        } else if (diffInSeconds < 3600) {
            value = Math.floor(diffInSeconds / 60);
            unit = "minute";
        } else if (diffInSeconds < 86400) {
            value = Math.floor(diffInSeconds / 3600);
            unit = "hour";
        } else if (diffInSeconds < 2592000) {
            value = Math.floor(diffInSeconds / 86400);
            unit = "day";
        } else if (diffInSeconds < 31536000) {
            value = Math.floor(diffInSeconds / 2592000);
            unit = "month";
        } else {
            value = Math.floor(diffInSeconds / 31536000);
            unit = "year";
        }

        const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
        return rtf.format(-value, unit);
    };

    const handleDelete = async () => {
        if (isProject) {
            await deleteDoc(doc(db, 'workspaces', props.id));
        } else {
            await deleteDoc(doc(db, 'invites', props.id));
        }
    };

    return (
        <motion.div
            className='onehorizontalfile__container'
            onContextMenu={(e) => {
                e.preventDefault();
                console.log("right click")
                setShowShareMenu(true);
            }}
            draggable={draggable}
            style={{
                margin: `0 0 ${inFiles ? '5px' : '1rem'} 0`,
            }}
            onDragStart={onDragStart}
            onDragEnter={onDragEnter}
            onDragEnd={onDragEnd}
            onClick={() => {
                // Only open if not editing
                if (!isEditing) {
                    openProject();
                }
            }}
            onMouseEnter={() => setMouseEnter(true)}
            onMouseLeave={() => {
                setMouseEnter(false);

                setShowShareMenu(false);
            }}
        >
            <motion.div
                className='invites__headercontainer'
                style={{
                    backgroundColor: "#FFFFFF",
                    boxShadow: '4px 4px 10px #ebeaea',
                    marginRight: `${inFiles ? '20px' : '35px'}`,
                    overflow: 'visible'
                }}
                animate={{
                    scale: mouseEnter ? 1.005 : 1,
                }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}

            >
                <motion.div
                    className='invites__albumcover'
                    animate={{
                        scale: mouseEnter ? 1 : 1,
                    }}
                >
                    <img src={randurl} className='invites__img' alt={name} />
                </motion.div>

                <div className='invites__songdetails'>
                    {/* If we are editing, show input; otherwise, show name */}
                    {isEditing ? (
                        <input
                            ref={inputRef}
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                        />
                    ) : (
                        <div className='invites__songtitle'>{newName}</div>
                    )}

                    <div className='invites__songmembers'>
                        {members} members
                    </div>
                </div>

                <div className='oneinvite__rightcornertimestamp'>
                    {timeFromChange}

                    {isUpdated && (
                        <motion.div
                            transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 10,
                                duration: 1.5
                            }}
                            initial={{ opacity: 0, scale: 0.0, translateY: -65, translateX: 8 }}
                            whileInView={{ opacity: 1, scale: 1.0 }}
                            viewport={{ once: true }}
                            className='oneinvite__isupdated'
                        />
                    )}
                </div>



                {/* SHARE MENU (appears on right-click or share button click) */}

            </motion.div>

            {/* INVITE POPUP */}
            <AnimatePresence>
                {showInvitePopup && (
                    <motion.div
                        className="files__invitePopup"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                        transition={{ duration: 0.2 }}
                        onClick={(e) => e.stopPropagation()}
                        style={{ zIndex: 9999 }}
                    >
                        <div className="files__invitePopupContent">
                            <h3>Share this link</h3>
                            {inviteLink ? (
                                <>
                                    <input
                                        type="text"
                                        value={inviteLink}
                                        readOnly
                                        onClick={(e) => e.target.select()}
                                    />
                                    <button onClick={() => setShowInvitePopup(false)}>
                                        Close
                                    </button>
                                </>
                            ) : (
                                <>
                                    <p>Generating link...</p>
                                </>
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {showShareMenu && (
                    <motion.div
                        className="files__shareMenu"

                        ref={shareMenuRef}
                        initial={{ opacity: 0, y: -10, x: 0 }}
                        animate={{ opacity: 1, y: -80, x: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        onClick={(e) => e.stopPropagation()}
                        style={{ zIndex: 9999, top: 'inherit' }}
                    >
                        <div
                            className="files__shareMenuItem"
                            onClick={() => {
                                setShowInvitePopup(true);
                                setShowShareMenu(false);
                            }}
                        >
                            Invite by Link
                        </div>

                        <div
                            className="files__shareMenuItem"
                            onClick={handleRename}
                        >
                            Rename
                        </div>

                        <div
                            className="files__shareMenuItem"
                            onClick={handleDelete}
                        >
                            Delete
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
};

export default OneHorizontalFile;
