import React, { useState, useRef } from 'react';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { storage, db } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import formX from '../invites/formX.svg';
import FileAddSVG from '../invites/fileadd.svg'; // or wherever your "Add" SVG lives
import SkeletonSound from '../invites/SkeletonSound3.jpg';
import { UserAuth } from '../../context/AuthContext';

/**
 * CoverEditor handles:
 *   - Displaying existing or placeholder image (SkeletonSound)
 *   - Uploading a new cover image to Firebase Storage
 *   - Saving the new image's URL in Firestore under invites/{projectId}/imgUrl
 * 
 * Props:
 *   - projectId        (the Firestore doc ID for this project in "invites")
 *   - onClose          (function to close this modal)
 *   - existingImageUrl (the current saved image URL, if any; fallback to SkeletonSound)
 */
const CoverEditor = ({ projectId, onClose, existingImageUrl }) => {
    const [imageFile, setImageFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [uploading, setUploading] = useState(false);

    const fileInputRef = useRef(null);

      const { user } = UserAuth();

    // Let user click on the placeholder or preview to pick an image.
    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // On file selection, immediately create a preview. 
    // (We can upload later when they click "Save", or immediately—your choice.)
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setImageFile(file);
        setImagePreviewUrl(URL.createObjectURL(file));
    };

    // Actually upload to Firebase Storage & save in Firestore
    const handleSaveCover = async () => {
        if (!imageFile) {
            // no new file selected
            onClose();
            return;
        }

        try {
            setUploading(true);
            const storageRef = ref(storage, `coverImages/${user.uid}/${projectId}`);
            const uploadTask = uploadBytesResumable(storageRef, imageFile);



            uploadTask.on(
                'state_changed',
                () => {
                    // Progress, if you want to show a progress bar
                },
                (error) => {
                    console.error('Upload error:', error);
                },
                async () => {
                    // Completed upload
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                    // Save the download URL in your Firestore doc
                    await setDoc(
                        doc(db, 'invites', projectId),
                        {
                            imgUrl: downloadURL,
                            updatedAt: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    setUploading(false);
                    onClose();
                }
            );
        } catch (error) {
            console.error('Error saving cover:', error);
            setUploading(false);
        }
    };

    return (
        <div className="invites__formblur">
            <div className="colab__form">
                {/* Modal header */}
                <div className="colab__cover__formtop">
                    <div className="invites__formtitle">Cover</div>
                    <div className="invites__xbutton" onClick={onClose}>
                        <img src={formX} className="invites__imgsize" alt="close" />
                    </div>
                </div>

                {/* Image preview / placeholder */}
                <div className="colab__cover__img" onClick={handleImageClick}>
                    {imagePreviewUrl ? (
                        <img
                            src={imagePreviewUrl}
                            className="invites__formpreviewimg"
                            alt="Preview"
                        />
                    ) : existingImageUrl ? (
                        <img
                            src={existingImageUrl}
                            className="invites__formpreviewimg"
                            alt="Existing cover"
                        />
                    ) : (
                        // Fallback: if no user-set cover, show SkeletonSound or an "Add" icon
                        <img
                            src={FileAddSVG}
                            className="invites__formsvg"
                            alt="Default cover"
                        />
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleImageUpload}
                        style={{ display: 'none' }}
                    />
                </div>

                <div className="colab__cover__gap" />

                {/* "Save" button */}
                <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                    <button className='invite__button' onClick={handleSaveCover} disabled={uploading} >
                        {uploading ? 'Uploading...' : 'Save'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CoverEditor;
