import React, { useEffect, useState, useRef } from 'react';
import Play from './playsound.svg';
import Pause from './PauseSound.svg';
import './colabeditor.css';
import WaveSurfer from 'wavesurfer.js';
import { upload } from '../../firebase';
import { UserAuth } from '../../context/AuthContext';
import { storage } from '../../firebase';
import { v4 } from 'uuid';
import {
    listAll,
    ref,
    uploadBytes,
    uploadBytesResumable,
    getDownloadURL
} from 'firebase/storage';
import { useParams } from 'react-router-dom';
import AddMemberSVG from '../files/adduser.svg';
import HamburgerSVG from './HamburgerSVG.svg';
import formX from '../invites/formX.svg';
import { db } from '../../firebase';
import {
    doc,
    collection,
    onSnapshot,
    setDoc,
    deleteDoc,
    query,
    where,
    getDocs,
    orderBy,
    getDoc,
    serverTimestamp,
    updateDoc,
    addDoc,
    arrayUnion
} from 'firebase/firestore';
import ColabMessages from './ColabMessages';
import OneMember from './OneMember';
import SkeletonWave from './SkeletonWave';
import { motion, AnimatePresence } from 'framer-motion';
import OneMode from './OneMode';
import ColabLyrics from './ColabLyrics';
import ElementContainer from '../elementcontainer/ElementContainer';
import ColabToDo from './ColabToDo';
import LufsMeter from './LufsMeter';
import PolarMeter from './PolarMeter';
import SideBarButton from '../useful/SideBarButton';
import SideBarShareLinkButton from '../useful/SideBarShareLinkButton';
import OneUserFiles from '../files/OneUserFiles';

import SkeletonSound from '../invites/SkeletonSound3.jpg';
import CoverEditor from './CoverEditor';

import MessagesSVG from './MessagesSVG.svg';
import LyricsSVG from './LyricsSVG.svg';
import ToDoSVG from './todoSVG.svg';

const ColabEditor = (props) => {
    const [fileLoaded, setFileLoaded] = useState(true);
    const [progress, setProgress] = useState(0);
    const [ready, setReady] = useState(false);
    const [songFile, setSongFile] = useState('');
    const [playState, setPlayState] = useState(false);
    const websurfer = useRef(null);
    const [loading, setLoading] = useState(false);
    const { user } = UserAuth();
    const [fileRefForD, setFileRefForD] = useState(null);
    const [fileUrl, setFileUrl] = useState(
        'https://s3.eu-central-1.amazonaws.com/some-sprouts/Mindcrush.mp3'
    );
    const waveformRef = useRef();
    const [playing, setPlaying] = useState(false);
    const [pluginid, setPluginId] = useState('');
    const [projectData, setProjectData] = useState([]);
    const [currentCommentValue, setCurrentCommentValue] = useState('');
    const [showMemberPanel, setShowMemberPanel] = useState(false);
    const [progresspercent, setProgresspercent] = useState(0);
    const [newMemberDataLoaded, setNewMemberDataLoaded] = useState(false);
    const [memberTextAreaFocus, setMemberTextAreaFocus] = useState(false);
    const [newMembersId, setNewMembersId] = useState('');
    const [numMember, setNumMember] = useState(0);
    const [playhead, setPlayhead] = useState(0);
    const [waveWidth, setWaveWidth] = useState(0);
    const [duration, setDuration] = useState(0);
    const [placeholder, setPlaceholder] = useState(true);
    const [invitesId, setInvitesId] = useState(props.iid);
    const [triggerDB, setTriggerDB] = useState(false);
    const [memberAddEmailValue, setMemberAddEmailValue] = useState('');
    const [playerReady, setPlayerReady] = useState(false);
    const [showImgEditor, setShowImageEditor] = useState(false);
    const [allMembers, setAllMembers] = useState({});
    const [newMember, setNewMember] = useState({});
    const [addButtonHover, setAddButtonHover] = useState(false);
    const [viewWidth, setViewWidth] = useState(window.innerWidth);
    const filereff = useRef();
    const [fileLocation, setFileLocation] = useState(
        'https://s3.eu-central-1.amazonaws.com/some-sprouts/Mindcrush.mp3'
    );
    const [fileUpload, setFileUpload] = useState(null);
    const [time, setTime] = useState(0);
    const uid = user.uid;
    const ws = useRef();
    let params = useParams();
    const waveWidthRef = useRef(null);
    const wrapperRef = useRef(null);


    const [selectedMode, setSelectedMode] = useState('Messages');

    const emailInputRef = useRef(null);

    const [users, setUsers] = useState({});

    // NEW STATE for toggling the email input
    const [newUserEmail, setNewUserEmail] = useState('');
    const [showAddUserPopup, setShowAddUserPopup] = useState(false);
    const [showEmailInput, setShowEmailInput] = useState(false);

    // WaveSurfer bar detail
    const defaultBarWidth = 5;
    const defaultBarGap = 1;
    const defaultBarRadius = 5;
    const detailedBarWidth = 1;
    const detailedBarGap = 1;
    const detailedBarRadius = 1;

    //======================== WAVE SURFER SETUP ========================
    useEffect(() => {
        ws.current = WaveSurfer.create({
            container: '#waveform',
            waveColor: '#E3E3EC',
            progressColor: '#bfbfbf',
            responsive: true,
            height: 70,
            barGap: defaultBarGap,
            barWidth: defaultBarWidth,
            barRadius: defaultBarRadius
        });

        ws.current.on('ready', function () {
            setPlayerReady(true);
        });

        // Update timecode during playback
        ws.current.on('audioprocess', function () {
            setTime(ws.current.getCurrentTime());
            setPlayhead(ws.current.getCurrentTime());
        });

        // Update timecode when seeking
        ws.current.on('seek', function () {
            setTime(ws.current.getCurrentTime());
            setPlayhead(ws.current.getCurrentTime());
        });

        return () => {
            ws.current && ws.current.destroy();
        };
    }, []);

    const increaseDetail = () => {
        if (ws.current) {
            ws.current.params.barWidth = detailedBarWidth;
            ws.current.params.barGap = detailedBarGap;
            ws.current.params.barRadius = detailedBarRadius;
            ws.current.drawBuffer();
        }
    };

    const decreaseDetail = () => {
        if (ws.current) {
            ws.current.params.barWidth = defaultBarWidth;
            ws.current.params.barGap = defaultBarGap;
            ws.current.params.barRadius = defaultBarRadius;
            ws.current.drawBuffer();
        }
    };

    const [position, setPosition] = useState(0);
    const [muted, setMuted] = useState(false);

    //======================== FIREBASE LOGIC ========================
    function handleChange(e) {
        if (e.target.files[0]) {
            setFileUpload(e.target.files[0]);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setViewWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getPluginId = async () => {
        const docRef = doc(db, 'invites', params.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setProjectData(docSnap.data());
        } else {
            // docSnap not found
        }
    };

    useEffect(() => {
        if (user.uid) {
            getPluginId();
        }
    }, [user.uid]);

    useEffect(() => {
        setFileLoaded(!fileLoaded);
    }, [triggerDB]);

    useEffect(() => {
        console.log(selectedMode);
    }, [selectedMode]);

    const uploadfile = (e) => {
        setLoading(true);

        const fileRef = ref(storage, `songs/${uid}/${params.name}`);
        setFileLocation(`songs/${uid}/${params.name}`);

        const uploadTask = uploadBytesResumable(fileRef, fileUpload);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFileUrl(downloadURL);
                    setLoading(false);
                    setTriggerDB(!triggerDB);
                });
            }
        );
    };

    // Prevent spacebar from scrolling the page
    window.addEventListener('keydown', function (e) {
        if (e.keyCode === 32 && e.target === document.body) {
            e.preventDefault();
        }
    });

    document.body.onkeyup = function (e) {
        const isTyping = document.querySelector('textarea') === document.activeElement;
        if (e.keyCode === 32 && !isTyping) {
            e.preventDefault();
            togglePlayState();
        }
    };

    const togglePlayState = () => {
        setPlayState(!playState);
        if (playState) {
            ws.current.pause();
        } else {
            ws.current.play();
        }
    };

    const deleteComment = async (id) => {
        try {
            await deleteDoc(doc(db, 'comments', id));
        } catch (error) {
            console.error('Error deleting comment: ', error);
        }
    };

    const formatTimecode = (time) => {
        const totalSeconds = parseInt(time, 10);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
        const seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);
        const milliseconds = Math.floor((time % 1) * 1000);

        const pad = (num, size = 2) => num.toString().padStart(size, '0');
        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}:${pad(milliseconds, 3)}`;
    };

    //======================== WAVE RESIZE ========================
    var doit;
    function resizedw() {
        setWaveWidth(waveWidthRef.current.offsetWidth);
    }

    window.onresize = function () {
        clearTimeout(doit);
        doit = setTimeout(function () {
            resizedw();
        }, 100);
    };

    useEffect(() => { }, [waveWidth]);

    const openImageChooser = () => {
        setShowImageEditor(true);
    };

    const hideImgEditor = () => {
        setShowImageEditor(false);
    };

    //======================== ADD MEMBER ========================
    const addMember = async (e) => {
        e.preventDefault();
        setNewMemberDataLoaded(false);
        setNewMember({});
        setNewMembersId('');

        const q = query(collection(db, 'users'), where('email', '==', memberAddEmailValue));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log('No user with that email');
        }

        querySnapshot.forEach((doc) => {
            setNewMembersId(doc.id);
            setNewMember(doc.data());
        });
    };

    const addMemberToProject = async () => {
        if (newMembersId) {
            const invitationCollectionRef = collection(db, 'invitations');
            const newInvite = {
                projectId: params.id,
                userId: newMember.email,
                accepted: null,
                time: serverTimestamp(),
                inviter: user.email,
                projectname: params.name
            };
            await setDoc(doc(invitationCollectionRef, params.id), newInvite);
        }
    };

    const addUserButtonRef = useRef(null);
    const popupRef = useRef(null);
    const [popupPosition, setPopupPosition] = useState({});

    const handleAddUserClick = () => {
        if (addUserButtonRef.current) {
            const rect = addUserButtonRef.current.getBoundingClientRect();
            setPopupPosition({
                top: rect.bottom + window.scrollY + 5
            });
            setShowAddUserPopup(true);
        }
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(e.target) &&
                addUserButtonRef.current &&
                !addUserButtonRef.current.contains(e.target)
            ) {
                setShowAddUserPopup(false);
                setShowEmailInput(false); // reset the email input state when popup is closed
            }
        };
        if (showAddUserPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showAddUserPopup]);

    useEffect(() => {
        getPluginId();
        setMemberAddEmailValue('');
    }, [numMember]);

    useEffect(() => { }, [waveWidth]);

    useEffect(() => {
        const interval = setInterval(() => {
            setWaveWidth(waveWidthRef.current.offsetWidth);
        }, 50);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setWaveWidth(waveWidthRef.current.offsetWidth);
    }, []);

    useEffect(() => {
        if (playerReady) {
            setDuration(ws.current.getDuration());
        }
    }, [playerReady]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (playState) {
            ws.current.play();
        }
    }, [playerReady]);

    const variants = {
        open: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: '30%' }
    };

    const onFocus = () => setMemberTextAreaFocus(true);
    const onBlur = () => setMemberTextAreaFocus(false);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowMemberPanel(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        if (!memberTextAreaFocus) {
            if (memberAddEmailValue.length === 0) {
                setAddButtonHover(false);
            }
        }
    }, [memberTextAreaFocus]);

    useEffect(() => { }, [memberAddEmailValue]);

    const goToComment = (position) => {
        if (ws.current) {
            const duration = ws.current.getDuration();
            const progress = position / duration;
            ws.current.seekTo(progress);
            setTime(position);
        }
    };

    const getCurrentTime = () => {
        return ws.current ? ws.current.getCurrentTime() : 0;
    };

    useEffect(() => {
        setPluginId(projectData.pluginId);
        if (projectData.fileUrl) {
            setFileLoaded(true);
            setFileUrl(projectData.fileUrl);
        }

        // Example user data (replace with your real logic)
        setUsers((prevUsers) => ({
            ...prevUsers,
            [user.email]: { name: 'Oddur', uid: user.email },
            Ozi8HII7bjT1zfAEdRcKXRuJQTy1: { name: 'Gunnar', uid: 'Ozi8HII7bjT1zfAEdRcKXRuJQTy1' }
        }));
    }, [projectData, user.email]);

    useEffect(() => {
        if (user.uid) {
            setAllMembers(projectData.team);
        }
    }, [projectData, user.uid]);

    const renderedMembers = Array.isArray(allMembers) ? allMembers.slice(0, 3) : [];
    const extraMembers = Array.isArray(allMembers) && allMembers.length > 3
        ? allMembers.slice(3)
        : [];
    const extraMembersCount = extraMembers.length;

    const [showExtraHover, setShowExtraHover] = useState(false);


    useEffect(() => {
        if (fileUrl) {
            if (fileUrl !== 'https://s3.eu-central-1.amazonaws.com/some-sprouts/Mindcrush.mp3') {
                updateData('', { fileUrl });
                ws.current.load(fileUrl);
                setPlaceholder(false);
            }
        }
    }, [fileUrl]);

    function updateData(collPath, data) {
        setDoc(doc(db, 'invites', params.id), data, { merge: true });
    }

    useEffect(() => {
        if (fileUrl !== 'https://s3.eu-central-1.amazonaws.com/some-sprouts/Mindcrush.mp3') {
            setFileUrl(props.songlink);
        }
    }, []);

    const changeMode = (mode) => {
        setSelectedMode(mode);
    };

    // The original function to finalize adding a user
    const handleAddUser = () => {
        const invitesDocRef = doc(db, 'invites', params.id);
        updateDoc(invitesDocRef, {
            team: arrayUnion(newUserEmail)
        })
            .then(() => {
                setNewUserEmail('');
                setShowAddUserPopup(false);
                setShowEmailInput(false); // hide input again after adding
            })
            .catch((error) => {
                alert(error);
            });
    };

    // The new button logic: first click shows the textbox, second click calls handleAddUser
    const handleAddUserByEmailClick = () => {
        // If the textbox is not shown, show it
        if (!showEmailInput) {
            setShowEmailInput(true);
            setTimeout(() => emailInputRef.current?.focus(), 0);
        }
        // Otherwise, if user typed something, add them
        else if (newUserEmail) {
            handleAddUser();
        }
    };

    //======================== SHARE-LINK FUNCTIONALITY ========================
    const [showShareMenu, setShowShareMenu] = useState(false);
    const [inviteLink, setInviteLink] = useState('');
    const shareButtonRef = useRef(null);

    // Generate link if not already generated
    useEffect(() => {
        if (showShareMenu && !inviteLink) {
            generateInviteLink();
        }
    }, [showShareMenu, inviteLink]);

    const generateInviteLink = async () => {
        try {
            const token = Math.random().toString(36).substr(2);
            const inviteRef = collection(db, 'fileInvites');
            // Instead of addDoc, we can do setDoc with the token as the doc ID
            await setDoc(doc(inviteRef, token), {
                token,
                fileId: params.id,
                isUsed: false,
                createdAt: serverTimestamp()
            });
            const link = `${window.location.origin}/invite/${token}`;
            setInviteLink(link);
        } catch (error) {
            console.error('Error generating invite link:', error);
        }
    };

    // Simple helper to copy link
    const copyToClipboard = (text) => {
        if (!text) return;
        navigator.clipboard
            .writeText(text)
            .then(() => alert('Link copied to clipboard!'))
            .catch((err) => console.error('Could not copy text: ', err));
    };

    return (
        <div>
            {/* ======================== COVER EDITOR POPUP ======================== */}
            {showImgEditor && (
                <CoverEditor
                    projectId={params.id}
                    onClose={hideImgEditor}
                    existingImageUrl={projectData?.imgUrl} // If the user already has a custom cover
                />
            )}

            {/* ======================== MEMBER PANEL ======================== */}
            <div
                style={{
                    position: 'fixed',
                    zIndex: 4,
                    top: '0',
                    right: '0',
                    bottom: '0',
                    left: '0',
                    opacity: !showMemberPanel ? '0' : '1',
                    transition: 'all .15s',
                    visibility: !showMemberPanel ? 'hidden' : 'visible'
                }}
            >
                <motion.div
                    className="colab__memberpanel"
                    ref={wrapperRef}
                    animate={showMemberPanel ? 'open' : 'closed'}
                    variants={variants}
                >
                    {showMemberPanel && (
                        <motion.div>
                            <div className="memberpanel__top">
                                <div className="memberpanel__title">Modes</div>
                                <div
                                    className="memberpanel__xbutton"
                                    onClick={() => {
                                        setShowMemberPanel(false);
                                    }}
                                >
                                    <img src={formX} className="invites__imgsize" alt="close" />
                                </div>
                            </div>
                        </motion.div>
                    )}

                    {/* =================== SIDEBAR BUTTONS =================== */}
                    <div className="colab__modes">
                        <SideBarButton
                            name={"Messages"}
                            onClick={() => changeMode("Messages")}
                            isSelected={selectedMode === "Messages"}
                            svg={MessagesSVG}
                        />
                        <SideBarButton
                            name={"To-Do"}
                            onClick={() => changeMode("To-Do")}
                            isSelected={selectedMode === "To-Do"}
                            svg={ToDoSVG}
                        />
                        <SideBarButton
                            name={"Lyrics"}
                            onClick={() => changeMode("Lyrics")}
                            isSelected={selectedMode === "Lyrics"}
                            svg={LyricsSVG}
                        />
                    </div>
                </motion.div>
            </div>

            {/* ======================== TOP BAR (TITLE, COVER) ======================== */}
            <div className="colab__top">
                <div className="colab__title">
                    {params.name}
                    <div className="colab__imgcontainer" onClick={openImageChooser}>
                        <img
                            src={projectData?.imgUrl ? projectData.imgUrl : SkeletonSound}
                            className="colab__img"
                            alt="Cover"
                        />
                    </div>
                </div>
                <div className="colab__topright">
                    <div className="files__users">
                        {/* Show first 3 users */}
                        {renderedMembers.map((memberEmail) => (
                            <OneUserFiles key={memberEmail} userEmail={memberEmail} />
                        ))}

                        {/* Show +X if more than 3 */}

                        {extraMembersCount > 0 && (
                            <div
                                className="files__moremembers"
                                onMouseEnter={() => setShowExtraHover(true)}
                                onMouseLeave={() => setShowExtraHover(false)}
                            >
                                +{extraMembersCount}

                                {/* The popup with extra user emails */}
                                <AnimatePresence>
                                    {showExtraHover && (
                                        <motion.div
                                            initial={{ opacity: 0, y: 0 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: 0 }}
                                            transition={{ duration: 0.2 }}
                                            className="files__moremembers__popup"
                                        >
                                            {extraMembers.map((email) => (
                                                <div
                                                    key={email}
                                                    className="files__moremembers__row"
                                                >
                                                    {email}
                                                </div>
                                            ))}
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        )}

                        {/* The "Add user" button */}
                        <button
                            ref={addUserButtonRef}
                            className="files__adduserbutton"
                            onClick={handleAddUserClick}
                        >
                            <img
                                src={AddMemberSVG}
                                className="files__addmembersvg"
                                style={{ height: 20 }}
                                alt="Add User"
                            />
                        </button>
                        {/* The pop-up for adding user by email or by link */}
                        <AnimatePresence>
                            {showAddUserPopup && (
                                <motion.div
                                    onClick={(e) => e.stopPropagation()}
                                    initial={{ opacity: 0, scale: 0.9, scaleY: 0.5, y: 0.0 }}
                                    animate={{ opacity: 1, scale: 1, scaleY: 1, y: 1.0 }}
                                    exit={{ opacity: 0, scale: 0.9, scaleY: 0.5 }}
                                    transition={{ duration: 0.15 }}
                                    className="files__addUserPopup"
                                    ref={popupRef}
                                    style={{
                                        top: popupPosition.top,
                                        left: popupPosition.left
                                    }}
                                >
                                    <div className="files__popupinfo">
                                        Add user by email or by link
                                    </div>

                                    <div className="files__addUserPopupButtons">
                                        <AnimatePresence>
                                            {showEmailInput && (
                                                <motion.div
                                                    initial={{ height: 0, opacity: 0 }}
                                                    animate={{ height: 'auto', opacity: 1 }}
                                                    exit={{ height: 0, opacity: 0 }}
                                                    transition={{ duration: 0.2 }}
                                                    style={{ overflow: 'hidden' }}

                                                >
                                                    <input
                                                        ref={emailInputRef}
                                                        type="email"
                                                        placeholder="User's email address"
                                                        value={newUserEmail}
                                                        onChange={(e) => setNewUserEmail(e.target.value)}
                                                        className="files__addUserInput"
                                                    />
                                                </motion.div>
                                            )}
                                        </AnimatePresence>

                                        <button
                                            onClick={handleAddUserByEmailClick}
                                            disabled={showEmailInput && !newUserEmail}
                                        >
                                            Add User By Email
                                        </button>

                                        {/* Share by Link */}
                                        <SideBarShareLinkButton
                                            className="colab__shareButton"
                                            ref={shareButtonRef}
                                            name={"Share By Link"}
                                            isSelected={showShareMenu}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowShareMenu(!showShareMenu);
                                            }}
                                            inviteLink={inviteLink}
                                            copyFunction={() => copyToClipboard(inviteLink)}
                                        />
                                    </div>

                                    {/* AnimatePresence to show/hide the email input */}

                                </motion.div>
                            )}
                        </AnimatePresence>

                        <div
                            className="files__adduserbutton"
                            style={{
                                padding: '0px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            onClick={() => {
                                if (allMembers) {
                                    setShowMemberPanel(true);
                                }
                            }}
                        >
                            <img
                                onClick={() => {
                                    if (allMembers) {
                                        setShowMemberPanel(true);
                                    }
                                }}
                                src={HamburgerSVG}
                                alt="AddMember"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* ======================== WAVEFORM ======================== */}
            <div className="colab__behindfileblur">
                <div className="colab__file">
                    <div className="colab__fileblurtest">
                        <div className="colab__blurblur"></div>
                    </div>
                    <div className="colab__fileshadow"></div>
                    {!fileLoaded && (
                        <div>
                            <div className="skeleton__wavecontainer">
                                <SkeletonWave width={waveWidth} />
                            </div>
                        </div>
                    )}

                    <div className="colab__wave">
                        <div
                            id="waveform"
                            className="colab_wave"
                            ref={waveWidthRef}
                            onMouseEnter={increaseDetail}
                            onMouseLeave={decreaseDetail}
                        />
                    </div>

                    <div
                        className="colab__transport"
                        style={{
                            marginLeft: `${(((playhead * waveWidth) * 1.001) / duration) + 9}px`
                        }}
                    ></div>
                </div>
            </div>

            {/* ======================== MODES ======================== */}
            {selectedMode === 'Messages' && (
                <ColabMessages
                    waveWidth={waveWidth}
                    duration={duration}
                    viewWidth={viewWidth}
                    projectData={projectData}
                    time={time}
                    playState={playState}
                    togglePlayState={togglePlayState}
                    goToComment={goToComment}
                    getCurrentTime={getCurrentTime}
                    memberPanelOpen={showMemberPanel}
                    users={users}
                />
            )}

            {selectedMode === 'Lyrics' && (
                <ColabLyrics projectData={projectData} showMemberPanel={showMemberPanel} />
            )}

            {selectedMode === 'To-Do' && <ColabToDo projectData={projectData} />}

            {/* ======================== METERS ======================== */}
            <div
                className="colab__lufs"
                style={{ position: 'fixed', zIndex: '3', transform: 'translate(0px, 0px)' }}
            >
                {playerReady && (
                    <LufsMeter
                        wavesurfer={ws.current ? { backend: ws.current.backend } : null}
                    />
                )}
            </div>

            {/*  
            <div
                className="colab__polar"
                style={{ position: 'fixed', zIndex: '3', transform: 'translate(0px, 0px)' }}
            >
                {playerReady && (
                    <PolarMeter wavesurfer={ws.current ? { backend: ws.current.backend } : null} />
                )}
            </div>
            */}

            {loading && (
                <div>
                    loading...
                    <div>{progresspercent}</div>
                </div>
            )}
        </div>
    );
};

export default ColabEditor;
