import React, { useEffect } from 'react'
import { useState } from "react";
import TextElement from '../textelement/TextElement'
import TitleElement from '../titleelement/TitleElement'
import CommentElement from '../commentelement/CommentElement';

import { ReactComponent as XButton } from './Xbutton.svg';
import { ReactComponent as Dragger } from './Dragger.svg';

import { Navigate, useNavigate } from 'react-router-dom';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


import './elements.css'


import { db } from "../../firebase"
import {
  doc,
  collection,
  onSnapshot,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  serverTimestamp,
  getDoc
} from "firebase/firestore"

import { UserAuth } from '../../context/AuthContext';
import { connectStorageEmulator } from 'firebase/storage';

import { useParams } from 'react-router-dom'
import MainTitle from '../maintitle/MainTitle';
import ColabMessage from '../colabeditor/ColabMessage';
import OneComment from './OneComment';


function ElementContainer() {
  const [inputList, setInputList] = useState([]);
  const [xbuttonList, setXbuttons] = useState([]);
  const [temptext, settemptext] = useState("");
  const [loadedProject, setLoadedProject] = useState("");
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(true);
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [draggersList, setDraggersList] = useState([]);
  const [localInputList, setLocalInputList] = useState([]);

  const [editorData, setEditorData] = useState("");

  const [rowIndex, setRowIndex] = useState(0);

  let params = useParams();

  const navigate = useNavigate()



  const { user } = UserAuth()
  const uid = user.uid

  const [element, setElement] = useState({
    id: "",
    type: "",
    completed: false,
    value: "",
    user: ""
  })

  const elementsCollectionRef = collection(db, "notelists");




  const getProjectName = async () => {
    const docRef = doc(db, "projects", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setProjectData(docSnap.data());
    }
    else {

    }
  }

  useEffect(() => {
    getProjectName();
    setLoadedProject(projectData.value);
  }, [])

  useEffect(() => {
    console.log(params.reload);
    if (params.reload == 1) {
      navigate(`/plugineditor/${params.id}/${params.name}/${"2"}`);
      window.location.reload();
    }
  }, [])

  useEffect(() => {
    setLocalInputList(inputList);
  }, [inputList]);

  useEffect(() => {
    setLoading(false);
  }, [inputList])

  useEffect(() => {
    if (user.uid) {
      //þetta mun verða projects
      const q = query(
        collection(db, "notelists"),
        where("project", "==", params.id),
        where("user", "==", user.uid), 
        orderBy("place", "asc")
      );

      onSnapshot(q, (snapshot) => {


        setInputList(snapshot.docs.map(doc => {
          return {
            id: doc.id,
            viewing: false,
            ...doc.data()
          }
        }))
      })
    }
  }, [user.uid])



  const onAddBtnClick = event => {
    settemptext("");
    const newTodo = {
      value: "",
      completed: false,
      user: uid,
      project: params.id,
      type: 1,
      place: rowIndex
    }

    setRowIndex(rowIndex + 1);

    //setInputList([...inputList].concat(newTodo));
    setDoc(doc(elementsCollectionRef), newTodo);

  };


  const onAddBtnClick2 = event => {
    /* const newTodo = {
      id: new Date().getTime(),
      type: <TitleElement />,
    }

    setInputList([...inputList].concat(newTodo));*/


    //window.location.reload();



    settemptext("");
    const newTodo = {
      value: "",
      completed: false,
      user: uid,
      project: params.id,
      type: 2,
      place: rowIndex
    }

    setRowIndex(rowIndex + 1);

    //setInputList([...inputList].concat(newTodo));
    setDoc(doc(elementsCollectionRef), newTodo);
  };

  const onAddBtnClick3 = event => {
    settemptext("");
    const newTodo = {
      value: "",
      completed: false,
      user: uid,
      project: params.id,
      type: 3,
      place: rowIndex
    }

    setRowIndex(rowIndex + 1);

    //setInputList([...inputList].concat(newTodo));
    setDoc(doc(elementsCollectionRef), newTodo);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(inputList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setInputList(items);

    // Update the order of elements in the database
    for (let i = 0; i < items.length; i++) {
      const elementRef = doc(db, "notelists", items[i].id);
      await setDoc(elementRef, { ...items[i], place: i }, { merge: true });
    }
  };

  const updateElementPlace = async (elementId, newIndex) => {
    try {
      const elementRef = doc(db, "notelists", elementId);
      await setDoc(
        elementRef,
        {
          place: newIndex,
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error updating element place: ", error);
    }
  };


  /*const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedInputList = reorder(
      localInputList,
      result.source.index,
      result.destination.index
    );

    setLocalInputList(updatedInputList);
  };

  useEffect(() => {
    // Update Firestore with the new order
    localInputList.forEach((element, index) => {
      updateElementPlace(element.id, index);
    });
  }, [localInputList]);*/

  const removeInputFields = (index) => {
    /* const rows = [...inputList];
     const rows2 = [...xbuttonList];
     rows.splice(index, 1);
     rows2.splice(index, 1);
     setInputList(rows);
     setXbuttons(rows2);*/
  }

  function deleteElement(id) {
    /*const updatedElements = [...inputList].filter((element) => element.id !== id);
  
    setInputList(updatedElements);*/

    setRowIndex(rowIndex - 1);

    deleteDoc(doc(db, "notelists", id));
  }

  const [t, setT] = useState(0);

  const handleDrag = (e) => {
    console.log("X: " + e.clientX + " | Y: " + e.clientY)
  }

  useEffect(() => {
    setRowIndex(inputList.length)
  }, [inputList])

  useEffect(() => {
    setLoadedProject(projectData.value);

  }, [projectData])






  return (
    <div>


      <MainTitle name={loadedProject} dbId={params.id} />

      {loading && (
        <div>
          <div className="element__skeleton" />
          <div className="element__skeleton" />
          <div className="element__skeleton" />
          <div className="element__skeleton" />
          <div className="element__skeleton" />
          <div className="element__skeleton" />
          <div className="element__skeleton" />
          <div className="element__skeleton" />
          <div className="element__skeleton" />
        </div>
      )}

      <div className='element__margin'>
        {showComments && (
          <div>
            {comments.map((element) =>
              <div key={element.id} className='element__commentcontainer'>
                <OneComment message={element.value} position={element.position} />
              </div>)}
          </div>
        )}

      </div>

      {comments && (
        <div className="element__commentstitle">
          Your Comments
        </div>
      )}

      {/*{inputList.map((element) => <div key={element.id}>
        <div>

          <button className='xbutton' onClick={() => deleteElement(element.id)}>
            <XButton />
          </button>

          <button className='dragger' draggable onDrag={handleDrag}>
            <Dragger />
          </button>

          {element.type == 1 && (
            <TextElement name={element.value} dbId={element.id} done={element.completed} user={uid} projectId={params.id} />
          )}

          {element.type == 2 && (
            <TitleElement name={element.value} dbId={element.id} done={element.completed} user={uid} projectId={params.id} />
          )}

          {element.type == 3 && (
            <CommentElement name={element.value} dbId={element.id} done={element.completed} user={uid} projectId={params.id} />
          )}



        </div>
          </div>)}*/}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="elementList">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {localInputList.map((element, index) => (
                <Draggable key={element.id} draggableId={element.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div>

                        <button className='xbutton' onClick={() => deleteElement(element.id)}>
                          <XButton />
                        </button>

                        <div className='dragger'>
                          <Dragger />
                        </div>

                        {element.type == 1 && (
                          <TextElement name={element.value} dbId={element.id} done={element.completed} user={uid} projectId={params.id} />
                        )}

                        {element.type == 2 && (
                          <TitleElement name={element.value} dbId={element.id} done={element.completed} user={uid} projectId={params.id} />
                        )}

                        {element.type == 3 && (
                          <CommentElement name={element.value} dbId={element.id} done={element.completed} user={uid} projectId={params.id} />
                        )}



                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className='button__container'>
        <button onClick={onAddBtnClick}>To Do</button>
        <button onClick={onAddBtnClick2}> Title</button>
        <button onClick={onAddBtnClick3}>Comment</button>
      </div>

      <div draggable onDrag={handleDrag}>

      </div>

    </div>
  )
}

export default ElementContainer