import React, { useState, useEffect, useRef } from 'react';
import { db } from "../../firebase";
import {
    doc,
    collection,
    onSnapshot,
    updateDoc,
    addDoc,
    serverTimestamp,
    query,
    orderBy,
    where,
} from "firebase/firestore";

import './notes.css'

import SendSVG from '../chat/ChatSend.svg'



const Notes = ({ workspaceId, user, onClose }) => {
    const [noteContent, setNoteContent] = useState('');
    const [chats, setChats] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(null);

    const messagesEndRef = useRef(null);

    // Fetch the workspace note
    useEffect(() => {
        if (workspaceId) {

            const workspaceDocRef = doc(db, "workspaces", workspaceId);
            const unsubscribe = onSnapshot(workspaceDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setNoteContent(data.note || '');
                }
            });
            return () => unsubscribe();
        }
    }, [workspaceId]);



    // Handle note changes with debounce
    const handleNoteChange = (e) => {
        setNoteContent(e.target.value);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(() => {
                const workspaceDocRef = doc(db, "workspaces", workspaceId);
                updateDoc(workspaceDocRef, {
                    note: e.target.value,
                }).catch((error) => {
                    console.error("Error updating note: ", error);
                });
            }, 2000)
        );
    };

    // Save note when closing
    const handleClose = () => {
        if (workspaceId) {

            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }
            const workspaceDocRef = doc(db, "workspaces", workspaceId);
            updateDoc(workspaceDocRef, {
                note: noteContent,
            }).catch((error) => {
                console.error("Error updating note: ", error);
            });
            onClose();
        }
    };



    // Fetch the workspace chats
    useEffect(() => {
        if (workspaceId) {

            const chatsRef = collection(db, "workspaceChats");
            const chatsQuery = query(
                chatsRef,
                where("workspaceId", "==", workspaceId),
                orderBy("timestamp", "asc")
            );

            const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
                const chatsData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setChats(chatsData);
            });

            return () => unsubscribe();
        }
    }, [workspaceId]);

    // Send a new message
    const handleSendMessage = (e) => {
        e.preventDefault();
        if (newMessage.trim() === '') return;

        const chatsRef = collection(db, "workspaceChats");
        addDoc(chatsRef, {
            workspaceId: workspaceId,
            message: newMessage,
            senderId: user.uid,
            senderName: user.displayName || user.email,
            timestamp: serverTimestamp(),
        }).then(() => {
            setNewMessage('');
            scrollToBottom();
        }).catch((error) => {
            console.error("Error sending message: ", error);
        });
    };

    // Scroll to bottom of chat when new message arrives
    useEffect(() => {
        scrollToBottom();
    }, [chats]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="notes__container">

            <div className="notes__top">

                <div className="notes__title">
                    Notes
                </div>
                <button className="notes__closeButton" onClick={handleClose}>
                    Close
                </button>
            </div>
            <div className="notes__content">

                {/* Upper Half: Note Editor */}
                <div className="notes__editor">
                    <textarea
                        value={noteContent}
                        onChange={handleNoteChange}
                        placeholder="Describe the contents of the workspace..."
                    />
                </div>
                <div className="notes__title">
                    Chat
                </div>
                {/* Lower Half: Chat */}
                <div className="notes__chat">
                    <div className="notes__chatMessages">
                        {chats.map((chat) => (
                            <div
                                key={chat.id}
                                className={`notes__chatMessage ${chat.senderId === user.uid ? 'sent' : 'received'}`}
                            >
                                {chat.senderId !== user.uid && (
                                    <div className="notes__chatSenderName">
                                        {chat.senderName}
                                    </div>
                                )}
                                <div className="notes__chatText">{chat.message}</div>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <form className="notes__chatInput" onSubmit={handleSendMessage}>
                        <input
                            type="text"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Type a message..."
                        />
                        <button type="submit">
                            <img style={{
                                height: '28px',
                                marginTop: '1px'
                            }}
                                src={SendSVG} />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Notes;
