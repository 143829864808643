import React, { useState } from 'react'

import Logo from './SoundbirdlogoSVG.svg'

import { ReactComponent as PluginsIcon } from './PluginsIconTiny.svg';
import { ReactComponent as PluginsIconGray } from './PluginsIconGray.svg'
import { ReactComponent as ProjectsIcon } from './ProjectsIconTiny.svg';
import { ReactComponent as ProjectsIconGray } from './ProjectsIconGray.svg'
import { ReactComponent as PlaylistsIcon } from './PlaylitsIconTiny.svg';
import { ReactComponent as PlaylistsIconGray } from './PlaylitsIconGray.svg'

import Projects from '../projects/Projects'
import Account from '../account/Account'
import { UserAuth } from '../../context/AuthContext'
/* Removed local WaveSurfer import */
import { Link, useNavigate } from 'react-router-dom'

import Invites from '../invites/Invites'
import _ from 'lodash';

import { motion } from "framer-motion"

const UserMainPageMock = (props) => {
    const [page, setPage] = useState(props.page);

    const [mouseEnter1, setMouseEnter1] = useState(false);
    const [mouseEnter2, setMouseEnter2] = useState(false);
    const [mouseEnter3, setMouseEnter3] = useState(false);
    const [mouseEnter4, setMouseEnter4] = useState(false);
    const [mouseEnter5, setMouseEnter5] = useState(false);

    const [parentWidth, setParentWidth] = useState(0);
    const [lengthOfCurrentSong, setLengthOfCurrentSong] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [playhead, setPlayhead] = useState(0);
    const [offsetL, setOffsetL] = useState(0);
    const [pageNum, setPageNum] = useState(2);
    const [awaitingPlay, setAwaitingPlay] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const scaleSize = 1.01;

    const onColabEditor = true;

    let inLanding = false
    if(props.inLanding)
    {
        inLanding = props.inLanding;
    }
    return (
        <div>
            <div className={inLanding ? 'landingpage__usermainpage__container' : 'main__container'}>
                <div className='main__dashmargin'>
                    <div className={inLanding ? 'landingpage__usermainpage__dashboard' : 'main__dashboard'}>
                        <div className='main__logo'>Soundbird</div>
                        <div className='main__tapesignal'><strong>by Tape Signal</strong></div>

                        <motion.div animate={{ scale: mouseEnter1 ? scaleSize : 1.0 }}>
                            <button
                                className='dash__button'
                                key="1"
                                onClick={() => { changeToPage(1, '/user') }}
                                style={{
                                    boxShadow: pageNum === 1 ? '4px 4px 10px #ebeaea' : '0px 0px 0px #FFFFFF',
                                    color: pageNum === 1 ? '#000000' : '#8A8A8A'
                                }}
                                onMouseEnter={() => setMouseEnter1(true)}
                                onMouseLeave={() => setMouseEnter1(false)}
                            >
                                <div className='dash__buttoninfo'>
                                    {pageNum === 1
                                        ? <PluginsIcon className='dash__buttonicon' />
                                        : <PluginsIconGray className='dash__buttonicon' />}
                                    Notelists
                                </div>
                            </button>
                        </motion.div>

                        <motion.div animate={{ scale: mouseEnter2 ? scaleSize : 1.0 }}>
                            <button
                                className='dash__button'
                                key="2"
                                onClick={() => { changeToPage(2, '/invites') }}
                                style={{
                                    boxShadow: pageNum === 2 ? '4px 4px 10px #ebeaea' : '0px 0px 0px #FFFFFF',
                                    color: pageNum === 2 ? '#000000' : '#8A8A8A'
                                }}
                                onMouseEnter={() => setMouseEnter2(true)}
                                onMouseLeave={() => setMouseEnter2(false)}
                            >
                                <div className='dash__buttoninfo'>
                                    {pageNum === 2
                                        ? <ProjectsIcon className='dash__buttonicon' />
                                        : <ProjectsIconGray className='dash__buttonicon' />}
                                    Projects
                                </div>
                            </button>
                        </motion.div>

                        <motion.div
                            onMouseEnter={() => setMouseEnter3(true)}
                            onMouseLeave={() => setMouseEnter3(false)}
                            animate={{ scale: mouseEnter3 ? scaleSize : 1.0 }}
                        >
                            <button
                                className='dash__button'
                                key="3"
                                onClick={() => { changeToPage(3, '/playlists') }}
                                style={{
                                    boxShadow: pageNum === 3 ? '4px 4px 10px #ebeaea' : '0px 0px 0px #FFFFFF',
                                    color: pageNum === 3 ? '#000000' : '#8A8A8A'
                                }}
                            >
                                <div className='dash__buttoninfo'>
                                    {pageNum === 3
                                        ? <PlaylistsIcon className='dash__buttonicon' />
                                        : <PlaylistsIconGray className='dash__buttonicon' />}
                                    Playlists
                                </div>
                            </button>
                        </motion.div>

                        <motion.div
                            onMouseEnter={() => setMouseEnter4(true)}
                            onMouseLeave={() => setMouseEnter4(false)}
                            animate={{ scale: mouseEnter4 ? scaleSize : 1.0 }}
                        >
                            <button
                                className='dash__button'
                                key="4"
                                onClick={() => { changeToPage(4, '/chat') }}
                                style={{
                                    boxShadow: pageNum === 4 ? '4px 4px 10px #ebeaea' : '0px 0px 0px #FFFFFF',
                                    color: pageNum === 4 ? '#000000' : '#8A8A8A'
                                }}
                            >
                                <div className='dash__buttoninfo'>
                                    {pageNum === 4
                                        ? <PlaylistsIcon className='dash__buttonicon' />
                                        : <PlaylistsIconGray className='dash__buttonicon' />}
                                    Chat
                                </div>
                            </button>
                        </motion.div>

                        <div className={inLanding ? 'landingpage__usermainpage__dashgap' : 'dash__gap'}></div>

                        <div className="dash__storageinfo">
                            <div className="dash__storageh1">
                                Space used
                            </div>
                            <div className="dash__storageh2">
                                0 GB of 2 GB
                            </div>
                            <div className="dash__storagebar">
                                <div
                                    className="dash__progress"

                                />
                            </div>

                        </div>

                        <motion.div animate={{ scale: mouseEnter5 ? scaleSize : 1.0 }}>
                            {/* Could add more Account UI here */}
                        </motion.div>

                        <div className='dash__line'></div>

                        <motion.div animate={{ scale: mouseEnter5 ? scaleSize : 1.0 }}>
                            <div
                                className='dash__rest'
                                onClick={() => { changeToPage(5, '/account') }}
                                onMouseEnter={() => setMouseEnter5(true)}
                                onMouseLeave={() => setMouseEnter5(false)}
                            >
                                <div className='soundbird__logo'>
                                    <img className="birdsize" src={Logo} alt="Bird" />
                                </div>
                                <div className='dash__accountname'>
                                    hello@tapesignal.com
                                </div>
                                <div className='dash__dotcontainer'>
                                    <div className='dash__dot' />
                                    <div className='dash__dot' />
                                    <div className='dash__dot' />
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>

                <div className={inLanding ? 'landingpage__usermainpage__maintodo' : 'main__todo'}>
                    {page}
                </div>





            </div>
        </div>
    )
}

export default UserMainPageMock