import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SoundType from './TypeSound.png';
import PhotoType from './TypePhoto.png';
import VideoType from './TypeVideo.png';
import SkeletonSound from '../invites/SkeletonSound.jpg'; // fallback if no image

/**
 * A purely visual component that mimics OneFile, but with no real functionality.
 */
const OneFileMini = ({
    name = 'Untitled File',
    members = 0,
    type = 'Sound',
    lastUpdated,
    unreadCount = 0,
    isHoveringFileTypeSelector = false,
}) => {
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [timeFromChange, setTimeFromChange] = useState('');

    const [showShareButton, setShowShareButton] = useState(false);

    // Choose icon based on type
    let typeImage = SoundType;
    if (type.toLowerCase() === 'photos') {
        typeImage = PhotoType;
    } else if (type.toLowerCase() === 'videos') {
        typeImage = VideoType;
    }

    // Update relative time from lastUpdated
    useEffect(() => {
        const updateTimeFromChange = () => {
            if (lastUpdated) {
                setTimeFromChange(formatRelativeTime(lastUpdated));
            }
        };
        updateTimeFromChange();
        const intervalId = setInterval(updateTimeFromChange, 30000);
        return () => clearInterval(intervalId);
    }, [lastUpdated]);

    const formatRelativeTime = (date) => {
        if (!date) return '';
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);

        let value;
        let unit;

        if (diffInSeconds < 60) {
            value = diffInSeconds;
            unit = 'second';
        } else if (diffInSeconds < 3600) {
            value = Math.floor(diffInSeconds / 60);
            unit = 'minute';
        } else if (diffInSeconds < 86400) {
            value = Math.floor(diffInSeconds / 3600);
            unit = 'hour';
        } else if (diffInSeconds < 2592000) {
            value = Math.floor(diffInSeconds / 86400);
            unit = 'day';
        } else if (diffInSeconds < 31536000) {
            value = Math.floor(diffInSeconds / 2592000);
            unit = 'month';
        } else {
            value = Math.floor(diffInSeconds / 31536000);
            unit = 'year';
        }

        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
        return rtf.format(-value, unit);
    };

    return (
        <motion.div
            className='files__onefilecontainer'
            initial={{ opacity: 0 }}
            animate={{
                scale: showShareButton ? 1.015 : 1,
                zIndex: showShareButton ? 3 : 1
            }}

            transition={{ duration: 0.01 }}

            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}

            style={{ cursor: 'default' }}  // no pointer since there's no click functionality

            onMouseEnter={() => setShowShareButton(true)}
            onMouseLeave={() => {
                setShowShareButton(false);

            }}


        >
            {unreadCount > 0 && (

                <motion.div
                    transition={{
                        type: "spring",
                        stiffness: 100,
                        damping: 10,
                        duration: 1.5,
                        zIndex: 99
                    }}
                    initial={{ opacity: 0, scale: 0.0 }}
                    whileInView={{ opacity: 1, scale: 1.0 }}
                    viewport={{ once: true }}
                    className='files__isupdated'
                >

                    <div className="onefile__badge">
                        {unreadCount}
                    </div>



                </motion.div>
            )
            }

            <div className="files__onefileimg" style={{ position: 'relative' }}>
                {/* Skeleton shimmer while image is loading */}
                <AnimatePresence>
                    {isImageLoading && (
                        <motion.div
                            className="files__onefileimgskeleton"
                            initial={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{
                                position: 'absolute',
                                top: 20,
                                left: 20,
                                width: '83%',
                                height: '88%',
                                overflow: 'hidden',
                                borderRadius: 4,
                                backgroundColor: '#f2f2f2',
                            }}
                        >
                            <motion.div
                                initial={{ x: '-100%' }}
                                animate={{ x: '100%' }}
                                transition={{
                                    repeat: Infinity,
                                    duration: 1.5,
                                    ease: 'linear',
                                }}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    background:
                                        'linear-gradient(90deg, transparent, rgba(255,255,255,0.5), transparent)',
                                }}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>

                <img
                    className='files__onefileimgsize'
                    src={SkeletonSound}
                    alt="File"
                    style={{
                        opacity: isImageLoading ? 0 : 1,
                        transition: 'opacity 0.3s ease-in-out',
                        zIndex: -1,
                    }}
                    onLoad={() => setIsImageLoading(false)}
                    onError={() => setIsImageLoading(false)}
                    
                />
            </div>

            <div className="files__onefilename">{name}</div>

            {members > 0 && (
                <div className="files__onefilemembers">
                    {members} {members === 1 ? 'Member' : 'Members'}
                </div>
            )}

            {timeFromChange && (
                <div className="files__onefiletime">{timeFromChange}</div>
            )}
        </motion.div>
    );
};

export default OneFileMini;
