import React, { useRef, useState, useEffect } from 'react';
// If you're using Firestore for saving notes, import doc, updateDoc, etc.
// import { doc, updateDoc } from 'firebase/firestore';
// import { db } from "../../firebase";
import { motion } from 'framer-motion';


const NotesMini = ({ onClose }) => {
    const [noteContent, setNoteContent] = useState('');
    const [chats, setChats] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    const messagesEndRef = useRef(null);

    // OPTIONAL: if you need workspaceId from props or context
    // const workspaceId = 'abc123';

    useEffect(() => {
        // 1 second after mounting, set the note content
        const noteTimer = setTimeout(() => {
            setNoteContent(
                'Soundbird is a platform where you can share your tracks with your clients'
            );
        }, 1000);

        // 3 seconds after mounting (i.e. 2 seconds after noteTimer),
        // post the first chat by hello@soundbird.com
        const chatTimer1 = setTimeout(() => {
            setChats((prev) => [
                ...prev,
                {
                    id: 1,
                    senderId: 'hello@soundbird.com',
                    senderName: 'Soundbird Support',
                    message: 'Where all comments appear in the DAW on the project you are working on',
                },
            ]);
        }, 3000);

        // 5 seconds after mounting, post the second chat
        const chatTimer2 = setTimeout(() => {
            setChats((prev) => [
                ...prev,
                {
                    id: 2,
                    senderId: 'hello@soundbird.com',
                    senderName: 'Soundbird Support',
                    message: 'With loads of productivity tools to help you get your tracks finished',
                },
            ]);
        }, 5000);

        return () => {
            clearTimeout(noteTimer);
            clearTimeout(chatTimer1);
            clearTimeout(chatTimer2);
        };
    }, []);

    // OPTIONAL: auto-scroll to bottom of chat

    const handleClose = () => {
        // If you want to save the note to Firestore:
        // if (workspaceId) {
        //   const workspaceDocRef = doc(db, "workspaces", workspaceId);
        //   updateDoc(workspaceDocRef, {
        //       note: noteContent,
        //   }).catch((error) => {
        //       console.error("Error updating note: ", error);
        //   });
        // }
        onClose();
    };

    const handleSend = (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

     /*   setChats((prev) => [
            ...prev,
            {
                id: Date.now(),
                senderId: 'me@example.com',
                senderName: 'Me',
                message: newMessage,
            },
        ]);*/

        setNewMessage('');
    };

    return (
        <div className="landingpage__notes__container">
            <div className="notes__top">
                <div className="notes__title">Notes</div>
                <button className="notes__closeButton" onClick={handleClose}>
                    Close
                </button>
            </div>

            <div className="notes__content">
                {/* Upper Half: Note Editor */}
                <div className="landingpage__notes__editor">
                    <motion.textarea
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{
                            opacity: noteContent !== "" ? 1 : 0,

                            scale: noteContent !== "" ? 1 : 0,
                        }}
                        value={noteContent}
                        onChange={(e) => setNoteContent(e.target.value)}
                        style={{
                            width: '100%',
                            height: '100%',
                            resize: 'none',
                            borderRadius: '5px',
                            padding: '10px',
                            fontSize: '18px',
                            fontWeight: '300',
                            fontFamily: 'Avenir',
                            letterSpacing: '-0.1px',
                            backgroundColor: 'white',
                        }}
                        placeholder="Describe the contents of the workspace..."
                    />
                </div>

                <div className="notes__title">Chat</div>

                {/* Lower Half: Chat */}
                <div className="landingpage__notes__chat">
                    <div className="notes__chatMessages">
                        {chats.map((chat) => (
                            <motion.div
                                initial={{ opacity: 0, scale: 0 }}
                                whileInView={{
                                    opacity: 1,

                                    scale: 1,
                                }}
                                key={chat.id}
                                className={`notes__chatMessage ${chat.senderId === 'me@example.com' ? 'sent' : 'received'
                                    }`}
                            >
                                {chat.senderId !== 'me@example.com' && (
                                    <div className="notes__chatSenderName">{chat.senderName}</div>
                                )}
                                <div className="notes__chatText">{chat.message}</div>
                            </motion.div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <form className="notes__chatInput" onSubmit={handleSend}>
                        <input
                            type="text"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Type a message..."
                        />
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NotesMini;
