import React, { useEffect, useState } from 'react'
import { UserAuth } from '../../context/AuthContext'
import { upload } from '../../firebase'

import placeholder from './Soundbirdpic.png'

import './account.css'

const Account = () => {
    const { user, storageUsed, storageLimit } = UserAuth()

    const [photoURL, setPhotoURL] = useState("");
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user?.photoURL) {
            setPhotoURL();
        }
    }, [user])


    //if user has no profile pic input type file visible

    function handleChange(e) {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    }

    function handleClick() {
        upload(image, user, setLoading)
    }

    return (
        <div>
            <div className='account_title'>
                Account
            </div>
            <div className='account__header'>
                <div className="account__headercontent">

                    <div className="account__left">
                        <div className='account_pic'>
                            <img src={placeholder} className='account_pic2'></img>
                            {/*  <input type="file" className='account__filechooser' onChange={handleChange} />
                                 <button disabled={loading || !image} onClick={handleClick}>Upload</button> */}
                        </div>



                        <div className="account__info">
                            <div className="account__name">
                                {user.displayName}
                            </div>
                            <div className="account__email">
                                {user.email}
                            </div>
                        </div>
                    </div>

                    <div className="account__right">
                        <div className="account__projectinfo">
                            <div className="account__name">
                                15 Projects
                            </div>
                            <div className="account__email">
                                {((storageUsed / (1024 * 1024 * 1024)).toFixed(2))} GB Used
                            </div>
                        </div>

                    </div>
                </div>

                <div className="account__settings__container">
                    <div className="account__settings">
                        <div className="account__settings__title">
                            Settings
                        </div>
                        <div className="acccount__settings__input">
                            <div className="acccount__settings__input__left">
                                Email
                            </div>

                            <div className="acccount__settings__input__right">
                                <input />
                            </div>
                        </div>
                        <div className="acccount__settings__input">
                            <div className="acccount__settings__input__left">
                                Password
                            </div>

                            <div className="acccount__settings__input__right">
                                <input type='password' />
                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </div>
    )
}

export default Account