

import React, { useEffect } from "react";
import './maintitle.css'

import { db } from "../../firebase"
import {
  doc,
  collection,
  onSnapshot,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs
} from "firebase/firestore"


const MIN_TEXTAREA_HEIGHT = 10;

/*$(document).ready(function(){
    var lht = parseInt($('textarea').css('lineHeight'),10);
    var lines = $('textarea').attr('scrollHeight') / lht;
    console.log(lines);
   })*/

export default function MainTitle(props) {

  const textareaRef = React.useRef(null);
  const [value, setValue] = React.useState("");
  const onChange = (event) => setValue(event.target.value);
  const [focused, setFocused] = React.useState(false);
  const id = props.dbId;

  const onFocus = () => setFocused(true)
const onBlur = () => setFocused(false)

  useEffect(() =>{
    setValue(props.name)
  },[props.name])

  function updateData(collPath, data) {
    setDoc(doc(db, "projects", id), data, { merge: true})
}

useEffect(() =>{
  if(value){
    updateData("name",{value});
  }
}, [focused])

  React.useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";
    textareaRef.current.style.minHeight = 10;
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    )}px`;
  }, [value]);

  return (
    <div className="title__box" style={{
      boxShadow: '4px 4px 10px #ebeaea',
    }}>

      <textarea className="textarea__alt"
      rows={1}
      spellCheck={false}
  autoCorrect={false}
        onChange={onChange}
        ref={textareaRef}
        style={{
          minHeight: MIN_TEXTAREA_HEIGHT,
          resize: "none"
        }}
        value={value}
        placeholder="Project Title"
        onFocus={onFocus}
        onBlur={onBlur}
      />

    </div>
  );
}