import React, { useRef, useState } from 'react'


import { db } from '../../firebase';
import ColabMessage from '../colabeditor/ColabMessage';
import { UserAuth } from '../../context/AuthContext';
import SkeletonSound from '../invites/SkeletonSound.jpg';
import { useParams } from 'react-router-dom';

import { motion } from 'framer-motion';

import OffsetSVG from '../tests/OffsetSVG.svg'

const PluginShowcase = ({ allComments, currentTime }) => {
    const [timecode, setTimecode] = useState(0.0);
    const [juceTimecode, setJuceTimecode] = useState(0.0); // raw JUCE time
    const [timeOffset, setTimeOffset] = useState(0.0);
    const [comments, setComments] = useState([]);
    const [projects, setProjects] = useState([]);
    const [containerWidth, setContainerWidth] = useState(700);
    const containerRef = useRef(null);
    const [hoveringOffset, setHoveringOffset] = useState(false);
    const [hoveringTime, setHoveringTime] = useState(false);

    const modifiedComments = [
        {
            value: "And they load directly into your DAW.",
            position: 0,
            col: 0,
        },
        ...allComments.slice(2), // Remove the first two elements
    ];

    const formatTimecode = (secs) => {
        const totalSeconds = Math.floor(secs);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        const milliseconds = Math.floor((secs - totalSeconds) * 1000);

        // zero-pad
        const hStr = String(hours).padStart(2, '0');
        const mStr = String(minutes).padStart(2, '0');
        const sStr = String(seconds).padStart(2, '0');
        const msStr = String(milliseconds).padStart(3, '0');

        return `${hStr}:${mStr}:${sStr}:${msStr}`;
    };

    return (
        <div className="plugin__test">
            <div className="pluginjuce__comments" ref={containerRef}>

                <div className="pluginjuce__top"
                    style={{
                        width: '120%',
                        marginLeft: '-20px',
                        paddingLeft: '10px',
                        transform: 'translate(0px, -111px)',
                        paddingTop: '20px'
                    }}
                >
                    <div className="pluginjuce__topleft">
                        <img src={SkeletonSound} className="pluginjuce__cover" />
                        <div className="pluginjuce__title">San Miguel</div>
                    </div>

                    <div className="pluginjuce__topright" style={{ marginRight: '175px' }}>
                        <div className="pluginjuce__time">00:01:30:000</div>
                    </div>
                </div>

                {/* Playhead */}
                <div
                    className="playhead"
                    style={{
                        position: 'absolute',
                        top: '-20px',
                        left: `${(currentTime / 90) * containerWidth}px`,
                        height: '100%',
                        width: '2px',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        pointerEvents: 'none',
                        transition: 'width 0.1s linear',
                        zIndex: 100,
                    }}
                ></div>



                <div className='pluginjuce__commentscontainer'
                style={{transform: 'translate(0px, -10px)'}}
                >
                    {/* Render each comment */}
                    {modifiedComments.map((comment) => {


                        return (
                            <div key={comment.id} style={{ marginLeft: '-22px' }}>
                                <div
                                    className="colab__onecomment"

                                >
                                    <div
                                        className="colab__whitepos"
                                        style={{

                                            width: `${(comment.position / 90.0) *
                                                containerWidth + 1
                                                }px`,
                                        }}
                                    ></div>
                                    <ColabMessage
                                        message={comment.value}
                                        position={comment.position}
                                        color={comment.col}
                                        commentId={comment.id}

                                        deleteComment={() => { }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="bottomspace"></div>
            </div>

            {/* Bottom container */}
            <div className="juceplugin__bottomcontainer"
                style={{ zIndex: 999 }}
            >
                <motion.div
                    className="pluginjuce__time__current"
                    onMouseEnter={() => { setHoveringTime(true) }}
                    onMouseLeave={() => { setHoveringTime(false) }}
                    animate={{ scale: hoveringTime ? 1.02 : 1 }}
                >
                    {formatTimecode(currentTime)}
                </motion.div>

                <motion.div
                    onMouseEnter={() => { setHoveringOffset(true) }}
                    onMouseLeave={() => setHoveringOffset(false)}

                    className='pluginjuce__button'
                    animate={{
                        // scaleX: hoveringOffset ? 2.0 : 1.0,

                        width: hoveringOffset ? 150.0 : 64.0,
                    }}

                >
                    <div
                        onClick={() => {


                        }}
                    >
                        <motion.div
                            animate={{

                                x: hoveringOffset ? -38 : 0.0
                            }}
                        >
                            <img src={OffsetSVG} />
                            <motion.div
                                style={{ position: 'absolute', fontSize: '17px', transform: 'translate(35px, -36px)', width: '100px', overflow: 'hidden' }}

                                animate={{
                                    opacity: hoveringOffset ? 1.0 : 0.0,
                                }}

                                className="pluginjuce__offsettext">
                                Set Offset
                            </motion.div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div >
    )
}

export default PluginShowcase