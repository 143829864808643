import React, { useEffect, useState, useRef } from 'react';
import OneUserFiles from '../files/OneUserFiles';
import OneFile from '../files/OneFile';

import { db, storage, functions } from "../../firebase";
import {
    doc,
    collection,
    onSnapshot,
    setDoc,
    updateDoc,
    query,
    where,
    orderBy,
    serverTimestamp,
    addDoc,
    arrayUnion,
    increment,
    writeBatch,
    arrayRemove
} from "firebase/firestore";

import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SearchIcon from '../files/searchicon.svg';

import { motion, AnimatePresence } from 'framer-motion';
import AddMemberSVG from '../files/adduser.svg'

import SoundType from '../files/TypeSound.png';
import PhotoType from '../files/TypePhoto.png';
import VideoType from '../files/TypeVideo.png';
import Notes from '../files/Notes';
import NoteSVG from '../files/NoteSvg.svg';

import ListSettingSVG from '../files/ListSettingSVG.svg';
import GridSettingSVG from '../files/GridSettingSVG.svg';

import SkeletonSound from '../invites/SkeletonSound3.jpg';
import { httpsCallable } from 'firebase/functions';
import OneHorizontalFile from '../invites/OneHorizontalFile';
import OneFileMini from '../files/OneFileMini';
import NotesMini from './NotesMini';

const FilesMini = () => {
    const [selectedFileType, setSelectedFileType] = useState('All files');
    const [files, setFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [filesPendingRename, setFilesPendingRename] = useState([]);
    const [workspaceUsers, setWorkspaceUsers] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);
    const [workspaceName, setWorkspaceName] = useState('New Album');
    const [isHoveringFileTypeSelector, setIsHoveringFileTypeSelector] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const [newUserEmail, setNewUserEmail] = useState("");
    const [showAddUserPopup, setShowAddUserPopup] = useState(false);
    const addUserButtonRef = useRef(null);
    const popupRef = useRef(null);
    const [popupPosition, setPopupPosition] = useState({});

    const [searchTerm, setSearchTerm] = useState('');

    const [viewMode, setViewMode] = useState("grid"); // Default to grid
    const [showNotesPanel, setShowNotesPanel] = useState(false);

    // ========================
    // NEW: Mock data for files
    // ========================
    const mockFiles = [
        {
            id: 1,
            name: 'Guitar Riff',
            members: 3,
            type: 'Sound',
            lastUpdated: new Date(Date.now() - 1000 * 60 * 5),
            unreadCount: 2,
        },
        {
            id: 2,
            name: 'Background Vocals',
            members: 4,
            type: 'Sound',
            lastUpdated: new Date(Date.now() - 1000 * 60 * 60),
            unreadCount: 0,
        },
        {
            id: 3,
            name: 'Drum Loop',
            members: 2,
            type: 'Sound',
            lastUpdated: new Date(Date.now() - 1000 * 60 * 60 * 24),
            unreadCount: 1,
        },
        {
            id: 4,
            name: 'Piano Chords',
            members: 5,
            type: 'Sound',
            lastUpdated: new Date(Date.now() - 1000 * 60 * 13),
            unreadCount: 0,
        },
    ];

    const isButtonActive = (type) => {
        return selectedFileType === 'All files' || selectedFileType === type;
    };

    const handleAddUserClick = () => {
        if (addUserButtonRef.current) {
            const rect = addUserButtonRef.current.getBoundingClientRect();
            setPopupPosition({
                top: rect.bottom + window.scrollY + 5,
            });
            setShowAddUserPopup(true);
        }
    };

    const handleFileTypeSelect = (type) => {
        setSelectedFileType(type);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(e.target) &&
                addUserButtonRef.current &&
                !addUserButtonRef.current.contains(e.target)
            ) {
                setShowAddUserPopup(false);
            }
        };
        if (showAddUserPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showAddUserPopup]);

    // Filter the mock files based on selectedFileType and searchTerm
    const filteredFiles = mockFiles.filter((element) => {
        const matchesFileType =
            selectedFileType === 'All files' ||
            element.type.toLowerCase() === selectedFileType.toLowerCase();
        const matchesSearchTerm = element.name
            .normalize('NFC')
            .toLowerCase()
            .includes(searchTerm.normalize('NFC').toLowerCase());
        return matchesFileType && matchesSearchTerm;
    });

    // Toggle between list and grid view
    const handleToggleViewMode = () => {
        // setViewMode((prevMode) => (prevMode === 'list' ? 'grid' : 'list'));
    };

    // =====================================================
    // NEW: Trigger the opening of NotesMini after 3 seconds
    // =====================================================
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowNotesPanel(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div
            style={{
                width: 'calc(100vw - 543px)'
            }}
        >
            <div className="files__header">
                <div className="landingpage__projects__title">{workspaceName}</div>

                <div className="files__search">
                    <div className="files__searchcontainer">
                        <input
                            className="files__searchbar"
                            placeholder="Search for a file..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <img
                            src={SearchIcon}
                            className="files__searchicon"
                            alt="Search Icon"
                        />
                    </div>
                </div>

                <div className="landingpage__files__currentuser">Project Settings</div>
            </div>

            <div className="files__secondaryheader">
                <div
                    className="files__filetypeselector"
                    onMouseEnter={() => setIsHoveringFileTypeSelector(true)}
                    onMouseLeave={() => setIsHoveringFileTypeSelector(false)}
                >
                    {['All files', 'Sound', 'Photos', 'Videos'].map((type) => (
                        <div key={type}>
                            <div
                                className={`landingpage__files__filetypebutton ${selectedFileType === type ? 'selected' : ''
                                    }`}
                                onClick={() => handleFileTypeSelect(type)}
                            >
                                {type}
                            </div>
                        </div>
                    ))}

                    <div className="landingpage__files__filetypebuttonsmall">
                        <img
                            src={SoundType}
                            className="files__smallbutton"
                            onClick={() => handleFileTypeSelect('Sound')}
                            style={{
                                filter: isButtonActive('Sound') ? 'none' : 'grayscale(1)',
                            }}
                            alt="Sound"
                        />
                        <img
                            src={PhotoType}
                            className="landingpage__files__smallbutton"
                            onClick={() => handleFileTypeSelect('Photos')}
                            style={{
                                filter: isButtonActive('Photos') ? 'none' : 'grayscale(1)',
                            }}
                            alt="Photos"
                        />
                        <img
                            src={VideoType}
                            className="landingpage__files__smallbutton"
                            onClick={() => handleFileTypeSelect('Videos')}
                            style={{
                                filter: isButtonActive('Videos') ? 'none' : 'grayscale(1)',
                            }}
                            alt="Videos"
                        />
                    </div>
                </div>

                <div className="files__users">
                    {workspaceUsers.map((userEmail) => (
                        <OneUserFiles key={userEmail} userEmail={userEmail} />
                    ))}

                    {/* Button that toggles between list and grid mode */}
                    <button
                        className="files__adduserbutton"
                        onClick={handleToggleViewMode}
                    >
                        {viewMode === 'grid' ? (
                            <img
                                src={GridSettingSVG}
                                className="files__addmembersvg"
                                style={{ height: 17, transform: 'translate(0.5px, 2px)' }}
                                alt="Grid Mode"
                            />
                        ) : (
                            <img
                                src={ListSettingSVG}
                                className="files__addmembersvg"
                                style={{ height: 15, transform: 'translate(0.5px, 3px)' }}
                                alt="List Mode"
                            />
                        )}
                    </button>

                    {/* Button for adding new user */}
                    <button
                        ref={addUserButtonRef}
                        className="files__adduserbutton"
                        onClick={handleAddUserClick}
                    >
                        <img
                            src={AddMemberSVG}
                            className="files__addmembersvg"
                            style={{ height: 20 }}
                            alt="Add User"
                        />
                    </button>

                    <AnimatePresence>
                        {showAddUserPopup && (
                            <motion.div
                                onClick={(e) => e.stopPropagation()}
                                initial={{ opacity: 0, scale: 0.9, scaleY: 0.5, y: 0.0 }}
                                animate={{ opacity: 1, scale: 1, scaleY: 1, y: 1.0 }}
                                exit={{ opacity: 0, scale: 0.9, scaleY: 0.5 }}
                                transition={{ duration: 0.15 }}
                                className="files__addUserPopup"
                                ref={popupRef}
                                style={{
                                    top: popupPosition.top,
                                    left: popupPosition.left,
                                }}
                            >
                                <input
                                    type="email"
                                    placeholder="User's email address"
                                    value={newUserEmail}
                                    onChange={(e) => setNewUserEmail(e.target.value)}
                                    className="files__addUserInput"
                                />
                                <div className="files__addUserPopupButtons">
                                    <button onClick={() => setShowAddUserPopup(false)}>
                                        Cancel
                                    </button>
                                    <button disabled={!newUserEmail}>
                                        Add User
                                    </button>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>

            {/* If viewMode is grid, apply a "grid-layout" CSS class; if list, apply horizontal layout */}
            <div className={`files__files ${viewMode === "grid" ? "" : "horizontal-layout"}`}
                style={{
                    height: '634px',
                    overflow: 'hidden'
                }}

            >
                {filteredFiles.map(file => {
                    if (viewMode === "grid") {
                        return (
                            <OneFileMini
                                key={file.id}
                                name={file.name}
                                members={file.members}
                                type={file.type}
                                lastUpdated={file.lastUpdated}
                                unreadCount={file.unreadCount}
                                isHoveringFileTypeSelector={true}
                            />
                        );
                    } else {
                        return (
                            <OneFileMini
                                key={file.id}
                                name={file.name}
                                members={file.members}
                                type={file.type}
                                lastUpdated={file.lastUpdated}
                                unreadCount={file.unreadCount}
                                isHoveringFileTypeSelector={true}
                            />
                        );
                    }
                })}
            </div>

            <button
                className="landingpage__files__notesbutton"
                onClick={() => setShowNotesPanel(true)}
            >
                <img src={NoteSVG} className="files__notesbuttonsize" alt="Notes" />
            </button>

            {/* Notes Panel */}
            <AnimatePresence>
                {showNotesPanel && (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.9, scaleY: 0.1 }}
                        animate={{ opacity: 1, scale: 1, scaleY: 1.0 }}
                        exit={{ opacity: 0, scale: 0.9, y: 50 }}
                        transition={{ duration: 0.2 }}
                    >
                        <NotesMini onClose={() => setShowNotesPanel(false)} />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default FilesMini;
