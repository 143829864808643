import React, { useState, useEffect, useCallback } from 'react';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  addDoc,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { UserAuth } from '../../context/AuthContext';
import { useGlobalWaveSurfer } from '../../context/GlobalWaveSurferContext';
import SongItem from './SongItem';
import PlaylistItem from './PlaylistItem';
import './playlists.css';

const Playlists = () => {
  const { user } = UserAuth();

  // We store both "allSongs" and "songs" (the latter is displayed in the main panel).
  const [allSongs, setAllSongs] = useState([]);
  const [songs, setSongs] = useState([]);

  // Store playlists from Firestore
  const [playlists, setPlaylists] = useState([]);

  // For creating a new playlist
  const [addingAPlaylist, setAddingAPlaylist] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState('');

  // For drag and drop
  const [dragOverPlaylistId, setDragOverPlaylistId] = useState(null);

  // Which playlist is currently selected? null => All Songs
  const [selectedPlaylistId, setSelectedPlaylistId] = useState(null);

  // ---- AUDIO-PLAYBACK STATE ----
  const [playQueue, setPlayQueue] = useState([]); // array of songs (with url)
  const [currentIndex, setCurrentIndex] = useState(0);

  // Pull in your global WaveSurfer functions
  const {
    waveSurferRef,
    loadFile,
    play,
    pause,
    isPlaying,
    setWaveSurferContainer,
  } = useGlobalWaveSurfer();

  // 1) Fetch all songs from Firebase Storage, but also get their download URLs
  useEffect(() => {
    if (user && user.uid) {
      const listRef = ref(storage, `files/${user.uid}`);
      listAll(listRef)
        .then(async (res) => {
          const fetchedSongs = await Promise.all(
            res.items.map(async (itemRef) => {
              const url = await getDownloadURL(itemRef);
              return {
                id: itemRef.name,
                name: itemRef.name,
                url, // <-- we store the download URL here
              };
            })
          );
          setAllSongs(fetchedSongs);
          setSongs(fetchedSongs); // by default, show all songs
        })
        .catch((error) => {
          console.error('Error fetching songs:', error);
        });
    }
  }, [user]);

  // 2) Fetch playlists from Firestore
  useEffect(() => {
    if (user && user.uid) {
      const playlistsRef = collection(db, 'playlists');
      const userPlaylistsQuery = query(
        playlistsRef,
        where('user', '==', user.uid)
      );
      const unsubscribe = onSnapshot(userPlaylistsQuery, (snapshot) => {
        setPlaylists(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      });
      return () => unsubscribe();
    }
  }, [user]);

  // 3) Listen for waveSurfer's "finish" event to play the next track
  useEffect(() => {
    if (!waveSurferRef.current) return;
    const handleFinish = () => {
      // If we still have more tracks in the queue, move to the next
      setCurrentIndex((prevIndex) => {
        if (prevIndex < playQueue.length - 1) {
          return prevIndex + 1;
        }
        return prevIndex; // no next track
      });
    };

    // Attach the event
    waveSurferRef.current.on('finish', handleFinish);

    // Cleanup on unmount
    return () => {
      waveSurferRef.current && waveSurferRef.current.un('finish', handleFinish);
    };
  }, [waveSurferRef, playQueue]);

  // 4) Whenever currentIndex or playQueue changes, load that next song
  useEffect(() => {
    if (playQueue.length > 0 && currentIndex < playQueue.length) {
      const songToPlay = playQueue[currentIndex];
      loadFile(songToPlay.url);
      // Optionally auto-play
      setTimeout(() => {
        play();
      }, 300);
    }
  }, [currentIndex, playQueue, loadFile, play]);

  // 5) Function to start playing a new array of songs at the given index
  const playSongAtIndex = (index, songsArray) => {
    setPlayQueue(songsArray);
    setCurrentIndex(index);
  };

  // 6) On clicking a song in the UI, figure out which array it belongs to (All Songs or a playlist)
  const handleSongClick = (song, index) => {
    console.log("clicked")
    if (selectedPlaylistId === null) {
      // All songs mode
      playSongAtIndex(index, allSongs);
    } else {
      // Find the songs of the selected playlist
      const selectedPlaylist = playlists.find((p) => p.id === selectedPlaylistId);
      if (selectedPlaylist && selectedPlaylist.songs) {
        playSongAtIndex(index, selectedPlaylist.songs);
      }
    }
  };

  // 7) Create a new playlist doc in Firestore
  const addPlaylistToFirebase = async (e) => {
    e.preventDefault();
    if (!newPlaylistName) return;
    const newPlaylist = {
      name: newPlaylistName,
      user: user.uid,
      songs: [], // empty initially
    };
    await addDoc(collection(db, 'playlists'), newPlaylist);
    setAddingAPlaylist(false);
    setNewPlaylistName('');
  };

  // ---- DRAG & DROP HANDLERS ----
  const handleDragStart = (e, song) => {
    e.dataTransfer.setData('songId', song.id);
  };

  const handleDragOverPlaylist = (e, playlistId) => {
    e.preventDefault();
    setDragOverPlaylistId(playlistId);
  };

  const handleDragLeavePlaylist = () => {
    setDragOverPlaylistId(null);
  };

  const handleDropOnPlaylist = async (e, playlist) => {
    e.preventDefault();
    const songId = e.dataTransfer.getData('songId');
    // We want the full song object from allSongs
    const songObj = allSongs.find((s) => s.id === songId);
    if (!songObj) return;

    // If the playlist doesn't already contain this song, push it
    const alreadyInPlaylist = playlist.songs?.some((s) => s.id === songObj.id);
    if (!alreadyInPlaylist) {
      const playlistDocRef = doc(db, 'playlists', playlist.id);
      // Include the entire object (with url) so we can play it later
      await updateDoc(playlistDocRef, {
        songs: arrayUnion({
          id: songObj.id,
          name: songObj.name,
          url: songObj.url,
        }),
      });
    }
    setDragOverPlaylistId(null);
  };

  // 8) If user selects "All Songs" from the left sidebar
  const handlePlaylistClick = (playlistId) => {
    console.log("clicked")
    setSelectedPlaylistId(playlistId);
    if (playlistId === null) {
      setSongs(allSongs);
    } else {
      const selectedPlaylist = playlists.find((p) => p.id === playlistId);
      if (selectedPlaylist) {
        setSongs(selectedPlaylist.songs || []);
      }
    }
  };

  // 9) Show/hide new playlist form
  const addPlaylist = () => {
    setAddingAPlaylist(true);
  };

  return (
    <div className="playlists-container">
      {/* ---- MAIN SONG-LIST PANE ---- */}
      <div className="playlists-songs">
        {songs.map((song, index) => (
          <SongItem
            key={song.id}
            song={song}
            onDragStart={handleDragStart}
            // Add an onClick to load & play
            onClick={() => handleSongClick(song, index)}
          />
        ))}
      </div>

      {/* ---- SIDEBAR WITH PLAYLISTS ---- */}
      <div className="playlists-sidebar">
        <div className="playlists-sidebar-title">Your Playlists</div>
        <div className="playlists-sidebar-list">
          {/* "All Songs" (unfiltered) button */}
          <div
            className={`playlist-item ${selectedPlaylistId === null ? 'selected' : ''}`}
            onClick={() => handlePlaylistClick(null)}
          >
            <div className="playlist-item-name">All Songs</div>
          </div>

          {/* Render user playlists */}
          {playlists.map((playlist) => (
            <PlaylistItem
              key={playlist.id}
              playlist={playlist}
              isDragOver={dragOverPlaylistId === playlist.id}
              isSelected={selectedPlaylistId === playlist.id}
              onDragOver={handleDragOverPlaylist}
              onDragLeave={handleDragLeavePlaylist}
              onDrop={handleDropOnPlaylist}
              onClick={() => handlePlaylistClick(playlist.id)}
            />
          ))}

          {/* Add a new playlist button */}
          {!addingAPlaylist && (
            <div className="playlists-sidebar-add" onClick={addPlaylist}>
              Add A Playlist +
            </div>
          )}

          {/* Show new playlist form if user clicked "Add" */}
          {addingAPlaylist && (
            <form onSubmit={addPlaylistToFirebase} className="playlists-sidebar-form">
              <input
                className="playlist-input"
                type="text"
                value={newPlaylistName}
                onChange={(e) => setNewPlaylistName(e.target.value)}
                placeholder="Playlist Name"
                required
              />
              <div>
                <button type="submit">Add</button>
                <button type="button" onClick={() => setAddingAPlaylist(false)}>
                  Cancel
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Playlists;
