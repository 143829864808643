import React, { useEffect, useState } from "react";
import { ReactComponent as BetaXButton } from "../elementcontainer/Xbutton.svg";
import { ReactComponent as BetaDragger } from "../elementcontainer/Dragger.svg";

import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { db } from "../../firebase";
import {
  doc,
  collection,
  onSnapshot,
  setDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  getDoc,
} from "firebase/firestore";

import { UserAuth } from "../../context/AuthContext";
import MainTitle from "../maintitle/MainTitle";
import OneComment from "../elementcontainer/OneComment";

// ---- Import the NEW BetaElement (combined version) ----
import BetaElement from "../ToDoList/BetaElement";

function ColabToDo() {
  const [inputList, setInputList] = useState([]);
  const [tempText, setTempText] = useState("");
  const [loadedProject, setLoadedProject] = useState("");
  const [showComments, setShowComments] = useState(true);
  const [projectData, setProjectData] = useState({});
  const [loading, setLoading] = useState(true);
  const [localInputList, setLocalInputList] = useState([]);
  const [rowIndex, setRowIndex] = useState(0);

  const params = useParams();
  const navigate = useNavigate();

  const { user } = UserAuth();
  const uid = user.uid;

  const elementsCollectionRef = collection(db, "elements");

  // Fetch project metadata
  const getProjectName = async () => {
    const docRef = doc(db, "projects", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setProjectData(docSnap.data());
    }
  };

  // 1) Load project name on mount
  useEffect(() => {
    getProjectName();
    setLoadedProject(projectData.value);
  }, [projectData.value]);

  // 2) Listen for changes in "elements" collection
  useEffect(() => {
    if (uid) {
      const q = query(
        collection(db, "elements"),
        where("project", "==", params.id),
        orderBy("place", "asc")
      );

      onSnapshot(q, (snapshot) => {
        setInputList(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      });
    }
  }, [uid, params.id]);

  // 3) Keep localInputList synced
  useEffect(() => {
    setLocalInputList(inputList);
  }, [inputList]);

  // 4) Turn off skeleton once data arrives
  useEffect(() => {
    setLoading(false);
  }, [inputList]);

  // Keep rowIndex in sync with inputList length
  useEffect(() => {
    setRowIndex(inputList.length);
  }, [inputList]);

  // 5) Handlers for creating new items
  const onAddBtnClick = () => {
    setTempText("");
    const newTodo = {
      value: "",
      completed: false,
      user: uid,
      project: params.id,
      type: 1, // "To Do"
      place: rowIndex,
    };
    setRowIndex(rowIndex + 1);
    setDoc(doc(elementsCollectionRef), newTodo);
  };

  const onAddBtnClick2 = () => {
    setTempText("");
    const newTitle = {
      value: "",
      completed: false,
      user: uid,
      project: params.id,
      type: 2, // "Title"
      place: rowIndex,
    };
    setRowIndex(rowIndex + 1);
    setDoc(doc(elementsCollectionRef), newTitle);
  };

  const onAddBtnClick3 = () => {
    setTempText("");
    const newComment = {
      value: "",
      completed: false,
      user: uid,
      project: params.id,
      type: 3, // "Comment"
      place: rowIndex,
    };
    setRowIndex(rowIndex + 1);
    setDoc(doc(elementsCollectionRef), newComment);
  };

  // 6) Handle drag & drop reordering
  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(inputList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setInputList(items);

    // Update the 'place' in Firestore
    for (let i = 0; i < items.length; i++) {
      const elementRef = doc(db, "elements", items[i].id);
      await setDoc(elementRef, { ...items[i], place: i }, { merge: true });
    }
  };

  // 7) Delete item
  function deleteElement(id) {
    setRowIndex(rowIndex - 1);
    deleteDoc(doc(db, "elements", id));
  }

  // 8) (Optional) handle manual drag event
  const handleDrag = (e) => {
    // e.clientX, e.clientY, etc.
    console.log("X:", e.clientX, "Y:", e.clientY);
  };

  return (
    <div className="colabToDo__wrapper">


      {loading && (
        <div>
          <div className="colabToDo__skeleton" />
          <div className="colabToDo__skeleton" />
          <div className="colabToDo__skeleton" />
          <div className="colabToDo__skeleton" />
          <div className="colabToDo__skeleton" />
          <div className="colabToDo__skeleton" />
          <div className="colabToDo__skeleton" />
          <div className="colabToDo__skeleton" />
          <div className="colabToDo__skeleton" />
        </div>
      )}

      {/* DnD Container */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="colabToDoList">
          {(provided) => (
            <div
              className="colabToDo__droppable"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {localInputList.map((element, index) => (
                <Draggable
                  key={element.id}
                  draggableId={element.id}
                  index={index}
                >
                  {(providedDrag) => (
                    <div
                      ref={providedDrag.innerRef}
                      {...providedDrag.draggableProps}
                      {...providedDrag.dragHandleProps}
                      className="colabToDo__draggableItem"
                    >
                      <button
                        className="colabToDo__xbutton"
                        onClick={() => deleteElement(element.id)}
                      >
                        <BetaXButton />
                      </button>

                      <div className="dragger">
                        <BetaDragger />
                      </div>

                      {/* Use the new BetaElement component */}
                      <BetaElement
                        type={element.type}
                        name={element.value}
                        dbId={element.id}
                        done={element.completed}
                        user={uid}
                        projectId={params.id}
                      />
                    </div>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="colabToDo__buttonContainer"> 
        +
        <span className="colabToDo__hoverText" onClick={onAddBtnClick}>
          To-Do
        </span>
        {" / "}
        <span className="colabToDo__hoverText" onClick={onAddBtnClick2}>
          Title
        </span>
        {" / "}
        <span className="colabToDo__hoverText" onClick={onAddBtnClick3}>
          Comment
        </span>
      </div>

      <div draggable onDrag={handleDrag}></div>
    </div>
  );
}

export default ColabToDo;
