import React, { useState } from 'react'

import { motion } from 'framer-motion';

const NewProjectButton = ({ onShowForm }) => {
    const [mouseEnter, setMouseEnter] = useState(false);

    return (
        <motion.div
            className='files__onefilecontainer'
            onClick={onShowForm}
            onMouseEnter={() => setMouseEnter(true)}
            onMouseLeave={() => {
                setMouseEnter(false)
            }}
            animate={{
                scale: mouseEnter ? 1.015 : 1,
            }}
            transition={{ duration: 0.01 }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
        >
            <div className='invites__newprojectcontainer'>
                <button className='projects__plusbutton'>
                    +
                </button>

                <div className="invites__newprojecttext">
                    New Folder
                </div>
            </div>
        </motion.div>
    )
}

export default NewProjectButton