import { createContext, useContext, useEffect, useState } from "react";


export const LoadedSongContext = createContext()

export const SongContextProvider = ({children}) => {
    const [loadedSong, setLoadedSong] = useState("bull");
    const[pageOn, setPageOn] = useState(1);

    const setNewLoadedSong = (url) => {
        console.log(url);
        setLoadedSong(url);
    };

    return(
        <LoadedSongContext.Provider value={{setNewLoadedSong, loadedSong}}>
            {children}
        </LoadedSongContext.Provider>
    )
}

export const LoadedSong = () => {
    return useContext(LoadedSongContext)
}
