import React, { useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import {
    collection,
    doc,
    setDoc,
} from "firebase/firestore";

// You can rename/check-sign icon if you like.
// For demonstration, let's assume you have a new check icon named BetaCheckSign.svg
// (or you can keep the old one)
import BetaCheckSign from "./CheckSVG.svg";

import './betaelement.css'

const MIN_TEXTAREA_HEIGHT = 10;

/**
 * Combined component that handles:
 *  - type = 1 -> "To Do" style (with check completion)
 *  - type = 2 -> "Title" style
 *  - type = 3 -> "Comment" style
 *
 * It has brand new class names, so it does not conflict with your existing CSS.
 */
export default function BetaElement(props) {
    const {
        type = 1,          // 1=todo, 2=title, 3=comment
        name = "",         // initial text
        dbId = "",         // document ID in Firestore
        done = false,      // completed status for 'todo' type
        user = "",         // user ID
        projectId = ""     // project ID
    } = props;

    // local state for text
    const [value, setValue] = useState(name);
    // local state for completed toggle (applies if type=1)
    const [completed, setCompleted] = useState(done);
    // track old text to batch updates
    const [oldValue, setOldValue] = useState(name);
    // help ensure we only push updates when something changes
    const [hasPendingChange, setHasPendingChange] = useState(false);

    // for focusing
    const [focused, setFocused] = useState(false);
    const textareaRef = useRef(null);

    // Firestore reference
    const elementsCollectionRef = collection(db, "elements");

    /**
     * Utility: merges data into the specified doc in Firestore.
     */
    function updateData(docId, data) {
        if (!docId) return;
        setDoc(doc(db, "elements", docId), data, { merge: true });
    }

    /**
     * 1) Keep local state in sync with props when mount.
     */
    useEffect(() => {
        setValue(name);
        setCompleted(done);
    }, [name, done]);

    /**
     * 2) Adjust the textarea height automatically.
     */
    useEffect(() => {
        if (textareaRef.current) {
            // Reset height
            textareaRef.current.style.height = "inherit";
            // Then set it
            textareaRef.current.style.height = `${Math.max(
                textareaRef.current.scrollHeight,
                MIN_TEXTAREA_HEIGHT
            )}px`;
        }
    }, [value]);

    /**
     * 3) If user leaves the input (blur event), 
     *    we might do a final update. 
     */
    useEffect(() => {
        if (!focused) {
            // Optionally do an immediate update on blur:
            updateData(dbId, { value });
        }
        // eslint-disable-next-line
    }, [focused]);

    /**
     * 4) We batch updates every 500ms if changes are pending or oldValue differs.
     */
    useEffect(() => {
        const interval = setInterval(() => {
            if (hasPendingChange || oldValue !== value) {
                setOldValue(value);
                updateData(dbId, { value });
                setHasPendingChange(false);
            }
        }, 500);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [hasPendingChange, oldValue, value]);

    /**
     * 5) On page unload, optionally do a last update
     */
    useEffect(() => {
        const handleTabClosing = () => {
            if (value) {
                updateData(dbId, { value });
            }
        };
        window.addEventListener("unload", handleTabClosing);
        return () => {
            window.removeEventListener("unload", handleTabClosing);
        };
        // eslint-disable-next-line
    }, []);

    /**
     * Handler for changes in the textarea
     */
    const handleChange = (e) => {
        setValue(e.target.value);
        setHasPendingChange(true);
    };

    /**
     * Handler for toggling "completed" (only relevant if type=1)
     */
    const toggleCompleted = () => {
        const newValue = !completed;
        setCompleted(newValue);
        updateData(dbId, { completed: newValue });
    };

    // Some styling or placeholders can differ by type
    let placeholderText = "";
    let containerClass = "betaElement__container";
    let textAreaClass = "betaElement__textarea";

    switch (type) {
        case 1:
            // To do
            placeholderText = "To Do";
            containerClass = "betaElement__containerTodo";
            textAreaClass = completed
                ? "betaElement__textareaTodo betaElement__completed"
                : "betaElement__textareaTodo";
            break;
        case 2:
            // Title
            placeholderText = "Title";
            containerClass = "betaElement__containerTitle";
            textAreaClass = "betaElement__textareaTitle";
            break;
        case 3:
            // Comment
            placeholderText = "Comment";
            containerClass = "betaElement__containerComment";
            textAreaClass = "betaElement__textareaComment";
            break;
        default:
            placeholderText = "To Do";
            containerClass = "betaElement__containerTodo";
            textAreaClass = "betaElement__textareaTodo";
            break;
    }





  const handleTextareaKeyDown = (e) => {
    if (e.code === "Space") {
      e.stopPropagation(); 
    }
  }


    return (
        <div className={containerClass}>
            {type === 1 && (
                <div style={{ display: 'flex' }}>

                    <div
                        className="betaElement__checkWrapper"
                        onClick={toggleCompleted}
                    >
                        {completed && (

                            <img
                                className="betaElement__checkIconCompleted"
                                src={BetaCheckSign}
                                alt="check"
                                style={completed ? { opacity: 1 } : { opacity: 0 }}
                            />
                        )}

                        {!completed && (

                            <div
                                className="betaElement__checkIcon"
                                src={BetaCheckSign}
                                alt="check"

                            />
                        )}
                    </div>

                    <textarea
                        ref={textareaRef}
                        className={textAreaClass}
                        rows={1}
                        spellCheck={false}
                        autoComplete="off"
                        autoCorrect="off"
                        placeholder={placeholderText}
                        value={value}
                        onChange={handleChange}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onKeyDown={handleTextareaKeyDown}
                        style={completed ? { textDecoration: ' line-through', color:'gray' } : { textDecoration: 'none' }
                        
                    }
                    />
                </div>
            )}

            {type !== 1 && (
                <div style={{ paddingLeft: '21px' }}>
                    <textarea
                        ref={textareaRef}
                        className={textAreaClass}
                        rows={1}
                        spellCheck={false}
                        autoComplete="off"
                        autoCorrect="off"
                        placeholder={placeholderText}
                        value={value}
                        onChange={handleChange}
                        onFocus={() => setFocused(true)}
                        onKeyDown={handleTextareaKeyDown}
                        onBlur={() => setFocused(false)}
                    />
                </div>
            )}




        </div>
    );
}
