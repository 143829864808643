import React from 'react'
import { Navigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'



const ProtectedPlugin = ({children}) => {
    const {user} = UserAuth()

    //create new project


    if(!user){
        return <Navigate to ='/' />
    }

  return children
}

export default ProtectedPlugin