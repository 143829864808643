import React from 'react'
import About from './Components/about/About'
import TextElement from './Components/textelement/TextElement'
import { useState } from "react";
import ElementContainer from './Components/elementcontainer/ElementContainer';
import MainTitle from './Components/maintitle/MainTitle'
import Test from './Components/test'
import SignIn from './Components/signin/SignIn';
import { Route, Routes } from 'react-router-dom'
import UserPage from './Components/userpage/UserPage';
import SignUp from './Components/signup/SignUp';
import { AuthContextProvider } from './context/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute';
import UserMainPage from './Components/usermainpage/UserMainPage';
import ProtectedPlugin from './Components/ProtectedPlugin';
import Editor from './Components/editor/Editor';
import Projects from './Components/projects/Projects';
import Account from './Components/account/Account';
import Plugform from './Components/plugform/Plugform';
import PluginEditor from './Components/plugineditor/PluginEditor';
import Invites from './Components/invites/Invites';
import ColabRoute from './Components/colabeditor/ColabRoute';
import Playlists from './Components/playlists/Playlists';
import { SongContextProvider } from './context/LoadedSongContext';
import Credits from './Components/credits/Credits';
import PlaylistsTest from './Components/tests/PlaylistsTest';
import PlaylistsMain from './Components/newplaylists/PlaylistsMain';
import Chat from './Components/chat/Chat';
import Files from './Components/files/Files';
import InvitePage from './Components/files/InvitePage';
import PluginJuceTest from './Components/tests/PluginJuceTest';
import ProjectSelector from './Components/tests/ProjectSelctor';
import ColabLyrics from './Components/colabeditor/ColabLyrics';
import { GlobalWaveSurferProvider } from './context/GlobalWaveSurferContext';
import UserMainPageMock from './Components/usermainpage/UserMainPageMock';
import ProjectInvitePage from './Components/files/ProjectInvitePage';
import LandingPage from './Components/landing/LandingPage';
import PluginProjectSelector from './Components/tests/PluginProjectSelector';
import DawShowcase from './Components/landing/DawShowcase';
import StickyTest from './Components/landing/StickyTest';
import { DataProvider } from './context/DataContext';



const App = () => {

  {/*const handleRightClick = (event) => {
    event.preventDefault();
    // Your custom logic for right-click behavior
    console.log("Custom right-click behavior triggered!");
  };*/}



  return (
    <div>
      {/* <div onContextMenu={handleRightClick} >*/}
      <AuthContextProvider>
        <SongContextProvider>
          <GlobalWaveSurferProvider>
            <DataProvider>

              <Routes>
                <Route path='/' element={<LandingPage />} />

                <Route path='/signin' element={<SignIn />} />
                <Route path='/signup' element={<SignUp />} />

                <Route path='/workingon' element={<ColabLyrics />} />


                <Route path='/pltest' element={

                  <ProtectedRoute>
                    <UserMainPage page={<PlaylistsTest />} nameofpage="Pltest" />
                  </ProtectedRoute>} />


                <Route path='/plugin/:id/:name/:duration/:reload' element={
                  <ProtectedRoute>
                    <PluginJuceTest />
                  </ProtectedRoute>} />


                <Route path='/plugintest' element={
                  <ProtectedRoute>
                    <PluginProjectSelector />
                  </ProtectedRoute>} />

                <Route path='/user' element={

                  <ProtectedRoute>
                    <UserMainPage page={<Projects />} nameofpage="Projects" />
                  </ProtectedRoute>} />

                <Route path='/invites' element={

                  <ProtectedRoute>
                    <UserMainPage page={<Invites />} nameofpage="Invites" />
                  </ProtectedRoute>} />




                <Route path='/account' element={

                  <ProtectedRoute>
                    <UserMainPage page={<Account />} nameofpage="Account" />
                  </ProtectedRoute>} />


                <Route path='/playlists' element={

                  <ProtectedRoute>
                    <UserMainPage page={<Playlists />} />
                  </ProtectedRoute>} />

                <Route path='/files/:id' element={

                  <ProtectedRoute>
                    <UserMainPage page={<Files />} />
                  </ProtectedRoute>} />

                <Route path="/invite/:token" element={<UserMainPageMock page={<InvitePage />} />} />
                <Route path="/projectinvite/:token" element={<UserMainPageMock page={<ProjectInvitePage />} />} />

                <Route path="/testdaw" element={<StickyTest />} />

                <Route path="/landing" element={<LandingPage />} />

                <Route path='/playlists/:id/:name' element={
                  <ProtectedRoute>
                    <UserMainPage page={<Playlists selected={true} />} />
                  </ProtectedRoute>} />

                <Route path='/credits' element={
                  <ProtectedRoute>
                    <UserMainPage page={<Credits />} />
                  </ProtectedRoute>} />


                <Route path='/chat' element={
                  <ProtectedRoute>
                    <UserMainPage page={<Chat />} />
                  </ProtectedRoute>} />


                <Route path='/userplugin' element={
                  <ProtectedPlugin>
                    <Plugform />
                  </ProtectedPlugin>} />

                <Route path='/editor/:id/:name' element={
                  <ProtectedRoute>
                    <Editor />
                  </ProtectedRoute>} />


                <Route path='/plugineditor/:id/:name/:reload' element={
                  <ProtectedRoute>
                    <PluginEditor />
                  </ProtectedRoute>} />


                <Route path='/project/:id/:name' element={
                  <ProtectedPlugin>
                    <ColabRoute />
                  </ProtectedPlugin>} />

                <Route path='/sb/:id/:name' element={
                  <ProtectedRoute>
                    <PluginEditor />
                  </ProtectedRoute>} />


              </Routes>
            </DataProvider>
          </GlobalWaveSurferProvider>
        </SongContextProvider>
      </AuthContextProvider>
    </div >
  )
}

export default App