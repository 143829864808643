import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions'; // <-- import from Firebase
import { UserAuth } from '../../context/AuthContext';


import './invitepage.css'

const InvitePage = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { user } = UserAuth();
    const [loading, setLoading] = useState(true);
    const [invalid, setInvalid] = useState(false);

    useEffect(() => {
        // We used to fetch the invite doc here. Now we can do
        // a simple check if token is valid or not, or show the user a loading screen.
        // We'll do the full check in the "acceptInvite" function.
        if (!token) {
            setInvalid(true);
        }
        setLoading(false);
    }, [token]);

    const acceptInvite = async () => {
        if (!user) {
            navigate('/signup');
            return;
        }
        try {
            setLoading(true);
            const functions = getFunctions(); // get default Functions instance
            const acceptFileInvite = httpsCallable(functions, 'acceptFileInvite');

            // Call the Cloud Function with {token}
            const result = await acceptFileInvite({ token });
            const data = result.data;

            console.log('Cloud Function result:', data);
            // data.fileId & data.fileName are returned by the function
            navigate(`/project/${data.fileId}/${data.fileName}`);
        } catch (error) {
            console.error('Error accepting invite:', error);
            alert(error.message || 'Something went wrong.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (invalid) {
        return <div>Invalid invite link.</div>;
    }

    return (
        <div className="invite__container">
            <div className="invite__cardcontainer">
                <div className='invite__textmain'>You've been invited to join a project.</div>
                {!user ? (
                    <>
                        <div className='invite__textsub'>Please log in or sign up to accept the invitation.</div>
                        <div className='invite__accountbuttoncontainer'>
                            <button className='invite__button' onClick={() => navigate(`/signin?redirect=/invite/${token}`)}>
                                Log In
                            </button>
                            <button className='invite__button' onClick={() => navigate(`/signup?redirect=/invite/${token}`)}>
                                Sign Up
                            </button>

                        </div>
                    </>
                ) : (
                    <button className='invite__button' onClick={acceptInvite}>Accept</button>
                )}
            </div>
        </div>
    );
};

export default InvitePage;
