import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import WaveSurfer from 'wavesurfer.js';

import ColabSendSVG from '../colabeditor/Colabsend.svg';
import ColabMessage from '../colabeditor/ColabMessage';

import Play from '../colabeditor/playsound.svg';
import Pause from '../colabeditor/PauseSound.svg';

import sanmig from './sanmiguel.mp3';
import LufsMeter from '../colabeditor/LufsMeter';

const ColabEditorMini = ({ allComments, setAllComments, onTimeUpdate }) => {
    const [currentCommentValue, setCurrentCommentValue] = useState("");
    const [playerReady, setPlayerReady] = useState(false);
    const [fileUrl, setFileUrl] = useState(sanmig);
    const [time, setTime] = useState("");
    const [playState, setPlayState] = useState(false);
    const [playhead, setPlayhead] = useState(0);
    const [waveWidth, setWaveWidth] = useState(0);
    const [duration, setDuration] = useState(0);

    // New: ensure "drums" comment is only added once
    const [drumsCommentAdded, setDrumsCommentAdded] = useState(false);

    // Intersection Observer state
    const [isEditorInView, setIsEditorInView] = useState(false);

    // NEW: The user must click the screen once to unlock audio
    const [isUserGestureDone, setIsUserGestureDone] = useState(false);

    const ws = useRef();
    const waveWidthRef = useRef(null);
    const commentsEndRef = useRef(null);
    const commentsContainerRef = useRef(null);
    const editorContainerRef = useRef(null);

    //
    // WaveSurfer init
    //
    useEffect(() => {
        // 1) Create WaveSurfer instance
        ws.current = WaveSurfer.create({
            container: "#waveform",
            waveColor: "#E3E3EC",
            progressColor: "#bfbfbf",
            responsive: true,
            height: 70,
            barGap: 1,
            barWidth: 5,
            barRadius: 5,
        });

        // 2) Add event listeners
        const handleReady = () => setPlayerReady(true);
        const handlePlay = () => setPlayState(true);
        const handlePause = () => setPlayState(false);

        ws.current.on('ready', handleReady);
        ws.current.on('play', handlePlay);
        ws.current.on('pause', handlePause);

        // 3) Load your file
        ws.current.load(fileUrl);

        return () => {
            ws.current.destroy();
        };
    }, [fileUrl]);

    //
    // Intersection Observer
    //
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsEditorInView(entry.isIntersecting);
            },
            { threshold: 0.2 }
        );

        if (editorContainerRef.current) {
            observer.observe(editorContainerRef.current);
        }
        return () => {
            if (editorContainerRef.current) {
                observer.unobserve(editorContainerRef.current);
            }
        };
    }, []);

    //
    // Attempt auto-play only if:
    // 1) WaveSurfer is ready
    // 2) The component is in view
    // 3) The user has clicked the "Start" button (isUserGestureDone)
    //
    useEffect(() => {
        if (!playerReady) return;

        if (isEditorInView && isUserGestureDone && !ws.current.isPlaying()) {
            ws.current.play()
        } else if (!isEditorInView && ws.current.isPlaying()) {
            // ws.current.pause();
        }
    }, [playerReady, isEditorInView, isUserGestureDone]);

    //
    // Keep track of time / waveWidth
    //
    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = ws.current?.getCurrentTime() || 0;
            setPlayhead(currentTime);
            setTime(currentTime);
            setWaveWidth(waveWidthRef.current?.offsetWidth || 0);

            if (onTimeUpdate) {
                onTimeUpdate(currentTime);
            }

            // Once we cross 3s for the first time, add a "drums" comment
            if (!drumsCommentAdded && currentTime >= 3) {
                setAllComments((prev) => [
                    ...prev,
                    {
                        value: "Maybe some drums here?",
                        position: 3,
                        col: 1,
                    },
                ]);
                setDrumsCommentAdded(true);
            }
        }, 50);
        return () => clearInterval(interval);
    }, [drumsCommentAdded, setAllComments, onTimeUpdate]);

    //
    // Format time
    //
    const formatTimecode = (time) => {
        const totalSeconds = parseInt(time, 10);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
        const seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);
        const milliseconds = Math.floor((time % 1) * 1000);

        const pad = (num, size = 2) => num.toString().padStart(size, '0');

        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}:${pad(milliseconds, 3)}`;
    };

    //
    // Handle resizing
    //
    const resizedw = () => {
        setWaveWidth(waveWidthRef.current.offsetWidth);
    };

    useEffect(() => {
        let doit;
        window.onresize = function () {
            clearTimeout(doit);
            doit = setTimeout(function () {
                resizedw();
            }, 100);
        };
    }, []);

    useEffect(() => {
        setDuration(ws.current?.getDuration() || 0);
    }, [playerReady]);

    //
    // Add new comment
    //
    const onChange = (event) => {
        setCurrentCommentValue(event.target.value);
    };

    const addNewComment = () => {
        if (currentCommentValue.trim() !== "") {
            const comment = {
                value: currentCommentValue.trim(),
                position: ws.current.getCurrentTime(),
                col: 1,
            };
            setCurrentCommentValue("");
            setAllComments([...allComments, comment]);
        }
    };

    //
    // Keyboard shortcuts
    //
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addNewComment();
        }
        if (e.key === ' ') { // space
            e.preventDefault();
            // Only allow if user has unlocked audio
            if (!isUserGestureDone) return;

            setPlayState(!playState);
            if (playState) {
                ws.current.pause();
            } else {
                ws.current.play();
            }
        }
    };

    //
    // Scroll to bottom of comments
    //
    const scrollToBottom = () => {
        if (commentsContainerRef.current && commentsEndRef.current) {
            commentsContainerRef.current.scrollTop = commentsEndRef.current.offsetTop;
        }
    };
    useEffect(scrollToBottom, [allComments]);

    //
    // Jump to a comment time
    //
    const goToComment = (position) => {
        if (!ws.current) return;
        const duration = ws.current.getDuration();
        const progress = position / duration;
        ws.current.seekTo(progress);
        setTime(position);
    };

    //
    // Manual play/pause
    //
    const handleClick = () => {
        // Only allow play if user unlocked audio
        if (!isUserGestureDone) return;

        setPlayState(!playState);
        if (playState) {
            ws.current.pause();
        } else {
            ws.current.play();
        }
    };

    //
    // The overlay that demands a user gesture
    //
    const handleStartDemo = () => {
        setIsUserGestureDone(true);
        // Optionally, you could also do:
        // ws.current.backend.ac.resume().catch(err => console.error(err));
        // But usually just setting the state is enough,
        // because the next auto-play effect will .play()
    };

    //
    // Render
    //
    return (
        <div ref={editorContainerRef}>

            {!isUserGestureDone && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(255,255,255,0.7)',
                        backdropFilter: 'blur(8px)',
                        zIndex: 9999,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <h2 style={{
                        letterSpacing: '-1.7px',
                        marginBottom: '-30px',
                    }}>Welcome to Soundbird</h2>
                    <p style={{ marginBottom: '1rem' }}>
                        Click below to start an interactive demo
                    </p>
                    <button
                        style={{
                            padding: '14px 20px',
                            cursor: 'pointer',
                            borderRadius: '5px',
                            fontSize: '16px',
                            backgroundColor: 'black',
                            color: 'white',
                        }}
                        onClick={handleStartDemo}
                    >
                        Start Demo
                    </button>
                </div>
            )}

            <div className="colab__title">San Miguel</div>

            <div className="landingpage__colabbehindfileblur">
                <div className="colabmini__file">
                    <div className="colab__fileshadow__mini"></div>
                    <div className="colab__wave">
                        <div
                            id="waveform"
                            className="colab__wave"
                            ref={waveWidthRef}
                            style={{ transform: 'translate(0px, -10px)' }}
                        />
                    </div>
                </div>

                <div
                    className="colab__transport"
                    style={{
                        marginTop: '45px',
                        marginLeft: `${(((playhead * waveWidth) * 1) / duration) + 38 + ((waveWidth - 872) * 0.01)}px`
                    }}
                />
            </div>

            <div id="commentsContainer" className="colab__commentscontainer" ref={commentsContainerRef}>
                {allComments.map((element, idx) => (
                    <div key={idx}>
                        <div className="colab__onecomment">
                            <div
                                className="colab__whitepos"
                                style={{
                                    width: `${(((element.position * waveWidth) / duration) - 6 + waveWidth * 0.022)}px`,
                                }}
                            />
                            <div onClick={() => goToComment(element.position)}>
                                <ColabMessage
                                    commentId={"123"}
                                    message={element.value}
                                    position={element.position}
                                    color={element.col}
                                    highlighted={"000000"}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                <div className="bottomspace" />
                <div ref={commentsEndRef} />
            </div>

            <div className="landingpage__colab__bottomcontainer">
                <div className="colab__plusbutton" onClick={handleClick}>
                    <div className="colab__playpos">
                        {playState ? (
                            <img className="colab__play" src={Pause} alt="Pause" />
                        ) : (
                            <img
                                className="colab__play"
                                src={Play}
                                alt="Play"
                                style={{ marginLeft: 3, marginTop: 1 }}
                            />
                        )}
                    </div>
                </div>

                <div className="colab__bottomleftgap"></div>

                <div className="colab__textarea">
                    <div className="colab__time">{formatTimecode(time)}</div>
                    <textarea
                        spellCheck={false}
                        autoCorrect="false"
                        className="colab__text"
                        value={currentCommentValue}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                        placeholder="You can try adding a comment here..."
                    />
                    <button className="colab__sendbutton" onClick={addNewComment}>
                        <img src={ColabSendSVG} alt="Send" />
                    </button>
                </div>
            </div>

            <div
                className="colab__lufs"
                style={{ position: 'fixed', zIndex: '3', transform: 'translate(0px, 0px)' }}
            >
                {playerReady && (
                    <LufsMeter wavesurfer={ws.current ? { backend: ws.current.backend } : null} />
                )}
            </div>
        </div>
    );
};

export default ColabEditorMini;
