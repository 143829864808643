import React from 'react'
import UserMainPage from '../usermainpage/UserMainPage'
import ColabEditor from './ColabEditor'

const ColabRoute = () => {
  return (
    <div>
        <UserMainPage page={<ColabEditor />}/>
    </div>
  )
}

export default ColabRoute