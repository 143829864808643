import React from 'react'
import { useState } from 'react';
import CheckSign from '../textelement/bi_check.svg';
import { motion } from 'framer-motion'

const OneComment = (props) => {
  const message = props.message;
  const [st, setst] = useState(false);
  const pos = props.position;
  const [mouseEnter, setMouseEnter] = useState(false);

  //const millisec = Math.trunc((pos - pos.toFixed(0)) * 100);
  const sec = Math.trunc(pos) % 60;
  const millisec = ((pos - sec) * 100).toFixed(0)
  const hours = Math.trunc(pos / 3600);
  let min = Math.trunc((pos - (hours * 3600)) / 60);

  min = min % 60;

  let secString = String(sec);
  let milString = String(millisec);
  let minString = String(min);
  let hourString = String(hours);

  if (sec < 10) {
    secString = "0" + secString;
  }

  if (millisec < 10) {
    milString = "0" + milString;
  }

  if (min < 10) {
    minString = "0" + minString;
  }

  if (hours < 10) {
    hourString = "0" + hourString;
  }

  function change() {
    setst(!st);
  }

  const combinedString = minString + ":" + secString + ":" + milString;


  const stylings = {
    textDecoration: "line-through",
    color: "#adadad",
  };

  const otherstylings = {

  };

  return (
    <div
    onMouseEnter={e => {
      setMouseEnter(true);
    }}
    onMouseLeave={e => {
      setMouseEnter(false);
    }}
    >
    <motion.div className='element__comment'
      onClick={change}
      style={st ? stylings : otherstylings}
      initial={{ opacity: 0, scale: 0.5}}
        whileInView={{ opacity: 1, scale: 1  }}
        viewport={{ once: true }}
      >

      {message}
    </motion.div>

   


     
    </div>
  )
}

export default OneComment