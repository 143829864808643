import React, { useEffect, useState, useRef } from 'react';
import UserPage from '../userpage/UserPage'
import SignIn from '../signin/SignIn'
import './usermainpage.css'
//import Logo from './Helu2.png'

import Logo from './SoundbirdlogoSVG.svg'

import { ReactComponent as PluginsIcon } from './PluginsIconTiny.svg';
import { ReactComponent as PluginsIconGray } from './PluginsIconGray.svg'
import { ReactComponent as ProjectsIcon } from './ProjectsIconTiny.svg';
import { ReactComponent as ProjectsIconGray } from './ProjectsIconGray.svg'
import { ReactComponent as PlaylistsIcon } from './PlaylitsIconTiny.svg';
import { ReactComponent as PlaylistsIconGray } from './PlaylitsIconGray.svg'
import { ReactComponent as ChatIcon } from './ChatSVG2.svg';
import { ReactComponent as ChatIconGray } from './ChatSVGGray2.svg'

import Projects from '../projects/Projects'
import Account from '../account/Account'
import { UserAuth } from '../../context/AuthContext'
/* Removed local WaveSurfer import */
import { Link, useNavigate } from 'react-router-dom'

import Invites from '../invites/Invites'
import _ from 'lodash';

import { motion } from "framer-motion"
import { LoadedSong } from '../../context/LoadedSongContext'
import userEvent from '@testing-library/user-event'
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

import Chat from '../chat/Chat'
import Playlists from '../playlists/Playlists'
import Credits from '../credits/Credits'
import Files from '../files/Files'

/* Import global waveSurfer */
import { useGlobalWaveSurfer } from '../../context/GlobalWaveSurferContext';

const UserMainPage = (props) => {
    const [page, setPage] = useState(props.page);
    const navigate = useNavigate();

    // Global waveSurfer
    const {
        waveSurferRef,
        loadFile,
        playPause,
        play,
        pause,
        seekTo,
        getCurrentTime,
        getDuration,
        isPlaying,
        currentUrl
    } = useGlobalWaveSurfer();

    const [fileUrl, setFileUrl] = useState("https://s3.eu-central-1.amazonaws.com/some-sprouts/Mindcrush.mp3");
    const [waveWidth, setWaveWidth] = useState(0);
    const [onColabEditor, setOnColabEditor] = useState(false);
    const [mouseEnter1, setMouseEnter1] = useState(false);
    const [mouseEnter2, setMouseEnter2] = useState(false);
    const [mouseEnter3, setMouseEnter3] = useState(false);
    const [mouseEnter4, setMouseEnter4] = useState(false);
    const [mouseEnter5, setMouseEnter5] = useState(false);

    const [parentWidth, setParentWidth] = useState(0);
    const [lengthOfCurrentSong, setLengthOfCurrentSong] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [playhead, setPlayhead] = useState(0);
    const [offsetL, setOffsetL] = useState(0);
    const [pageNum, setPageNum] = useState(2);
    const [awaitingPlay, setAwaitingPlay] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const scaleSize = 1.01;

    const widthRef = useRef(null);
    const waveWidthRef = useRef(null);
    const seekBarRef = useRef(null);

    const pagename = props.nameofpage;
    const loadedSong = LoadedSong();

    const [mousePos, setMousePos] = useState({});
    const { user, storageUsed, storageLimit, storagePercentage } = UserAuth();
    const [percentagePlayed, setPercentagePlayed] = useState(0);

    // Track user’s mouse for potential seeking
    useEffect(() => {
        const handleMouseMove = (event) => {
            setMousePos({ x: event.clientX, y: event.clientY });
        };
        window.addEventListener('mousemove', handleMouseMove);
        return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);

    // Decide whether to show or hide the local waveform/controls
    useEffect(() => {
        if (pageNum === 2) {
            // If user is on the "project" route
            setOnColabEditor(true);
            pause();
        } else {
            setOnColabEditor(false);
        }

        if (window.location.href.includes("editor")) {
            setPageNum(1);
        }

        if (window.location.href.includes("project")) {
            setPageNum(2);
        }

        if (window.location.href.includes("user")) {
            setPageNum(1);
        }

        if (window.location.href.includes("playli")) {
            setPageNum(3);
        }

        if (window.location.href.includes("chat")) {
            setPageNum(4);
            setOnColabEditor(true);
            pause();
        }
    }, [pageNum, pause]);

    useEffect(() => {
        if (waveWidthRef.current) {
            setWaveWidth(waveWidthRef.current.offsetWidth);
        }
    }, [waveWidthRef]);

    const changeToPage = (pagenum, destination) => {
        setPageNum(pagenum);
        navigate(destination);
    };

    useEffect(() => {
        setPage(props.page);
    }, [props.page]);

    // If fileUrl changes AND user is not in colab page, we can load in global
   /* useEffect(() => {
        if (fileUrl && pageNum !== 2 && waveSurferRef.current) {
            loadFile(fileUrl);
            waveSurferRef.current.on('ready', () => {
                setLengthOfCurrentSong(waveSurferRef.current.getDuration());
                setPlaying(true);
                waveSurferRef.current.play();
                waveSurferRef.current.setCurrentTime(0);
            });
        }
    }, [fileUrl, pageNum, waveSurferRef, loadFile]);*/

    const handlePlayPause = () => {
        setPlaying(!playing);
        playPause();
    };

    var doit;
    function resizedw() {
        if (waveWidthRef.current) {
            setParentWidth(waveWidthRef.current.offsetWidth);
            setOffsetL(widthRef.current ? widthRef.current.offsetLeft : 0);
        }
    }

    window.onresize = function () {
        clearTimeout(doit);
        doit = setTimeout(function () {
            resizedw();
        }, 100);
    };

    // small timer for the wave
    useEffect(() => {
        const interval = setInterval(() => {
            setPlayhead(getCurrentTime());
        }, 50);
        return () => clearInterval(interval);
    }, [getCurrentTime]);

    // user click on bottom bar => seek
    const seekToNewPos = (event) => {
        if (!seekBarRef.current) return;
        const barRect = seekBarRef.current.getBoundingClientRect();
        const clickX = event.clientX - barRect.left;
        const ratio = clickX / barRect.width;
        seekTo(ratio);
    };

    // If user changed the loadedSong via context
    useEffect(() => {
        setFileUrl(loadedSong.loadedSong);
    }, [loadedSong]);

    return (
        <div>
            <div className='main__container'>
                <div className='main__dashmargin'>
                    <div className='main__dashboard'>
                        <div className='main__logo'>Soundbird</div>
                        <div className='main__tapesignal'><strong>by Tape Signal</strong></div>

                        <motion.div animate={{ scale: mouseEnter1 ? scaleSize : 1.0 }}>
                            <button
                                className='dash__button'
                                key="1"
                                onClick={() => { changeToPage(1, '/user') }}
                                style={{
                                    boxShadow: pageNum === 1 ? '4px 4px 10px #ebeaea' : '0px 0px 0px #FFFFFF',
                                    color: pageNum === 1 ? '#000000' : '#8A8A8A'
                                }}
                                onMouseEnter={() => setMouseEnter1(true)}
                                onMouseLeave={() => setMouseEnter1(false)}
                            >
                                <div className='dash__buttoninfo'>
                                    {pageNum === 1
                                        ? <PluginsIcon className='dash__buttonicon' />
                                        : <PluginsIconGray className='dash__buttonicon' />}
                                    Notelists
                                </div>
                            </button>
                        </motion.div>

                        <motion.div animate={{ scale: mouseEnter2 ? scaleSize : 1.0 }}>
                            <button
                                className='dash__button'
                                key="2"
                                onClick={() => { changeToPage(2, '/invites') }}
                                style={{
                                    boxShadow: pageNum === 2 ? '4px 4px 10px #ebeaea' : '0px 0px 0px #FFFFFF',
                                    color: pageNum === 2 ? '#000000' : '#8A8A8A'
                                }}
                                onMouseEnter={() => setMouseEnter2(true)}
                                onMouseLeave={() => setMouseEnter2(false)}
                            >
                                <div className='dash__buttoninfo'>
                                    {pageNum === 2
                                        ? <ProjectsIcon className='dash__buttonicon' />
                                        : <ProjectsIconGray className='dash__buttonicon' />}
                                    Projects
                                </div>
                            </button>
                        </motion.div>

                        <motion.div
                            onMouseEnter={() => setMouseEnter3(true)}
                            onMouseLeave={() => setMouseEnter3(false)}
                            animate={{ scale: mouseEnter3 ? scaleSize : 1.0 }}
                        >
                            <button
                                className='dash__button'
                                key="3"
                                onClick={() => { changeToPage(3, '/playlists') }}
                                style={{
                                    boxShadow: pageNum === 3 ? '4px 4px 10px #ebeaea' : '0px 0px 0px #FFFFFF',
                                    color: pageNum === 3 ? '#000000' : '#8A8A8A'
                                }}
                            >
                                <div className='dash__buttoninfo'>
                                    {pageNum === 3
                                        ? <PlaylistsIcon className='dash__buttonicon' />
                                        : <PlaylistsIconGray className='dash__buttonicon' />}
                                    Playlists
                                </div>
                            </button>
                        </motion.div>

                        <motion.div
                            onMouseEnter={() => setMouseEnter4(true)}
                            onMouseLeave={() => setMouseEnter4(false)}
                            animate={{ scale: mouseEnter4 ? scaleSize : 1.0 }}
                        >
                            <button
                                className='dash__button'
                                key="4"
                                onClick={() => { changeToPage(4, '/chat') }}
                                style={{
                                    boxShadow: pageNum === 4 ? '4px 4px 10px #ebeaea' : '0px 0px 0px #FFFFFF',
                                    color: pageNum === 4 ? '#000000' : '#8A8A8A'
                                }}
                            >
                                <div className='dash__buttoninfo'
                           style={{transform: 'translate(3px, 0px)'}}
                                >
                                    {pageNum === 4
                                        ? <ChatIcon className='dash__buttonicon'      style={{transform: 'translate(-1px, 2.5px)'}}/>
                                        : <ChatIconGray className='dash__buttonicon'      style={{transform: 'translate(-1px, 2.5px)'}}/>}
                                    Chat
                                </div>
                            </button>
                        </motion.div>

                        <div className="dash__gap"></div>

                        <div className="dash__storageinfo">
                            <div className="dash__storageh1">
                                Space used
                            </div>
                            <div className="dash__storageh2">
                                {(storageUsed / (1024 * 1024 * 1024)).toFixed(2)} GB of 2 GB
                            </div>
                            <div className="dash__storagebar">
                                <div
                                    className="dash__progress"
                                    style={{ width: `${storagePercentage}%` }}
                                />
                            </div>
                            {storagePercentage > 80 && (
                                <div className="dash__warning">
                                    You have used {storagePercentage.toFixed(2)}% of your storage limit.
                                    Please consider upgrading your plan.
                                </div>
                            )}
                        </div>

                        <motion.div animate={{ scale: mouseEnter5 ? scaleSize : 1.0 }}>
                            {/* Could add more Account UI here */}
                        </motion.div>

                        <div className='dash__line'></div>

                        <motion.div animate={{ scale: mouseEnter5 ? scaleSize : 1.0 }}>
                            <div
                                className='dash__rest'
                                onClick={() => { changeToPage(5, '/account') }}
                                onMouseEnter={() => setMouseEnter5(true)}
                                onMouseLeave={() => setMouseEnter5(false)}
                            >
                                <div className='soundbird__logo'>
                                    <img className="birdsize" src={Logo} alt="Bird" />
                                </div>
                                <div className='dash__accountname'>
                                    {user.email}
                                </div>
                                <div className='dash__dotcontainer'>
                                    <div className='dash__dot' />
                                    <div className='dash__dot' />
                                    <div className='dash__dot' />
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>

                <div className='main__todo'>
                    {page}
                </div>

                {/* 
                  Optionally, if you want a separate WaveSurfer container in user main page: 
                  But we set container in ColabEditor, so typically we'd keep it hidden or unused here
                */}
                <div className='main__wave'>
                    <div
                        id="waveform2"
                        className={"main__wave " + (onColabEditor ? 'show' : 'hidden')}
                        ref={waveWidthRef}
                    />
                </div>

                {/* Show a simple bottom bar if we want to. */}
                {fileUrl && !onColabEditor && (
                    <div className="playlists__controls">
                        <div
                            className="playlists__controlplayhead"
                            ref={seekBarRef}
                            onClick={seekToNewPos}
                        >
                            <div
                                className="playlists__playheaddot"
                                style={{
                                    marginLeft: `${(playhead / (getDuration() || 1)) * 100}%`,
                                    boxShadow: '4px 4px 10px #ebeaea'
                                }}
                            />
                        </div>

                        <div
                            className="playlists__controlplaybutton"
                            onClick={handlePlayPause}
                        >

                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserMainPage;
