import React from 'react'
import UserPage from '../userpage/UserPage'

const PluginEditor = () => {
  return (
    <div>
        <UserPage />
    </div>
  )
}

export default PluginEditor