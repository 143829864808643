// utils/audioAnalysis.js

/**
 * An *approximate* integrated LUFS calculator using
 * an OfflineAudioContext and a K-weighted filter chain.
 * For a full EBU R128 implementation, you'd include gating, etc.
 */

export async function getAudioLufs(file) {
    try {
      // Convert file to an ArrayBuffer
      const arrayBuffer = await readFileAsArrayBuffer(file);
  
      // Decode audio in an OfflineAudioContext
      const audioBuffer = await decodeArrayBuffer(arrayBuffer);
  
      // We'll create another OfflineAudioContext for processing
      const offlineCtx = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        audioBuffer.length,
        audioBuffer.sampleRate
      );
  
      // Create a buffer source
      const source = offlineCtx.createBufferSource();
      source.buffer = audioBuffer;
  
      // Create the required K-weighting filter chain
      // For a very basic K-weight filter, you want:
      //   1) High-pass ~38 Hz (Q=0.707)
      //   2) High-shelf ~1500 Hz, +4dB
      const hpFilter = offlineCtx.createBiquadFilter();
      hpFilter.type = 'highpass';
      hpFilter.frequency.value = 38.0;
      hpFilter.Q.value = 0.707;
  
      const hsFilter = offlineCtx.createBiquadFilter();
      hsFilter.type = 'highshelf';
      hsFilter.frequency.value = 1500.0;
      hsFilter.gain.value = 4.0;
  
      // Connect: source -> HP -> HS -> destination
      source.connect(hpFilter);
      hpFilter.connect(hsFilter);
      hsFilter.connect(offlineCtx.destination);
  
      source.start();
  
      // Render the entire file in offline mode
      const renderedBuffer = await offlineCtx.startRendering();
  
      // Now compute average LUFS from the rendered buffer
      const averageLufs = computeIntegratedLufs(renderedBuffer);
  
      return averageLufs;
    } catch (err) {
      console.error('Error computing LUFS:', err);
      // If there's an error, just return a fallback
      return -Infinity;
    }
  }
  
  /**
   * Helper: read file as array buffer
   */
  function readFileAsArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = () => reject(reader.error);
      reader.onload = () => resolve(reader.result);
      reader.readAsArrayBuffer(file);
    });
  }
  
  /**
   * Helper: decode audio data to AudioBuffer
   */
  function decodeArrayBuffer(arrayBuffer) {
    return new Promise((resolve, reject) => {
      const audioCtx = new AudioContext();
      audioCtx.decodeAudioData(
        arrayBuffer,
        (buffer) => {
          audioCtx.close(); // close context to free resources
          resolve(buffer);
        },
        (e) => {
          reject(e);
        }
      );
    });
  }
  
  /**
   * Helper: approximate integrated LUFS from the fully-rendered buffer.
   * This is a *simplified* approach.
   */
  function computeIntegratedLufs(audioBuffer) {
    const channelData = [];
    for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
      channelData.push(audioBuffer.getChannelData(i));
    }
  
    let sumSquares = 0;
    let totalSamples = audioBuffer.length * audioBuffer.numberOfChannels;
  
    // Sum of squares across all channels
    for (let c = 0; c < channelData.length; c++) {
      const data = channelData[c];
      for (let i = 0; i < data.length; i++) {
        sumSquares += data[i] * data[i];
      }
    }
  
    // Mean square
    const meanSquare = sumSquares / totalSamples;
  
    // Convert to dB
    const rmsDb = 10 * Math.log10(meanSquare);
  
    // For a rough "Integrated LUFS," we account for the approximate offset
    // that EBU R128 gating + K-weighting typically includes. 
    // A rule of thumb offset might be ~ -0.691 (for digital dBFS to LUFS),
    // but it's more complicated in a *real* implementation (e.g. gating).
    // We'll do a minor offset for demonstration:
    const approximateLufs = rmsDb - 0.7;
  
    return approximateLufs;
  }
  