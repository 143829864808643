// FriendRequestItem.js
import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove, query, collection, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserAuth } from '../../context/AuthContext';

const FriendRequestItem = ({ requesterDocId }) => {
    const { user } = UserAuth();
    const [requesterData, setRequesterData] = useState(null);
    const [currentUserDocId, setCurrentUserDocId] = useState(null);

    useEffect(() => {
        if (!user) return;

        // Fetch current user's document ID
        const q = query(collection(db, 'users'), where('email', '==', user.email));
        getDocs(q).then((querySnapshot) => {
            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                setCurrentUserDocId(userDoc.id); 
            }
        });

        // Fetch requester data
        const requesterRef = doc(db, 'users', requesterDocId);
        getDoc(requesterRef).then((docSnap) => {
            if (docSnap.exists()) {
                setRequesterData(docSnap.data());
            }
        });


    }, [user, requesterDocId]);

    useEffect(()=>{
        console.log("requester doc id " << requesterDocId)
    },[])

    const acceptRequest = async () => {
        if (!currentUserDocId) return;

        const currentUserRef = doc(db, 'users', currentUserDocId);
        const requesterRef = doc(db, 'users', requesterDocId);

        await updateDoc(currentUserRef, {
            friends: arrayUnion(requesterDocId),
            friendRequests: arrayRemove(requesterDocId),
        });

        await updateDoc(requesterRef, {
            friends: arrayUnion(currentUserDocId),
        });

        console.log('Friend request accepted between', currentUserDocId, 'and', requesterDocId);
    };

    const rejectRequest = async () => {
        if (!currentUserDocId) return;

        const currentUserRef = doc(db, 'users', currentUserDocId);

        await updateDoc(currentUserRef, {
            friendRequests: arrayRemove(requesterDocId),
        });

        console.log('Friend request rejected from', requesterDocId);
    };




    return (
        <div className="chat__friendRequestItem">

            <button onClick={acceptRequest}>Accept</button>
            <button onClick={rejectRequest}>Reject</button>
        </div>
    );
};

export default FriendRequestItem;
