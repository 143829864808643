import React from 'react'
import ElementContainer from '../elementcontainer/ElementContainer'
import MainTitle from '../maintitle/MainTitle'

const UserPage = () => {

  return (
    <div className='bg'>
         <ElementContainer />
         <div className='bottom' />
    </div>
  )
}

export default UserPage
