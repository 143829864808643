import React, { useEffect, useState } from 'react'
import OneProject from './OneProject'

import { db } from "../../firebase"
import {
  doc,
  collection,
  onSnapshot,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  orderBy
} from "firebase/firestore"

import { UserAuth } from '../../context/AuthContext';

import './projects.css'

import { Link, useNavigate } from 'react-router-dom'

import { motion } from "framer-motion"

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [searchPrompt, setSearchPrompt] = useState("");
  const [addButtonHover, setAddButtonHover] = useState(false);
  const [memberTextAreaFocus, setMemberTextAreaFocus] = useState(false);

  const navigate = useNavigate()


  const { user } = UserAuth()

  const projectsCollectionRef = collection(db, "projects");

  const onFocus = () => setMemberTextAreaFocus(true)
  const onBlur = () => setMemberTextAreaFocus(false)

  const[results, setResults] = useState([]);

  const setSearchResults = () => {
    setResults(projects.filter((item)=>eachIndex(item)));
}

function eachIndex(e){
  return e.value.toLowerCase().includes(searchPrompt.toLowerCase());
  }


useEffect(() => {
  setSearchResults(); 
}, [searchPrompt]);

useEffect(() => {
  setSearchResults(); 
}, [projects]);



  useEffect(() => {
    if (user.uid) {
      const q = query(collection(db, "projects"), where("user", "==", user.uid), orderBy("time", "desc"));

      onSnapshot(q, (snapshot) => {

        setProjects(snapshot.docs.map(doc => {
          return {
            id: doc.id,
            viewing: false,
            ...doc.data()
          }
        }))
      })
    }
  }, [user.uid])

  const showFormfun = () => {
    setShowForm(!showForm);
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const newTodo = {
      value: projectName,
      user: user.uid
    }

    //setInputList([...inputList].concat(newTodo));
    setDoc(doc(projectsCollectionRef), newTodo);
  }

  const openProject = (id, name) => {
    //navigate('/user', { projectid: [projectName] })
    navigate(`/editor/${id}/${name}`);
  }

  return (
    <div className='projects__maincontainer'>

      <div className="projects__topcontainer">

        <div className='projects__title'>
          Plugins
        </div>
        <motion.div
          onMouseEnter={e => {
            setAddButtonHover(true);
          }}
          onMouseLeave={e => {
            if (!memberTextAreaFocus) {
              setAddButtonHover(false);
            }
          }}

        >
          <motion.div
            className='memberpanel__shadow'
            onMouseEnter={e => {
              setAddButtonHover(true);
            }}
            onMouseLeave={e => {
              if (!memberTextAreaFocus) {
                if (searchPrompt.length == 0) {
                  setAddButtonHover(false);
                }
              }
            }}
            animate={{ opacity: addButtonHover ? 1 : 0 }}
            style={{
              boxShadow: '4px 4px 10px #ebeaea',
            }}
          >
          </motion.div>

          <div className='memberpanel__place'>

            <motion.div
              onMouseEnter={e => {
                setAddButtonHover(true);
              }}
              onMouseLeave={e => {
                if (!memberTextAreaFocus) {
                  if (searchPrompt.length == 0) {
                    setAddButtonHover(false);
                  }
                }
              }}
              animate={{
                opacity: addButtonHover ? 1 : 0,
              }}
            >
              <input
                className='memberpanel__addplaceholder'
                value={searchPrompt}
                onChange={(e) => setSearchPrompt(e.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
              >
              </input>
              {/*<button onClick={addMember}>
                        add
                </button>*/}
            </motion.div>
          </div>

        </motion.div>


        <motion.div
          className='projects__searchbutton'
          onMouseEnter={e => {
            setAddButtonHover(true);
          }}
          onMouseLeave={e => {
            if (!memberTextAreaFocus) {
              if (searchPrompt.length == 0) {
                setAddButtonHover(false);
              }
            }
          }}
          animate={{
            x: addButtonHover ? 255 : 0,
          }}
        // onClick={addMember}
        >
          ?
        </motion.div>

        <motion.div
          className='memberpanel__text'
          onMouseEnter={e => {
            setAddButtonHover(true);
          }}
          onMouseLeave={e => {
            if (!memberTextAreaFocus) {
              if (searchPrompt.length == 0) {
                setAddButtonHover(false);
              }
            }
          }}
          animate={{
            opacity: addButtonHover ? 0 : 1,
          }}
        >
          Search Plugins...
        </motion.div>

      </div>
      <div className='projects__container'>

        {results.map((element) =>
          <div key={element.id}>
            <div onClick={() => openProject(element.id, element.value)}>
              <OneProject name={element.value} dbId={element.id} done={element.completed} user={user.uid} />
            </div>
          </div>)}
      </div>

      <div className='project__form'>
        {showForm && (
          <form onSubmit={handleSubmit}>
            <input onChange={(e) => setProjectName(e.target.value)} />
            <button>
              Add Project
            </button>
          </form>
        )}

      </div>

    </div>
  )
}

export default Projects