// ColabMessage.jsx
import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import { db } from '../../firebase'
import { doc, setDoc } from 'firebase/firestore';

import EmojiIcon from './emojiicon.svg';
import EditIcon from './editicon.svg';

const ColabMessage = (props) => {
  const {
    message,
    position,
    color,
    commentId,
    reactions,
    currentUserEmail,
    userName,
    users = {},
    isHighlighted = false, // <-- NEW: parent decides if this is highlighted
  } = props;

  const [mouseEnter, setMouseEnter] = useState(false);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [memberPanelOpen, setMemberPanelOpen] = useState(props.memberPanelOpen);
  const [hoveredReaction, setHoveredReaction] = useState(null);

  let name = userName || "Name";
  const popupRef = useRef(null);
  const emojiOptions = ['❤️', '😂', '🥸', '😢', '✅'];

  // Convert position => time string
  let pos = position;
  const sec = Math.trunc(pos) % 60;
  const millisec = (((pos % 60) - sec) * 100).toFixed(0);
  const hours = Math.trunc(pos / 3600);
  let min = Math.trunc((pos - hours * 3600) / 60);
  min = min % 60;

  let secString = String(sec).padStart(2, '0');
  let milString = String(millisec).padStart(2, '0');
  let minString = String(min).padStart(2, '0');
  let hourString = String(hours).padStart(2, '0');

  const combinedString = `${minString}:${secString}:${milString}`;

  // Choose background color
  let colStyle = { backgroundColor: "#A4ECDF" };
  if (color === 2) colStyle = { backgroundColor: "#ECBAA4" };
  if (color === 1) colStyle = { backgroundColor: "#A4DFEC" };
  if (color === 3) colStyle = { backgroundColor: "#ECA4BA" };

  useEffect(() => {
    setMemberPanelOpen(props.memberPanelOpen);
  }, [props.memberPanelOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiOpen && popupRef.current && !popupRef.current.contains(event.target)) {
        setEmojiOpen(false);
        setMouseEnter(false);
      }
    };
    if (emojiOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiOpen]);

  // Provided by parent or no-op
  const deleteComment = props.deleteComment || (() => { });

  // User selects an emoji reaction
  const handleEmojiClick = async (selectedEmoji) => {
    setEmojiOpen(false);
    setMouseEnter(false);
    try {
      const commentRef = doc(db, 'comments', commentId);
      await setDoc(
        commentRef,
        {
          reactions: { [currentUserEmail]: selectedEmoji }
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error saving emoji reaction: ", error);
    }
  };

  // Clear reaction
  const handleClearReaction = async (e) => {
    e.stopPropagation();
    setEmojiOpen(false);
    setMouseEnter(false);
    try {
      const commentRef = doc(db, 'comments', commentId);
      await setDoc(
        commentRef,
        {
          reactions: { [currentUserEmail]: "" }
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error clearing emoji reaction: ", error);
    }
  };

 // Utility function to saturate and darken a color
const saturateColor = (colStyle, saturationAmount = 0.5, darkenAmount = 0.4) => {
  const hexColor = colStyle.backgroundColor;

  // Convert HEX to RGB
  const hexToRgb = (hex) => {
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  // Convert RGB to HSL
  const rgbToHsl = ({ r, g, b }) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          break;
      }
      h /= 6;
    }
    return { h, s, l };
  };

  // Convert HSL back to RGB
  const hslToRgb = ({ h, s, l }) => {
    let r, g, b;

    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255),
    };
  };

  // Adjust saturation and lightness
  const rgb = hexToRgb(hexColor);
  if (!rgb) return colStyle;

  const hsl = rgbToHsl(rgb);
  hsl.s = Math.min(hsl.s + saturationAmount, 1); // Increase saturation
  hsl.l = Math.max(hsl.l - darkenAmount, 0); // Decrease lightness to darken
  const newRgb = hslToRgb(hsl);

  return {
    backgroundColor: `rgb(${newRgb.r}, ${newRgb.g}, ${newRgb.b})`,
  };
};



  return (
    <div
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => {
        if (!emojiOpen) {
          setMouseEnter(false);
        }
      }}
      style={{ position: 'relative' }}
    >
      <motion.div
        className='colab__messagecontainer'
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
        style={{
          ...colStyle,
          zIndex: memberPanelOpen ? -2 : 'auto',
        }}


        animate={{
          marginTop: mouseEnter ? '5px' : '5px',
          marginBottom: mouseEnter ? '5px' : '-5px'
        }}
      >
        {/* The highlight overlay */}
        <motion.div
          className='colab__messagehighlight'
          animate={{
            // show highlight if isHighlighted
            opacity: isHighlighted ? 1.0 : 0.0,
            height: mouseEnter ? 'calc(120% + 40px)' : '120%',
          }}
        />

        <motion.div
          onClick={(e) => e.stopPropagation()}
          className='colab__messagesender'
          animate={{ opacity: mouseEnter ? 1.0 : 0.0 }}
          style={{
            color: saturateColor(colStyle, 0.4).backgroundColor, // Use saturated color for text
            zIndex: 2,
          }}
        >
          {name}
        </motion.div>

        <motion.div
          className='colab__message'
          whileInView={{ opacity: 1, scale: 1 }}
          animate={{ y: mouseEnter ? 12.0 : 0.0 }}
          style={{ zIndex: 2 }}
        >
          {message}
        </motion.div>

        <motion.div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className='colab__deleteButton'
          animate={{
            opacity: mouseEnter ? 1.0 : 0.0,
            width: mouseEnter ? 26.0 : 0.0,
          }}
          style={{ zIndex: 2, pointerEvents: 'auto' }}
        >
          <img src={EditIcon} alt="Edit" />
        </motion.div>

        <motion.div
          onClick={(e) => {
            e.stopPropagation();
            setEmojiOpen(!emojiOpen);
          }}
          className='colab__messageemoji'
          animate={{
            opacity: mouseEnter ? 1.0 : 0.0,
            width: mouseEnter ? 26.0 : 0.0,
          }}
          style={{ zIndex: 2, pointerEvents: 'auto' }}
        >
          <img src={EmojiIcon} alt="Emoji" />
        </motion.div>

        {emojiOpen && (
          <div
            ref={popupRef}
            className='colab__emojiPopup'
            style={{
              position: 'absolute',
              padding: '10px',
              zIndex: 999,
              pointerEvents: 'auto',
            }}
          >
            <motion.div style={{ display: 'flex', gap: '8px' }}>
              {emojiOptions.map((emoji) => (
                <span
                  key={emoji}
                  style={{ cursor: 'pointer', fontSize: '1.2rem' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEmojiClick(emoji);
                  }}
                >
                  {emoji}
                </span>
              ))}
            </motion.div>
            <div
              onClick={handleClearReaction}
              style={{
                pointerEvents: 'auto',
                marginTop: '8px',
                cursor: 'pointer',
                textAlign: 'center',
                marginLeft: '10px',
                marginTop: '0px',
                fontSize: '0.9rem',
              }}
            >
              X
            </div>
          </div>
        )}
      </motion.div>

      <motion.div
        className='colab__messageextra'
        style={colStyle}
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        animate={{
          height: mouseEnter ? 50.0 : 0.0,
        }}
      >
        <motion.div
          className='colab__messagepostext'
          animate={{ opacity: mouseEnter ? 1.0 : 0.0 }}
        >
          {combinedString}
        </motion.div>
      </motion.div>

      <motion.div
        className='colab__messagemargin'
        animate={{ marginTop: mouseEnter ? -20 : 0 }}
      />

      {/* Reaction display */}
      {reactions &&
        Object.keys(reactions).length > 0 &&
        Object.values(reactions).some((value) => value !== '') && (
          <motion.div
            style={{
              marginTop: 10,
              display: 'flex',
              gap: '0px',
              flexWrap: 'wrap',
              fontSize: '1.1rem',
              transform: 'translate(0px, -15px)',
              justifyContent: 'end',
              marginTop: '-20px',
              marginBottom: '-10px',
              position: 'relative',
              zIndex: memberPanelOpen || emojiOpen ? -2 : 999,
            }}
            animate={{
              x: mouseEnter ? -15.0 : 7.0,
            }}
          >
            {Object.entries(reactions).map(([reactingUser, emoji]) => {
              if (!emoji) return null;

              let userDisplayName;
              if (reactingUser === currentUserEmail) {
                userDisplayName = "You";
              } else {
                userDisplayName = users[reactingUser]?.name || reactingUser;
              }

              return (
                <motion.div
                  key={reactingUser}
                  style={{
                    position: 'relative',
                    borderRadius: '4px',
                    padding: '2px 3px',
                    cursor: 'default'
                  }}
                  onMouseEnter={() => setHoveredReaction(reactingUser)}
                  onMouseLeave={() => setHoveredReaction(null)}
                >
                  {emoji}
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{
                      opacity: hoveredReaction === reactingUser ? 1 : 0,
                      scale: hoveredReaction === reactingUser ? 1 : 0.8,
                    }}
                    transition={{ duration: 0.2 }}
                    style={{
                      position: 'absolute',
                      bottom: '-80%',
                      left: '50%',
                      transform: 'translate(0px,20px)',
                      backgroundColor: '#333',
                      color: '#fff',
                      padding: '4px 8px',
                      borderRadius: '4px',
                      pointerEvents: 'none',
                      whiteSpace: 'nowrap',
                      fontSize: '0.8rem',
                      zIndex: 999,
                    }}
                  >
                    {userDisplayName}
                  </motion.div>
                </motion.div>
              );
            })}
          </motion.div>
        )}
    </div>
  );
};

export default ColabMessage;
