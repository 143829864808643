import React, { useState } from 'react'

import { db } from "../../firebase"
import {
  doc,
  collection,
  onSnapshot,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  serverTimestamp
} from "firebase/firestore"

import { UserAuth } from '../../context/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';

import './plugform.css'

const Plugform = () => {

    const[projectName, setProjectName] = useState("");
    const[selectedProject, setSelectedProject] = useState("");
    const navigate = useNavigate()
    const[done, setDone] = useState(false);
    const[idForNav, setIdForNav] = useState("");


    const {user} = UserAuth()

    const projectsCollectionRef = collection(db, "projects");

   
    const handleSubmit = async () => {

        const currentDateTime = Date().toLocaleString()
        const timeforid = currentDateTime.slice(0, -15);
    

        const projectRef = doc(projectsCollectionRef) // docRef
        const id = projectRef.id // a docRef has an id property
        const newTodo = {
            name: projectName,
            user: user.uid,
            id: id,
          } 

          const userData = {newTodo} // insert the id among the data
        await setDoc(projectRef, 
            {value: timeforid,
            user: user.uid,
            id: id,
            time: serverTimestamp()}) // create the document
      
        openProject(id, timeforid);
      }

      const openProject = async (id, name) => {
        //navigate('/user', { projectid: [projectName] })
        navigate(`/plugineditor/${id}/${name}/${"1"}`);
        setIdForNav(id)
        setDone(true);
      }

      handleSubmit();

    return (
    <div className='load__contain'>
        <div className='loader__container'>
            <div className='spinner'>
            <div className='spinner2'>

</div>
            </div>
        </div>

        {done && (
        navigate(`/plugineditor/${idForNav}`)
      )}

        

   
    </div>
  )
}

export default Plugform
