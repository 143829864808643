import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const OneUserFiles = (props) => {
    const [isHovered, setIsHovered] = useState(false);

    // Get the user's email or name from props
    const userEmail = props.userEmail;
    const userName = props.userName || userEmail;

    // Extract the first letter to display
    const firstLetter = userName.charAt(0).toUpperCase();

    // Predetermined colors for the first 4 users
   // const predeterminedColors = ["#A4ECDF", "#A4DFEC", "#ECBAA4", "#ECA4BA"];

   const predeterminedColors = ["#8BE4D4", "#91DEF0", "#E4A98F", "#EE94B0"];

    // Generate a background color based on the user's email
    const stringToColor = (string) => {
        let hash = 0;
        for (let i = 0; i < string.length; i++) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
    };

    // Determine the background color
    const backgroundColor = props.index !== undefined && props.index < 4
        ? predeterminedColors[props.index]
        : stringToColor(userEmail);

    useEffect(() => {
        console.log(isHovered);
    }, [isHovered]);

    return (
        <motion.div
            className='files__oneuser__container'
            style={{
                backgroundColor: backgroundColor,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 10px',
                borderRadius: '50%',
                cursor: 'default',
                color: '#fff',
                margin: '0 5px',
                height: '45px',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}

            transition={{ duration: 0.2 }}
        >

            <span style={{ fontSize: '16px', color: '#fff' }}>
                {firstLetter}
            </span>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{
                    opacity: isHovered ? 1 : 0,

                }}
                className='files__userpopup'
                transition={{ duration: 0.2 }}
                style={{
                    position: 'absolute',
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    pointerEvents: 'none',
                    whiteSpace: 'nowrap',
                    fontSize: '0.8rem',
                    zIndex: 999,
                }}
            >
                {userName}
            </motion.div>

        </motion.div>
    );
};

export default OneUserFiles;