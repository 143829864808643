

// Chat.js
import React, { useState, useEffect, useRef } from 'react';
import {
    doc,
    collection,
    onSnapshot,
    setDoc,
    query,
    where,
    getDocs,
    orderBy,
    getDoc,
    serverTimestamp,
    arrayUnion,
    arrayRemove,
    updateDoc,
    addDoc
} from 'firebase/firestore';
import { db } from '../../firebase';
import { UserAuth } from '../../context/AuthContext';
import FriendRequestItem from './FriendRequestItem';
import FriendItem from './FriendItem';
import UserProfileDisplay from './UserProfileDisplay';
import UserNameDisplay from './UserNameDisplay';
import './chat.css';

import ColabSendSVG from './ChatSend.svg'

const Chat = () => {
    const { user } = UserAuth();
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [currentUserDocId, setCurrentUserDocId] = useState(null);
    const [friendRequests, setFriendRequests] = useState([]);
    const [friends, setFriends] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    // Fetch current user's document by email
    useEffect(() => {
        if (!user) return;
        if (!user.email) return;

        console.log('Fetching user document for email:', user.email);

        const q = query(collection(db, 'users'), where('email', '==', user.email));

        const unsubscribe = onSnapshot(
            q,
            (querySnapshot) => {
                if (querySnapshot.empty) {
                    console.log('No user document found for email:', user.email);
                    return;
                }

                const userDoc = querySnapshot.docs[0];
                const data = userDoc.data();

                setCurrentUserDocId(userDoc.id);
                setFriendRequests(data.friendRequests || []);
                setFriends(data.friends || []);
            },
            (error) => {
                console.error('Error fetching user document:', error);
            }
        );

        return unsubscribe;
    }, [user]);

    // Fetch chats involving the current user
    useEffect(() => {
        if (!currentUserDocId) return;
        if (!user) return;
        if (!user.email) return;

        const q = query(
            collection(db, 'chats'),
            where('participants', 'array-contains', currentUserDocId)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const chatsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setChats(chatsData);
        });

        return unsubscribe;
    }, [currentUserDocId]);

    const handleSearch = async () => {
        if (!user) return;
        if (!user.email) return;
        const q = query(collection(db, 'users'), where('email', '==', searchTerm));
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map((doc) => ({ docId: doc.id, ...doc.data() }));
        setSearchResults(users);
    };

    const sendMessage = async () => {
        if (!newMessage.trim() || !selectedChat) return;

        const messagesRef = collection(db, 'chats', selectedChat.id, 'messages');

        await addDoc(messagesRef, {
            text: newMessage,
            senderId: user.uid,
            timestamp: serverTimestamp(),
        });

        // Update last message and timestamp in the chat document
        const chatRef = doc(db, 'chats', selectedChat.id);
        await updateDoc(chatRef, {
            lastMessage: newMessage,
            lastUpdated: serverTimestamp(),
        });

        setNewMessage('');
    };

    const startChat = async userToChatWith => {
        if (!user) return;
        if (!user.email) return;
        if (!friends.includes(userToChatWith.uid)) {
            alert('You can only chat with friends.');
            return;
        }
        // Check if a chat already exists between the two users
        const q = query(
            collection(db, 'chats'),
            where('participants', 'array-contains', user.uid)
        );

        const snapshot = await getDocs(q);
        let chatExists = false;
        let chatId = null;

        snapshot.forEach(doc => {
            const data = doc.data();
            if (data.participants.includes(userToChatWith.uid)) {
                chatExists = true;
                chatId = doc.id;
            }
        });

        if (chatExists) {
            // Chat exists, select it
            const chatDoc = await getDoc(doc(db, 'chats', chatId));
            selectChat({ id: chatDoc.id, ...chatDoc.data() });
        } else {
            // Create a new chat
            const chatRef = doc(collection(db, 'chats'));
            await setDoc(chatRef, {
                participants: [user.uid, userToChatWith.uid],
                lastUpdated: serverTimestamp(),
            });
            selectChat({ id: chatRef.id, participants: [user.uid, userToChatWith.uid] });
        }
    };

    const sendFriendRequest = async (recipientEmail) => {
        if (!user) return;
        if (!user.email) return;

        // Query the recipient's user document by email
        const q = query(collection(db, 'users'), where('email', '==', recipientEmail));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.error('No user found with email:', recipientEmail);
            return;
        }

        const recipientDoc = querySnapshot.docs[0];
        const recipientDocId = recipientDoc.id;

        // Update recipient's friendRequests array
        const recipientRef = doc(db, 'users', recipientDocId);
        await updateDoc(recipientRef, {
            friendRequests: arrayUnion(user.uid),
        });

        console.log('Friend request sent from', user.uid, 'to', recipientDocId);
    };


    useEffect(() => {
        if (!user) return;
        if (!user.email) return;

        console.log('Listening to friendRequests for user with email:', user.email);

        // Query the user document where email == user.email
        const q = query(collection(db, 'users'), where('email', '==', user.email));

        const unsubscribe = onSnapshot(
            q,
            (querySnapshot) => {
                if (querySnapshot.empty) {
                    console.log('No user document found for email:', user.email);
                    return;
                }

                // Assuming emails are unique, we can take the first document
                const userDoc = querySnapshot.docs[0];
                const data = userDoc.data();
                console.log('User document data:', data);

                setFriendRequests(data.friendRequests || []);
                setCurrentUserDocId(userDoc.id); // Save the user's document ID
            },
            (error) => {
                console.error('Error fetching user document:', error);
            }
        );

        return unsubscribe;
    }, [user]);


    useEffect(() => {
        if (!user) return;
        if (!user.email) return;

        const unsubscribe = onSnapshot(doc(db, 'users', user.uid), docSnap => {
            if (docSnap.exists()) {
                setFriends(docSnap.data().friends || []);
            }
        });

        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (!user) return;
        if (!user.email) return;
        console.log(friendRequests)
    }, [friendRequests]);

    const listRef = useRef(null);

    useEffect(() => {
        listRef.current?.lastElementChild?.scrollIntoView()
    }, [messages]);

    /* function onIntersection(entries){
         const firstEntry = entries[0];
         if(firstEntry.isIntersection && hasMore)
     }
 
     useEffect(()=> {
         const observer = new IntersectionObserver(onIntersection)
         if(observer && listRef.current){
             observer.observe(listRef.current);
         }
         
         return () => {
             observer.disconnect();
         }
     },[messages])*/


    const selectChat = chat => {
        setSelectedChat(chat);
        // Fetch messages for the selected chat
        const messagesRef = collection(db, 'chats', chat.id, 'messages');
        const q = query(messagesRef, orderBy('timestamp'));

        const unsubscribe = onSnapshot(q, snapshot => {
            const messagesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setMessages(messagesData);
        });

        // Clean up on unmount or when changing chats
        return () => unsubscribe();
    };



    return (
        <div className="chat">
            <div className="chat__top">
                <div className="chat__title">Chat</div>
            </div>

            <div className="chat__maincontainer">
                {/* Chats List */}
                <div className="chat__chatscontainer">
                    <div className="chat__chats__top">
                        <div className="chat__chats__title">Chats</div>
                        <div className="chat__chats__searchbox">
                            <input
                                type="text"
                                placeholder="Search users by email"
                                value={searchTerm}
                                className='chat__chats__searchboxstyle'
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                            <button onClick={handleSearch}>Search</button>
                        </div>

                        <div className="chat__searchResults">
                            {searchResults.map(user => (
                                <div key={user.uid} className="chat__searchResultItem">
                                    <span>{user.email}</span>
                                    <button onClick={() => sendFriendRequest(user.email)}>Add Friend</button>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div className="chat__chats">
                        {chats.map(chat => (
                            <div
                                key={chat.id}
                                className={`chat__chatItem ${selectedChat?.id === chat.id ? 'selected' : ''}`}
                                onClick={() => selectChat(chat)}
                            >
                                {/* Display the name of the other participant */}
                                <div className="chat__chatItemName">
                                    {chat.participants
                                        .filter(uid => uid !== user.uid)
                                        .map(uid => (
                                            <UserNameDisplay key={uid} uid={uid} lastMessage={chat.lastMessage} />
                                        ))}
                                </div>
                
                            </div>
                        ))}
                    </div>

                    <div className="chat__friendRequests">
                        <h3>Friend Requests</h3>
                        {friendRequests.length === 0 && <p>No pending requests.</p>}
                        {friendRequests.map(uid => (
                            <div>
                                <FriendRequestItem key={uid} requesterDocId={uid} />
                                {uid}
                            </div>
                        ))}
                    </div>

                    <div className="chat__friendsList">
                        <h3>Your Friends</h3>
                        {friends.length === 0 && <p>Add friends to start chatting.</p>}
                        {friends.map(uid => (
                            <FriendItem
                                key={uid}
                                uid={uid}
                                onClick={() => startChat({ uid })}
                            />
                        ))}
                    </div>


                </div>

                {/* Chat Window */}
                <div className="chat__chatcontainer">
                    {selectedChat ? (
                        <>
                            <div className="chat__chattop">
                                <div className="chat__currentchattingprofile">
                                    {/* Display profile pic and name of the other user */}
                                    <UserProfileDisplay
                                        uid={selectedChat.participants.find(uid => uid !== user.uid)}
                                    />
                                </div>
                            </div>

                            <div className="chat__messages" ref={listRef}>
                                {messages.map(message => (
                                    <div
                                        key={message.id}
                                        className={`chat__message ${message.senderId === user.uid ? 'sent' : 'received'
                                            }`}
                                    >
                                        {message.text}
                                    </div>
                                ))}
                            </div>

                            <div className="chat__bottom">
                                <input
                                    type="text"
                                    className="chat__textbox"
                                    value={newMessage}
                                    onChange={e => setNewMessage(e.target.value)}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') sendMessage();
                                    }}
                                    placeholder='Write a message...'
                                />
                                <button className="chat__sendbutton" onClick={sendMessage}>
                                    <img className="chat__messagesendsize" src={ColabSendSVG}></img>
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="chat__noChatSelected">Select a chat to start messaging</div>
                    )}
                </div>
            </div>
        </div>
    );

}

export default Chat