import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserMainPageMock from '../usermainpage/UserMainPageMock';
import ColabEditorMini from './ColabEditorMini';
import FilesMini from './FilesMini';
import DawShowcase from './DawShowcase';

import './landing.css';

const LandingPage = () => {
    const navigate = useNavigate();

    const [currentTime, setCurrentTime] = useState(0);

    const [allComments, setAllComments] = useState([
        {
            value: "Your clients can comment on the track via the website or the app.",
            position: 0,
            col: 0,
        },
        {
            value: "Like this one.",
            position: 0,
            col: 0,
        },
    ]);

    const [showForm, setShowForm] = useState(false);
    const [formDetails, setFormDetails] = useState({ email: '', name: '', profession: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    };

    const handleApply = () => {
        console.log('Form Details:', formDetails);
    };

    useEffect(() => {
        document.body.classList.add('landing-page-body');

        const preventSpaceScroll = (e) => {
            const activeElement = document.activeElement;
            const isInputField =
                activeElement.tagName === 'TEXTAREA' ||
                (activeElement.tagName === 'INPUT' && activeElement.type === 'text');

            if (e.code === 'Space' && !isInputField) {
                e.preventDefault();
            }
        };

        window.addEventListener('keydown', preventSpaceScroll);

        return () => {
            document.body.classList.remove('landing-page-body');
            window.removeEventListener('keydown', preventSpaceScroll);
        };
    }, []);

    return (
        <main className="landingpage__container">
            <section className="hero">
                <header className="landingpage__top">
                    <div className="linear-blur">
                        <div className="background-layer">
                            <div className="blur-layer blur-64"></div>
                            <div className="blur-layer blur-32"></div>
                            <div className="blur-layer blur-16"></div>
                            <div className="blur-layer blur-8"></div>
                            <div className="blur-layer blur-4"></div>
                            <div className="blur-layer blur-2"></div>
                            <div className="blur-layer blur-0-5"></div>
                        </div>
                    </div>

                    <div className="landingpage__logo">
                        <div className="main__logo">Soundbird</div>
                    </div>
                    <div className="landingpage__signin">
                        <button
                            className="invite__button"
                            onClick={() => navigate('/signup')}
                        >
                            Sign up
                        </button>
                    </div>
                </header>

                <div className="desktop-view">
                    <div className="hero__content">
                        <h1 className="landingpage__maintitle">
                            Everything in the
                            <div className="landingpage__maintitlebold">same place.</div>
                        </h1>
                        <div className="landingpage__firstimg">
                            <div className="landingpage__filesshadow">
                                <UserMainPageMock inLanding={true} page={<FilesMini />} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="more-content desktop-view">
                <div className="landingpage__colabcontainer">
                    <ColabEditorMini
                        onTimeUpdate={(timeInSeconds) => setCurrentTime(timeInSeconds)}
                        allComments={allComments}
                        setAllComments={setAllComments}
                    />
                </div>

                <div className="landingpage__dawshowcase">
                    <DawShowcase allComments={allComments}
                        currentTime={currentTime} />
                </div>

                <div className="landingpage__finalmessage">
                    <div
                        className="landingpage__finalmessagetext"
                        style={{ marginBottom: '20px', textAlign: 'center' }}
                    >
                        We're live! <br /> Apply now to join our exclusive public beta.
                    </div>
                    {!showForm && (
                        <div className="files__addUserPopupButtons">
                            <button
                                style={{
                                    marginBottom: '50px',
                                    fontSize: '20px',
                                    padding: '20px 42px',
                                    letterSpacing: '-0.2px',
                                }}
                                onClick={() => setShowForm(true)}
                            >
                                Apply for our public beta
                            </button>
                        </div>
                    )}

                    {showForm && (
                        <div style={{ textAlign: 'center', marginBottom: '50px' }} className="landing__form">
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formDetails.email}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px', borderRadius: '20px' }}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={formDetails.name}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '10px', borderRadius: '20px' }}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="profession"
                                    placeholder="Profession"
                                    value={formDetails.profession}
                                    onChange={handleInputChange}
                                    style={{ marginBottom: '20px', borderRadius: '20px' }}
                                />
                            </div>
                            <div className='files__addUserPopupButtons'>
                                <button


                                    onClick={handleApply}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    )}

                    <div
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <div style={{ fontSize: '20px' }}>Supported by:</div>
                        <img
                            style={{ filter: 'invert(1)', width: '150px' }}
                            src="https://www.rannis.is/media/taeknithrounarsjodur/small/tthrsj_logo_is_w-transp.png"
                            alt="Rannis Support"
                        />
                    </div>
                </div>
            </section>

            <section className="phone-section">
                <h1 className="landingpage__maintitle">
                    Everything in the
                    <div className="landingpage__maintitlebold">same place.</div>
                </h1>
                
                <div>
                    Open this page on a desktop to see an interactive demo of soundbird
                </div>
                {/*
                <div className="landingpage__firstimg__img">
                    <img
                        src="https://via.placeholder.com/600x300?text=Files+Preview"
                        alt="Files preview"
                    />
                </div>
                <div className="landingpage__colabcontainer__img">
                    <img
                        src="https://via.placeholder.com/600x300?text=Colab+Preview"
                        alt="Collaboration Editor preview"
                    />
                </div>
                <div className="landingpage__dawshowcase__img">
                    <img
                        src="https://via.placeholder.com/600x300?text=DAW+Showcase"
                        alt="DAW Showcase preview"
                    />
                </div>*/}
            </section>
        </main>
    );
};

export default LandingPage;
