// PluginJuceTest.jsx
import React, { useEffect, useState, useRef } from 'react';
import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
} from 'firebase/firestore';
import { db } from '../../firebase';
import ColabMessage from '../colabeditor/ColabMessage';
import { UserAuth } from '../../context/AuthContext';
import SkeletonSound from '../invites/SkeletonSound.jpg';
import { useNavigate, useParams } from 'react-router-dom';

import { motion } from 'framer-motion';

import './pluginjuce.css';

import OffsetSVG from './OffsetSVG.svg'

const PluginJuceTest = () => {
    // State variables
    const [timecode, setTimecode] = useState(0.0);
    const [juceTimecode, setJuceTimecode] = useState(0.0); // raw JUCE time
    const [timeOffset, setTimeOffset] = useState(0.0);
    const [comments, setComments] = useState([]);
    const [projects, setProjects] = useState([]);
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);
    const [hoveringOffset, setHoveringOffset] = useState(false);
    const [hoveringTime, setHoveringTime] = useState(false);

    const navigate = useNavigate();

    const { user } = UserAuth();
    let params = useParams();

    const convertMsToSeconds = (ms) => ms / 1000;

    useEffect(() => {
        console.log(params.reload);
        if (params.reload == 1) {
            navigate(`/plugin/${params.id}/${params.name}/${params.duration}/${"2"}`);
            window.location.reload();
        }
    }, [])

    // Fetch the list of projects
    useEffect(() => {
        if (user.uid) {
            const invitesRef = collection(db, 'invites');
            const userInvitesQuery = query(
                invitesRef,
                where('team', 'array-contains', user.email),
                orderBy('lastUpdated', 'desc')
            );

            const unsubscribe = onSnapshot(userInvitesQuery, (snapshot) => {
                setProjects(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        viewing: false,
                        ...doc.data(),
                    }))
                );
            });

            return () => unsubscribe();
        }
    }, [user.uid]);

    // Set up the timecode update listener from JUCE
    useEffect(() => {
        const listenerId = window.__JUCE__.backend.addEventListener(
            'timecodeUpdate',
            (newTimecode) => {
                setJuceTimecode(newTimecode);
            }
        );

        return () => {
            window.__JUCE__.backend.removeEventListener(listenerId);
        };
    }, []);


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === 'Space') {
                event.preventDefault(); // Disable the spacebar functionality
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    // Whenever juceTimecode or timeOffset changes, update the displayed timecode
    useEffect(() => {
        const displayedTime = juceTimecode - timeOffset;
        setTimecode(displayedTime);
    }, [juceTimecode, timeOffset]);

    // Fetch comments
    useEffect(() => {
        if (user) {
            const commentsRef = collection(db, 'comments');
            const q = query(
                commentsRef,
                where('project', '==', params.id),
                orderBy('time', 'asc')
            );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const commentsData = [];
                querySnapshot.forEach((doc) => {
                    commentsData.push({ id: doc.id, ...doc.data() });
                });
                setComments(commentsData);
            });

            return () => unsubscribe();
        }
    }, [user, params.id]);

    // Calculate which comments are highlighted *in the parent* 
    const tolerance = 0.5; // seconds
    const actualTime = timecode + timeOffset; // raw "JUCE time" for checking
    // We'll say a comment is highlighted if it's within tolerance
    // of the *actualTime*. This means multiple can be highlighted if
    // they are both within that time window.
    const getIsCommentHighlighted = (comment) => {
        return Math.abs(comment.position - timecode) <= tolerance;
    };

    // Helper: Format time for display
    const formatTimecode = (time) => {
        const isNegative = time < 0;
        const absTime = Math.abs(time);

        const totalSeconds = parseInt(absTime, 10);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);
        const milliseconds = Math.floor((absTime % 1) * 1000);

        const pad = (num, size = 2) => num.toString().padStart(size, '0');
        const formatted = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}:${pad(milliseconds, 3)}`;

        return isNegative ? `-${formatted}` : formatted;
    };

    // Container width
    useEffect(() => {
        const updateContainerWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };
        window.addEventListener('resize', updateContainerWidth);
        updateContainerWidth();
        return () => window.removeEventListener('resize', updateContainerWidth);
    }, []);

    // PTX file creation
    const downloadPTXFile = () => {
        const markers = comments.map((comment) => ({
            name: comment.value || 'Marker',
            timeCode: formatPTXTimecode(comment.position),
            comments: comment.value || '',
            color: comment.col || 'Default',
        }));
        createPTXFile(markers, `${params.name || 'Project'}_Markers.ptx`);
    };

    const formatPTXTimecode = (time) => {
        const frameRate = 24; // Assuming 24 fps
        const totalSeconds = Math.floor(time);
        const frames = Math.floor((time % 1) * frameRate);

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const pad = (num, size = 2) => num.toString().padStart(size, '0');
        return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}:${pad(frames)}`;
    };

    const createPTXFile = (markers, fileName = 'Markers.ptx') => {
        const ptxHeader = `PTX
Pro Tools 10 Session Text Export
Markers exported from PluginJuceTest
`;
        const markerHeader = `Memory Locations
Marker #\tMarker Name\tTime\tComments\tColor
`;
        const markerLines = markers.map((marker, index) => {
            const { name, timeCode, comments, color } = marker;
            return `${index + 1}\t${name}\t${timeCode}\t${comments}\t${color}`;
        });

        const ptxContent = `${ptxHeader}${markerHeader}${markerLines.join('\n')}`;
        const blob = new Blob([ptxContent], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Jump to a specific comment in JUCE (raw time)
    const goToComment = (commentPosition) => {
        window.__JUCE__.backend.seekToTime(commentPosition);
    };

    // totalDuration in seconds
    const totalDuration = convertMsToSeconds(params.duration || 180000);

    console.log(totalDuration);
    console.log(comments)

    return (
        <div className="plugin__test">
            <div className="pluginjuce__comments" ref={containerRef}>
                <div className="pluginjuce__top">
                    <div className="pluginjuce__topleft">
                        <img src={SkeletonSound} className="pluginjuce__cover" />
                        <div className="pluginjuce__title">{params.name}</div>
                    </div>

                    <div className="pluginjuce__topright">

                        <div className="pluginjuce__time">{formatTimecode(totalDuration)}</div>
                    </div>
                </div>

                {/* Playhead */}
                <div
                    className="playhead"
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: `${(timecode / totalDuration) * containerWidth}px`,
                        height: '100%',
                        width: '2px',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        pointerEvents: 'none',
                        transition: 'width 0.1s linear',
                        zIndex: 100,
                    }}
                ></div>

                <div className='pluginjuce__commentscontainer'>
                    {/* Render each comment */}
                    {comments.map((comment) => {
                        const isHighlighted = getIsCommentHighlighted(comment);

                        return (
                            <div key={comment.id} style={{ marginLeft: '-22px' }}>
                                <div
                                    className="colab__onecomment"
                                    onClick={() => goToComment(comment.position)}
                                >
                                    <div
                                        className="colab__whitepos"
                                        style={{
                                            width: `${(comment.position / totalDuration) *
                                                containerWidth + 1
                                                }px`,
                                        }}
                                    ></div>
                                    <ColabMessage
                                        message={comment.value}
                                        position={comment.position}
                                        color={comment.col}
                                        commentId={comment.id}
                                        isHighlighted={isHighlighted}
                                        deleteComment={() => { }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="bottomspace"></div>
            </div>

            {/* Bottom container */}
            <div className="juceplugin__bottomcontainer"
                style={{ zIndex: 999 }}
            >
                <motion.div
                    className="pluginjuce__time__current"
                    onMouseEnter={() => { setHoveringTime(true) }}
                    onMouseLeave={() => { setHoveringTime(false) }}
                    animate={{ scale: hoveringTime ? 1.02 : 1 }}
                >
                    {formatTimecode(timecode)}
                </motion.div>
                <motion.div
                    onMouseEnter={() => { setHoveringOffset(true) }}
                    onMouseLeave={() => setHoveringOffset(false)}

                    className='pluginjuce__button'
                    animate={{
                        // scaleX: hoveringOffset ? 2.0 : 1.0,

                        width: hoveringOffset ? 150.0 : 64.0,
                    }}

                >
                    <div
                        onClick={() => {

                            setTimeOffset(juceTimecode);
                        }}
                    >
                        <motion.div
                            animate={{

                                x: hoveringOffset ? -38 : 0.0
                            }}
                        >
                            <img src={OffsetSVG} />
                            <motion.div
                                style={{ position: 'absolute', fontSize: '17px', transform: 'translate(35px, -36px)', width: '100px', overflow: 'hidden' }}

                                animate={{
                                    opacity: hoveringOffset ? 1.0 : 0.0,
                                }}

                                className="pluginjuce__offsettext">
                                Set Offset
                            </motion.div>
                        </motion.div>
                    </div>
                </motion.div>


            </div>
        </div>
    );
};

export default PluginJuceTest;
