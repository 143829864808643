import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

const LufsMeter = ({ wavesurfer }) => {
    const [rmsValue, setRmsValue] = useState(-Infinity);
    const [lufsValue, setLufsValue] = useState(-Infinity);
    const [initialized, setInitialized] = useState(false);

    const [mouseEnter, setMouseEnter] = useState(false);

    const hpFilterRef = useRef(null);
    const hsFilterRef = useRef(null);
    const workletNodeRef = useRef(null);
    const rafIdRef = useRef(null);

    const sampleBufferRef = useRef([]);
    const weightedSampleBufferRef = useRef([]);

    useEffect(() => {
        if (!wavesurfer || !wavesurfer.backend || !wavesurfer.backend.ac || initialized) return;

        const audioCtx = wavesurfer.backend.ac;

        const init = async () => {
            if (!wavesurfer.backend.buffer) {
                requestAnimationFrame(init);
                return;
            }

            const processorCode = `
            class RMSWorkletProcessor extends AudioWorkletProcessor {
                process(inputs, outputs, parameters) {
                    const input = inputs[0];
                    if (input && input[0] && input[0].length > 0) {
                        const channelData = input[0];
                        let sum = 0.0;
                        for (let i = 0; i < channelData.length; i++) {
                            sum += channelData[i]*channelData[i];
                        }
                        const rms = Math.sqrt(sum/channelData.length);
                        this.port.postMessage({ rms });
                    }
                    return true;
                }
            }
            registerProcessor('rms-worklet', RMSWorkletProcessor);
            `;

            const blob = new Blob([processorCode], { type: 'application/javascript' });
            const url = URL.createObjectURL(blob);

            await audioCtx.audioWorklet.addModule(url);

            const inputNode = wavesurfer.backend.gainNode;

            // K-weight filters
            hpFilterRef.current = audioCtx.createBiquadFilter();
            hpFilterRef.current.type = 'highpass';
            hpFilterRef.current.frequency.value = 38;
            hpFilterRef.current.Q.value = 0.707;

            hsFilterRef.current = audioCtx.createBiquadFilter();
            hsFilterRef.current.type = 'highshelf';
            hsFilterRef.current.frequency.value = 1500;
            hsFilterRef.current.gain.value = 4.0;

            workletNodeRef.current = new AudioWorkletNode(audioCtx, 'rms-worklet');
            workletNodeRef.current.port.onmessage = handleWorkletMessage;

            inputNode.connect(hpFilterRef.current);
            hpFilterRef.current.connect(hsFilterRef.current);
            hsFilterRef.current.connect(workletNodeRef.current);

            // We don’t want to hear the worklet; connect it to a dummy Gain = 0
            const dummyGain = audioCtx.createGain();
            dummyGain.gain.value = 0.0;
            workletNodeRef.current.connect(dummyGain);
            dummyGain.connect(audioCtx.destination);

            setInitialized(true);
        };

        init();

        return () => {
            if (workletNodeRef.current) workletNodeRef.current.disconnect();
            if (hpFilterRef.current) hpFilterRef.current.disconnect();
            if (hsFilterRef.current) hsFilterRef.current.disconnect();
            if (rafIdRef.current) cancelAnimationFrame(rafIdRef.current);
        };
    }, [wavesurfer, initialized]);

    useEffect(() => {
        if (initialized) {
            rafIdRef.current = requestAnimationFrame(updateMeters);
        }
        return () => {
            if (rafIdRef.current) cancelAnimationFrame(rafIdRef.current);
        };
    }, [initialized]);

    const handleWorkletMessage = (event) => {
        const { rms } = event.data;

        sampleBufferRef.current.push(rms);
        if (sampleBufferRef.current.length > 50) {
            sampleBufferRef.current.shift();
        }

        weightedSampleBufferRef.current.push(rms);
        if (weightedSampleBufferRef.current.length > 512) {
            weightedSampleBufferRef.current.shift();
        }
    };

    const updateMeters = () => {
        const rmsValues = sampleBufferRef.current;
        const weightedValues = weightedSampleBufferRef.current;

        if (rmsValues.length > 0) {
            const avgRms = rmsValues.reduce((a, b) => a + b, 0) / rmsValues.length;
            const rmsDb = linearToDb(avgRms);
            setRmsValue(rmsDb.toFixed(1));
        }

        if (weightedValues.length > 0) {
            const avgWeighted = weightedValues.reduce((a, b) => a + b, 0) / weightedValues.length;
            const lufsDb = linearToDb(avgWeighted);
            setLufsValue(lufsDb.toFixed(1));
        }

        rafIdRef.current = requestAnimationFrame(updateMeters);
    };

    const linearToDb = (value) => {
        if (value <= 0.000000001) return -Infinity;
        return 20 * Math.log10(value);
    };

    const dBToPercent = (dB) => {
        if (dB <= -60) return 0;
        if (dB >= 0) return 100;
        return ((dB + 60) / 60) * 100;
    };

    return (
        <div style={{ position: 'relative' }}>
          {/* Outer container to handle mouseEnter/mouseLeave */}
          <div
            onMouseEnter={() => setMouseEnter(true)}
            onMouseLeave={() => setMouseEnter(false)}
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              fontFamily: 'sans-serif',
            }}
          >
            {/* RMS Bar */}
            <div>
              <div
                style={{
                  background: '#EFF1F0',
                  width: '14px',
                  height: '92px',
                  position: 'relative',
                  borderRadius: '3px',
                }}
              >
                <div
                  style={{
                    background: '#A9A9A9',
                    width: '100%',
                    height: dBToPercent(parseFloat(rmsValue)) + '%',
                    position: 'absolute',
                    bottom: '0',
                    borderRadius: '0px 0px 3px 3px'
                  }}
                ></div>
              </div>
            </div>

            {/* LUFS Bar */}
            <div>
              <div
                style={{
                  background: '#EFF1F0',
                  width: '14px',
                  height: '92px',
                  position: 'relative',
                  borderRadius: '3px',
                }}
              >
                <div
                  style={{
                    background: '#A9A9A9',
                    width: '100%',
                    height: dBToPercent(parseFloat(lufsValue)) + '%',
                    position: 'absolute',
                    bottom: '0',
                    borderRadius: '0px 0px 3px 3px'
                  }}
                ></div>
              </div>
            </div>
          </div>

          {/* Tooltip pop-up, imitating the style from ColabMessage */}
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: mouseEnter ? 1 : 0,
              scale: mouseEnter ? 1 : 0.8,
            }}
            transition={{ duration: 0.2 }}
            style={{
              position: 'absolute',
              bottom: '110%',     // Position above the meters
              left: '-100%',
              transform: 'translateX(-50%)',
              backgroundColor: '#333',
              color: '#fff',
              padding: '6px 10px',
              borderRadius: '6px',
              pointerEvents: 'none',
              whiteSpace: 'nowrap',
              fontSize: '0.9rem',
              zIndex: 999,
              width: '78px'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
              <span style={{ fontWeight: 'bold' }}>RMS:</span> 
              <span>{rmsValue} dBFS</span>
              <span style={{ fontWeight: 'bold', marginTop: '6px' }}>LUFS:</span> 
              <span>{lufsValue} LUFS</span>
            </div>
          </motion.div>
        </div>
    );
};

export default LufsMeter;
