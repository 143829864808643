import React, { useEffect, useRef, useState } from 'react';
import './invites.css';
import OneInvite from './OneInvite';
import OneInviteRequest from './OneInviteRequest';
import OneFile from '../files/OneFile';
import OneHorizontalFile from './OneHorizontalFile';

import { db, storage, functions } from "../../firebase";
import {
  doc,
  collection,
  addDoc,
  query,
  where,
  orderBy,
  serverTimestamp,
  getDoc,
  updateDoc,
  increment,
  arrayRemove,
  onSnapshot
} from "firebase/firestore";

import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';

import { UserAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

import PlusIcon from './PlusIconSB.png';
import formX from './formX.svg';
import FileAddSVG from './fileadd.svg';
import SearchIcon from '../files/searchicon.svg';
import SkeletonSound from './SkeletonSound.jpg';
import NewProjectButton from './NewProjectButton';

import { motion, AnimatePresence } from 'framer-motion';

// ─────────────────────────────────────────────────────────────
import { useDataContext } from '../../context/DataContext';

const Invites = () => {
  const [workspaceName, setWorkspaceName] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [imageUpload, setImageUpload] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [friends, setFriends] = useState([]);
  const [addedMembers, setAddedMembers] = useState([]);
  const [memberSearchTerm, setMemberSearchTerm] = useState('');
  const [showFriendsList, setShowFriendsList] = useState(false);
  const [hoveredFriend, setHoveredFriend] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState('All Projects');

  const { user } = UserAuth();
  const uid = user.uid;
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const addMemberInputRef = useRef(null);

  // NEW: file input ref for "Upload a file" button
  const fileUploadInputRef = useRef(null);

  // Drag and drop states
  const [isDragging, setIsDragging] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);

  // IMPORTANT: We store uploading files here
  const [uploadingFiles, setUploadingFiles] = useState([]);

  // ─────────────────────────────────────────────────────────────
  //  1) Get data from the context
  // ─────────────────────────────────────────────────────────────
  const {
    invites: invitesList,
    invitations,
    workspaces: memberWorkspaces,
    loading: contextLoading
  } = useDataContext();

  const workspacesCollectionRef = collection(db, "workspaces");

  // Get user's friends
  useEffect(() => {
    if (user.uid) {
      const userDocRef = doc(db, "users", user.uid);
      const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          const friendsUIDs = data.friends || [];
          const friendsPromises = friendsUIDs.map((fuid) => {
            const friendDocRef = doc(db, "users", fuid);
            return getDoc(friendDocRef).then((friendDocSnap) => {
              if (friendDocSnap.exists()) {
                return { uid: fuid, ...friendDocSnap.data() };
              } else {
                return null;
              }
            });
          });
          Promise.all(friendsPromises).then((friendsData) => {
            setFriends(friendsData.filter((friend) => friend !== null));
          });
        }
      });
      return () => unsubscribe();
    }
  }, [user.uid]);

  // Close friends list when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        addMemberInputRef.current &&
        !addMemberInputRef.current.contains(event.target)
      ) {
        setShowFriendsList(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [addMemberInputRef]);

  // ─────────────────────────────────────────────────────────────
  //   CREATE WORKSPACE
  // ─────────────────────────────────────────────────────────────
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (imageUpload) {
      const imageRef = ref(storage, `workspaceImages/${uid}/${workspaceName}`);
      const uploadTask = uploadBytesResumable(imageRef, imageUpload);

      uploadTask.on(
        "state_changed",
        () => { },
        (error) => {
          alert(error);
          setLoading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          createWorkspace(downloadURL);
        }
      );
    } else {
      createWorkspace("");
    }
  };

  const createWorkspace = (imageUrl) => {
    const newWorkspace = {
      name: workspaceName,
      owner: user.uid,
      time: serverTimestamp(),
      team: [user.email, ...addedMembers],
      lastUpdated: serverTimestamp(),
      imageUrl: imageUrl,
    };

    addDoc(workspacesCollectionRef, newWorkspace)
      .then((docRef) => {
        // Send invitations to added members who are not already friends
        const nonFriendMembers = addedMembers.filter(
          (email) => !friends.some((friend) => friend.email === email)
        );
        const invitationsRef = collection(db, "invitations");
        nonFriendMembers.forEach((email) => {
          const newInvitation = {
            accepted: false,
            inviter: user.email,
            projectId: docRef.id,
            projectname: workspaceName,
            time: serverTimestamp(),
            userId: email,
          };
          addDoc(invitationsRef, newInvitation);
        });

        setShowForm(false);
        setLoading(false);
        setWorkspaceName("");
        setImageUpload(null);
        setImagePreviewUrl("");
        setAddedMembers([]);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  const showFormFunct = () => {
    setWorkspaceName("");
    setImageUpload(null);
    setImagePreviewUrl("");
    setShowForm(!showForm);
  };

  // ─────────────────────────────────────────────────────────────
  //   OPEN WORKSPACE / OPEN PROJECT
  // ─────────────────────────────────────────────────────────────
  const openWorkspace = (id) => {
    navigate(`/files/${id}`);
  };

  const openProject = (id, name) => {
    updateDoc(doc(db, "invites", id), {
      unreadUsers: arrayRemove(user.email),
    });
    navigate(`/project/${id}/${name}`);
  };

  // ─────────────────────────────────────────────────────────────
  //   FILE TYPE SELECT
  // ─────────────────────────────────────────────────────────────
  const handleFileTypeSelect = (fileType) => {
    setSelectedFileType(fileType);
  };

  // Filter memberWorkspaces
  const filteredMemberWorkspaces = (memberWorkspaces || []).filter((element) =>
    element.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Filter invitesList
  const filteredInvitesList = (invitesList || []).filter((element) => {
    const itemName = element.name || "";
    return itemName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // ─────────────────────────────────────────────────────────────
  //   IMAGE UPLOAD (Workspace Cover)
  // ─────────────────────────────────────────────────────────────
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageUpload(file);
      setImagePreviewUrl(URL.createObjectURL(file));
    }
  };

  // ─────────────────────────────────────────────────────────────
  //   ADD MEMBER
  // ─────────────────────────────────────────────────────────────
  const addMember = (email) => {
    setAddedMembers((prev) => {
      if (!prev.includes(email)) {
        return [...prev, email];
      } else {
        return prev;
      }
    });
    setMemberSearchTerm("");
    setShowFriendsList(false);
  };

  // ─────────────────────────────────────────────────────────────
  //   DRAG AND DROP
  // ─────────────────────────────────────────────────────────────
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((prev) => prev + 1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((prev) => {
      const newCounter = prev - 1;
      if (newCounter === 0) {
        setIsDragging(false);
      }
      return newCounter;
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const uploadFile = async (file, initialName) => {
    const fileSize = file.size;
    const checkAndUpload = httpsCallable(functions, "checkAndUploadFile");

    try {
      // Check if user has enough storage
      await checkAndUpload({
        fileName: file.name,
        fileSize: fileSize,
      });

      const storageRef = ref(
        storage,
        `files/${user.uid}/${Date.now()}_${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Push a new uploading file into state
      const uploadingFile = {
        id: storageRef.fullPath,
        name: initialName,
        progress: 0,
      };
      setUploadingFiles((prev) => [...prev, uploadingFile]);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadingFiles((prevUploadingFiles) =>
            prevUploadingFiles.map((uf) =>
              uf.id === uploadingFile.id ? { ...uf, progress } : uf
            )
          );
        },
        async (error) => {
          alert(error.message);
          // Revert storage usage if upload fails
          const userDocRef = doc(db, "users", user.uid);
          await updateDoc(userDocRef, {
            storageUsed: increment(-fileSize),
          });
          // Remove from uploading list
          setUploadingFiles((prev) =>
            prev.filter((uf) => uf.id !== uploadingFile.id)
          );
        },
        async () => {
          // On success, get download link and create the new doc
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const invitationsRef = collection(db, "invites");

          const newInvitation = {
            name: initialName,
            userId: user.email,
            workspaceId: "",
            accepted: true,
            time: serverTimestamp(),
            lastUpdated: serverTimestamp(),
            fileUrl: downloadURL,
            type: "sound",
            team: [user.email],
          };

          await addDoc(invitationsRef, newInvitation);

          // Remove from uploading list
          setUploadingFiles((prev) =>
            prev.filter((uf) => uf.id !== uploadingFile.id)
          );
        }
      );
    } catch (error) {
      if (error.code === "functions/resource-exhausted") {
        alert(error.message);
      } else {
        alert("An error occurred during file upload.");
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    setDragCounter(0);

    const droppedFiles = e.dataTransfer.files;
    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["mp3", "wav", "aiff"];

      if (allowedExtensions.includes(fileExtension)) {
        const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
        uploadFile(file, fileNameWithoutExtension);
      } else {
        alert("Only mp3, wav, and aiff files are allowed.");
      }
    }
  };

  // Handle "Upload a file" button
  const handleUploadFileButton = () => {
    if (fileUploadInputRef.current) {
      fileUploadInputRef.current.click();
    }
  };

  // Handle files selected via file dialog
  const handleFileButtonChange = (e) => {
    const selectedFiles = e.target.files;
    if (!selectedFiles) return;

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["mp3", "wav", "aiff"];

      if (allowedExtensions.includes(fileExtension)) {
        const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
        uploadFile(file, fileNameWithoutExtension);
      } else {
        alert("Only mp3, wav, and aiff files are allowed.");
      }
    }
    e.target.value = null; // reset the input
  };

  // ─────────────────────────────────────────────────────────────
  //   LOADING & NO-FILES HANDLING
  // ─────────────────────────────────────────────────────────────
  const isLoading = contextLoading;
  const noFilesOrProjects =
    !isLoading &&
    (filteredMemberWorkspaces.length === 0 &&
      filteredInvitesList.length === 0 &&
      invitations.length === 0);

  // ─────────────────────────────────────────────────────────────
  //   Count unread invites, etc.
  // ─────────────────────────────────────────────────────────────
  const [workspaceUnreadCounts, setWorkspaceUnreadCounts] = useState({});
  useEffect(() => {
    if (!invitesList || invitesList.length === 0) {
      setWorkspaceUnreadCounts({});
      return;
    }
    const counts = {};
    invitesList.forEach((invite) => {
      if (invite.workspaceId) {
        if (!counts[invite.workspaceId]) {
          counts[invite.workspaceId] = 0;
        }
        if (invite.unreadUsers && invite.unreadUsers.includes(user.email)) {
          counts[invite.workspaceId]++;
        }
      }
    });
    setWorkspaceUnreadCounts(counts);
  }, [invitesList, user.email]);

  const unseenWorkspacesCount = Object.values(workspaceUnreadCounts).filter(
    (count) => count > 0
  ).length;
  const unseenInvitesCount = (invitesList || []).filter(
    (inv) => inv.unreadUsers && inv.unreadUsers.includes(user.email)
  ).length;

  const [showUnseenPopup, setShowUnseenPopup] = useState(false);
  useEffect(() => {
    if (unseenWorkspacesCount > 0 || unseenInvitesCount > 0) {
      setShowUnseenPopup(true);
      const timer = setTimeout(() => {
        setShowUnseenPopup(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [unseenWorkspacesCount, unseenInvitesCount]);

  // ─────────────────────────────────────────────────────────────
  //   RENDER
  // ─────────────────────────────────────────────────────────────
  return (
    <>
      <div
        className={`projects__maincontainer ${isDragging ? "dragging" : ""}`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {/* If user drags a file over */}
        {isDragging && (
          <div className="files__dragOverlay">
            <div className="files__dragOverlayContent">
              <p>Drop files to upload</p>
            </div>
          </div>
        )}

        {/* SHOW LOADING SPINNER IF ANY DATA IS STILL FETCHING */}
        {isLoading && (
          <div className="loading-container">
            <motion.div
              className="loading-spinner"
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{
                repeat: Infinity,
                duration: 1,
                ease: "linear",
              }}
            />
          </div>
        )}

        {/* SHOW FORM (CREATE WORKSPACE) */}
        {showForm && (
          <div className="invites__formblur">
            <div className="invites__form">

              <div className="invites__formbottom">
                <div className="invites__formmembersdisplay">
                  {addedMembers.map((email, index) => {
                    const friend = friends.find((f) => f.email === email);
                    const name = friend ? friend.name : email.split("@")[0];
                    return (
                      <div key={index} className="invites__formonemember">

                      </div>
                    );
                  })}
                </div>
                <button
                  className="invites__submitbutton"
                  onClick={handleSubmit}
                  disabled={loading || !workspaceName}
                >
                  {loading ? "Creating..." : "Create Workspace"}
                </button>
              </div>

              <div className="invites__formtop">
                <div className="invites__formtitle"></div>
                <div className="invites__xbutton" onClick={showFormFunct}>
                  <img src={formX} className="invites__imgsize" alt="Close" />
                </div>
              </div>

              <div className="invites__formmain">
                <div className="invites__formimg" onClick={handleImageClick}>
                  {imagePreviewUrl ? (
                    <img
                      src={imagePreviewUrl}
                      className="invites__formpreviewimg"
                      alt="Preview"
                    />
                  ) : (
                    <img src={FileAddSVG} className="invites__formsvg" alt="Add" />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                </div>

                <div className="invites__formrightside">
                  <div className="invites__inputcontainer">
                    <div className="invites__inputinfo">
                      <input
                        className="invites__input"
                        value={workspaceName}
                        placeholder="Untitled Folder"
                        onChange={(e) => setWorkspaceName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div
                    className="invites__formaddmember"
                    ref={addMemberInputRef}
                  >
                    <textarea
                      className="invites__formaddmember__input"
                      placeholder="Add Members"
                      value={memberSearchTerm}
                      onChange={(e) => setMemberSearchTerm(e.target.value)}
                      onFocus={() => setShowFriendsList(true)}
                    />
                    {showFriendsList && (
                      <div className="invites__friendslist">
                        {friends
                          .filter((friend) => {
                            const nameMatch = friend.name
                              .toLowerCase()
                              .includes(memberSearchTerm.toLowerCase());
                            const emailMatch = friend.email
                              .toLowerCase()
                              .includes(memberSearchTerm.toLowerCase());
                            return (
                              (nameMatch || emailMatch) &&
                              !addedMembers.includes(friend.email)
                            );
                          })
                          .map((friend) => (
                            <div
                              key={friend.uid}
                              className="invites__frienditem"
                              onMouseEnter={() => setHoveredFriend(friend.uid)}
                              onMouseLeave={() => setHoveredFriend(null)}
                              onClick={() => addMember(friend.email)}
                            >
                              <div className="invites__friendinfo">
                                <div className="invites__friendpic"></div>
                                <div className="invites__friendname">
                                  {friend.name}
                                </div>
                              </div>
                              {hoveredFriend === friend.uid && (
                                <button className="invites__addfriendbutton">
                                  Add
                                </button>
                              )}
                            </div>
                          ))}
                        {memberSearchTerm &&
                          !friends.some(
                            (friend) => friend.email === memberSearchTerm
                          ) &&
                          !addedMembers.includes(memberSearchTerm) && (
                            <div className="invites__addemailitem">
                              <div className="invites__emailinfo">
                                <div className="invites__friendpic"></div>
                                <div className="invites__emailname">
                                  {memberSearchTerm}
                                </div>
                              </div>
                              <button
                                className="invites__addemailbutton"
                                onClick={() => addMember(memberSearchTerm)}
                              >
                                Add
                              </button>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>


            </div>
          </div>
        )}

        {/* HEADER */}
        <div className="files__header">
          <div className="projects__title">Projects</div>

          <div className="files__search">
            <div className="files__searchcontainer">
              <input
                className="files__searchbar"
                placeholder="Search for a project..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <img
                src={SearchIcon}
                className="files__searchicon"
                alt="Search Icon"
              />
            </div>
          </div>

          <div className="files__currentuser">
            {user.displayName || user.email}
          </div>
        </div>

        <div className="files__secondaryheader">
          <div className="files__filetypeselector">
            {["All Projects", "Folders", "Files"].map((type) => (
              <div
                key={type}
                className={`files__filetypebutton ${selectedFileType === type ? "selected" : ""
                  }`}
                onClick={() => handleFileTypeSelect(type)}
              >
                {type}
              </div>
            ))}
          </div>

          <div className="files__addUserPopupButtons">
            <button
              className="invites__uploadfilebutton"
              style={{
                fontSize: "15px",
                backgroundColor: "#f9f9f9",
                color: "black",
                borderRadius: "10px",
              }}
              onClick={handleUploadFileButton}
            >
              + Upload a file
            </button>
            <input
              ref={fileUploadInputRef}
              type="file"
              accept=".mp3,.wav,.aiff"
              multiple
              style={{ display: "none" }}
              onChange={handleFileButtonChange}
            />
          </div>
        </div>

        {/* MAIN FILES / PROJECTS LIST */}
        {(selectedFileType === "All Projects" ||
          selectedFileType === "Folders") && (
            <div className="files__files">
              {/* Uploading in-page display REMOVED! */}

              {noFilesOrProjects && (

                <div className="invites__nofilesmessage">
                  <div className='invites__nofilesmessage__bold'>
                    Oops! No files found.
                  </div>
                  <div className='invites__nofilesmessage__regular'>
                    Drop a file or create your first collaborative project
                  </div>
                </div>


              )}

              {noFilesOrProjects && (

                <div >
                </div>
              )}



              {filteredMemberWorkspaces.map((element) => {
                const unreadCount = workspaceUnreadCounts[element.id] || 0;
                return (
                  <div
                    key={element.id}
                    onClick={() => openWorkspace(element.id)}
                  >
                    <OneFile
                      key={element.id}
                      name={element.name}
                      id={element.id}
                      img={element.imageUrl}
                      type={"sound"}
                      members={element.team.length}
                      lastUpdated={element.lastUpdated?.toDate()}
                      isRenaming={false}
                      isProject={true}
                      isUpdated={unreadCount > 0}
                      unreadCount={unreadCount}
                    />
                  </div>
                );
              })}

              {searchTerm === "" && <NewProjectButton onShowForm={showFormFunct} />}
            </div>
          )}

        <div className="invites__seperationline"></div>

        {(selectedFileType === "All Projects" || selectedFileType === "Files") && (
          <div className="invites__colabscontainer">
            {filteredInvitesList.map((element) => (
              <div
                key={element.id}
                onClick={() => openProject(element.id, element.name)}
              >
                <OneHorizontalFile
                  key={element.id}
                  name={element.name}
                  id={element.id}
                  url={SkeletonSound}
                  type={"sound"}
                  isUpdated={element.unreadUsers?.includes(user.email)}
                  members={element.team?.length || 1}
                  lastUpdated={element.lastUpdated?.toDate()}
                  isRenaming={false}
                  infiles={false}
                />
              </div>
            ))}
          </div>
        )}


      </div>

      {/* Popup: "X folders and Y projects with unseen changes" */}
      <AnimatePresence>
        {showUnseenPopup &&
          (unseenWorkspacesCount > 0 || unseenInvitesCount > 0) && (
            <motion.div
              initial={{ opacity: 0, y: 60 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 60 }}
              transition={{ duration: 0.4 }}
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                backgroundColor: "#333",
                color: "#fff",
                padding: "12px 20px",
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                fontWeight: "500",
                fontSize: "14px",
                zIndex: 9999,
              }}
            >
              {unseenWorkspacesCount} folder
              {unseenWorkspacesCount !== 1 ? "s" : ""} and {unseenInvitesCount} project
              {unseenInvitesCount !== 1 ? "s" : ""} with unseen changes
            </motion.div>
          )}
      </AnimatePresence>

      {/* NEW: Popups for uploading files */}
      <AnimatePresence>
        {uploadingFiles.map((file, index) => (
          <motion.div
            key={file.id}
            initial={{ opacity: 0, y: 60 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 60 }}
            transition={{ duration: 0.4 }}
            style={{
              position: "fixed",
              // Stagger them so they stack upward
              bottom: `${20 + index * 60}px`,
              right: "20px",
              backgroundColor: "#333",
              color: "#fff",
              padding: "12px 20px",
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
              fontWeight: "500",
              fontSize: "14px",
              zIndex: 9999,
              marginTop: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                minWidth: "200px",
              }}
            >
              <span>{file.name}</span>
              <span>{Math.round(file.progress)}%</span>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </>
  );
};

export default Invites;
