
import React, { useEffect, useState } from "react";

import reactTextareaAutosize from "react-textarea-autosize";


import { db } from "../../firebase"
import {
  collection,
  onSnapshot,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
  setDoc
} from "firebase/firestore"
import useWindowDimensions from "../getWindowDimensions";


const MIN_TEXTAREA_HEIGHT = 10;

export default function CommentElement(props) {
  const textareaRef = React.useRef(null);
  const [value, setValue] = React.useState(props.name);
  //const onChange = (event) => setValue(event.target.value);
  const [h, setHeight] = React.useState(MIN_TEXTAREA_HEIGHT);
  const[completed, setcompleted] = useState(false);
  const initValue = props.name;
  const id = props.dbId;
  const done = props.done;
  const userid = props.user;
  const projectid = props.projectId;
  const [changing, setChanging] = useState(false);
  const [oldText, setOldText] = useState("");

  const elementsCollectionRef = collection(db, "notelists");
  var [st, setst] = useState(false);

  const [focused, setFocused] = React.useState(false)
const onFocus = () => setFocused(true)
const onBlur = () => setFocused(false)
  

  function getInitialState() { 
      setValue(initValue)
  }

  useEffect(() =>{
    setValue(props.name)
  },[])

  useEffect(() =>{
    setst(done)
  },[])

  const text = props.textbefore;

  function getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    context.font = font || getComputedStyle(document.body).font;
  
    return context.measureText(text).width;
  }

  function onChange (event) {
     setValue(event.target.value);
  }

  function updateData(collPath, data) {
    setDoc(doc(db, "notelists", id), data, { merge: true})
}

useEffect(() =>{
  updateData("",{value});
}, [focused])

  const elements = document.querySelectorAll('h1, h2, h3, h4, h5, h6');

  React.useLayoutEffect(() => {
    //updateData("",{value});
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    )}px`;
  }, [value]);

  /*const handleClick = event => {
    if (event.target.style.textDecoration) {
      event.target.style.removeProperty('text-decoration');
    } else {
      event.target.style.setProperty('text-decoration', 'line-through');
    }
  };*/

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing)
    return () => {
        window.removeEventListener('unload', handleTabClosing)
    }
})

const handleTabClosing = () => {
  if(value){
   // updateData("",{value});
  }
}

useEffect(() => {
  setChanging(true);
},[oldText])

useEffect(() => {
  const interval = setInterval(() => {
    //if(valueHasBeenSet){
      if(changing || oldText!=value){
        //if(changing){
        setOldText(value);
        updateData("",{value});
        console.log("called");
        setChanging(false);
        console.log("called");
      }
   // }
  }, 500);
  return () => clearInterval(interval);
}, [changing]);



  return (
    <div className="gray__box2">

<textarea className="comment__style"

        style={{
          minHeight: MIN_TEXTAREA_HEIGHT,
          resize: "none",
        }}
        placeholder="Comment"

        rows={1}
        spellCheck={false}
        autoCorrect={false}
        onChange={onChange}
        ref={textareaRef}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}