import { createContext, useContext, useEffect, useState } from "react";
import {
createUserWithEmailAndPassword,
signInWithEmailAndPassword,
signOut,
onAuthStateChanged,
getAuth,
sendPasswordResetEmail
} from 'firebase/auth'

import { auth } from '../firebase'

import { doc, getDoc,setDoc } from 'firebase/firestore';
import { db } from '../firebase';

const UserContext = createContext()

export const AuthContextProvider = ({ children }) => {
    const auth = getAuth()
    const [user, setUser] = useState({});
    const [storageUsed, setStorageUsed] = useState(0.0);
    const [storageLimit, setStorageLimit] = useState(2 * 1024 * 1024 * 1024); //2GB
    const [storagePercentage, setStoragePercentage] = useState(0);

    const createUser = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
    };

    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            console.log('Auth state changed: ', currentUser);
            setUser(currentUser);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        const fetchStorageData = async () => {
            if (user && user.uid) {
                console.log("called counter");
                const userDocRef = doc(db, 'users', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const data = userDocSnap.data();
                    const used = data.storageUsed || 0;
                    setStorageUsed(used);
                    const percentage = (used / storageLimit) * 100;
                    setStoragePercentage(percentage);
                } else {
                    // Initialize storageUsed if it doesn't exist
                    await setDoc(userDocRef, { storageUsed: 0 }, { merge: true });
                    setStorageUsed(0);
                    setStoragePercentage(0);
                }
            }
        };

        fetchStorageData();
    }, [user, storageLimit]);

    return (
        <UserContext.Provider value={{ createUser, signIn, user, storageUsed, storageLimit, storagePercentage }}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(UserContext)
}